export enum PaymentAction {
  VIEW = 'VIEW',
  CHANGE = 'CHANGE',
  SELECT = 'SELECT',
}

export enum Duration {
  DURATION_ONCE = 'once',
  DURATION_REPEATING = 'repeating',
  DURATION_FOREVER = 'forever',
}

export type CardType = | 'visa' | 'mastercard' | 'amex' | 'discover' | 'diners' | 'jcb' | 'unionpay' | 'unknown';
