import * as moment from 'moment';
import {Duration} from "../enum/payment.enum";
import {StripeCardElement} from "@stripe/stripe-js";

const YEAR = 365;
const MONTH = 30;

export class Plan {
  currency: string;
  id: number;
  interval: string;
  name: string;
  price: number;
  station_fetches_per_month: number;
  stripe_id: string;
  cover: string;
  excel_available: 'NONE' | 'WRE_FULL' | 'WRE' | 'WRE_PLUS';
  is_trial: boolean;
  serial_number: number;

  constructor(init?: Partial<Plan>) {
    Object.assign(this, init);
  }

  getPrice(): string {
    const price = this.price / 100;
    if (price === 0) {
      return 'Free';
    }
    return `${price.toFixed(2)} ${this.currency.toUpperCase()}`;
  }

  getPriceWithDiscount(percents: number = null): string {
    const price = this.price / 100;
    if (!percents || price === 0) {
      return this.getPrice();
    }
    let priceWithDiscount: number = price - (price / 100 * percents);
    return `${priceWithDiscount.toFixed(2)} ${this.currency.toUpperCase()} (including ${percents}% discount)`;
  }

  getNameWithDiscount(percents: number = null): string {
    if (!percents) {
      return this.name;
    }
    return `${this.name} (Discounted)`;
  }

  get isTrial(): boolean {
    return this.is_trial;
  }

  get isAnnual(): boolean {
    return this.interval.toLowerCase() === 'year';
  }
}

export interface CreatePaymentMethodRequest {
  card: StripeCardElement;
  name: string;
}

export interface Invoice {
  id: number;
  date: string;
  link?: string;
  plan?: number;
  user: number;
  count?: number;
  isGift?: boolean;
}

export class Subscription {
  created_at: string;
  current_period_end: string;
  id: number;
  plan: Plan | number;
  purchases_quota: number;
  status: string;
  stripe_id: string;
  auto_renew: boolean;
  is_payable: boolean;
  payment_status: string;
  coupon: string | Discount = null;

  discount: Discount;

  constructor(init?: Partial<Subscription>) {
    Object.assign(this, init);
    if (typeof this.plan === 'object') {
      this.plan = new Plan(this.plan);
    }
  }

  getCurrentPeriodEnd(): string {
    const currentPeriodEn = new Date(this.current_period_end);
    if ((this.plan as Plan)?.isAnnual) {
      return `${currentPeriodEn.getDate()}.${currentPeriodEn.getMonth() + 1}.${currentPeriodEn.getFullYear() + 1}`;
    }
    return `${currentPeriodEn.getDate()}.${currentPeriodEn.getMonth() + 1}.${currentPeriodEn.getFullYear()}`;
  }

  getCurrentCreatedAt(): string {
    const currentCreatedAt = new Date(this.created_at);
    return `${currentCreatedAt.getDate()}.${currentCreatedAt.getMonth() + 1}.${currentCreatedAt.getFullYear()}`;
  }

  getSubscriptionExpiresInDays(): number {
      const start = moment(new Date());
      const end = moment(this.current_period_end);
      if ((this.plan as Plan)?.isAnnual) {
        end.add(YEAR, 'd');
        return end.diff(start, 'days');
      }
      end.add(MONTH, 'd');
      return end.diff(start, 'days');
  }

  isActive(): boolean {
    return this.status === 'active' || this.status === 'trial';
  }

  isHaveCoupon(): boolean {
    return this.coupon !== null && !this.isCancelled && typeof this.discount !== 'undefined';
  }

  get isCancelled(): boolean {
    return this.status && this.status.toLowerCase().includes('cancel');
  }

  get isTrial(): boolean {
    return this.status === 'trial';
  }

  get isCancelledButActive(): boolean {
    return this.isCancelled && this.getSubscriptionExpiresInDays() > 0
  }
}

export interface Discount {
  percents: number;
  duration: Duration;
  duration_months: number;
  id: number;
  is_active: boolean;
  is_used: boolean;
}
