<h2 mat-dialog-title>
  <i
      class="iconshock iconshock-iosline-general-upload text-30 text-primary"
      style="position: relative; top: 4px;"
  ></i>
  Upload Data from an Excel file
  <i (click)="close()" class="i-Close-Window modal-close"></i>
</h2>
<mat-dialog-content>
  <div>
    Project name: {{injectData.project.title}} <br>
    Created by: {{injectData.project.creator.getFullName()}}
    <ngx-file-drop
        (onFileDrop)="dropped($event)"
        [accept]="'.csv, .xlsx'"
        [multiple]="false"
        [showBrowseBtn]="true"
        [browseBtnLabel]="'Browse files'"
        [dropZoneLabel]="'Drop files here or Browse to add attachments'"
    >
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div class="d-flex flex-column align-items-center">
          <span>Drop files here or Browse to add attachments.</span>
          <button
              style="width: fit-content"
              class="btn btn-primary waves-effect"
              type="button"
              (click)="openFileSelector()"
          >
            Browse Files</button>
        </div>
      </ng-template>
    </ngx-file-drop>
  </div>
</mat-dialog-content>
<mat-dialog-actions
    [ngClass]="{'justify-content-between': fileName, 'justify-content-end': !fileName}"
>
  <span *ngIf="fileName">Uploaded file: {{fileName}}</span>
  <div class="modal-btns">
    <button
        [ngClass]="{'btn-primary': data.length, 'btn-outline-primary': !data.length}"
        class="btn btn-primary mr5 waves-effect"
        type="submit"
        (click)="confirm(this.data)"
        [disabled]="!data.length"
    >
      Upload
    </button>
  </div>
</mat-dialog-actions>
