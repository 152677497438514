import {Component, Inject, OnInit} from '@angular/core';
import {ProjectPerLevel} from "../../../enum/project.enum";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {User} from "../../../models/user.model";
import {Projects, ProjectsUser} from "../../../models/project.model";

@Component({
  selector: 'app-edit-permission',
  templateUrl: './edit-permission.component.html',
  styleUrls: ['./edit-permission.component.css']
})
export class EditPermissionComponent implements OnInit {
  projectUserLevel = ProjectPerLevel;
  currentLevel: ProjectPerLevel = null;
  constructor(
    public dialogRef: MatDialogRef<EditPermissionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {perm: ProjectsUser, user: User, project: Projects}
  ) { }

  ngOnInit() {
    this.currentLevel = this.data.perm.level;
  }

  save(): void {
    this.dialogRef.close(this.currentLevel);
  }

  public close(): void {
    this.dialogRef.close();
  }
}
