import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-step-one',
  templateUrl: './confirm-step-one.component.html',
  styleUrls: ['./confirm-step-one.component.scss']
})
export class ConfirmStepOneComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<ConfirmStepOneComponent>,
  )
  { }

  ngOnInit() {
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

  close(): void {
    this.dialogRef.close(null);
  }

}
