<h2 mat-dialog-title>Report <i (click)="close()" class="i-Close-Window modal-close"></i></h2>

<mat-dialog-content>
    <div class="d-flex justify-content-between">
        <span class="m-0 p-0"><strong>{{ createdAt }}</strong></span>
        <span *ngIf="!data.is_replied">
            <strong>Your ticket has not yet been responded to</strong>
            <i class="i-Yes text-19 text-danger icon-feedback ml-1"></i>
        </span>
        <span *ngIf="data.is_replied">
            <strong>Your support ticket has been actioned by WRE. Please check you email</strong>
            <i class="i-Yes text-19 text-success icon-feedback ml-1"></i>
        </span>
    </div>
    <div class="w-100 my-3 message-container">
        <p class="text-break">{{data.message}}</p>
    </div>
    <p><strong>Page from where you send ticket: {{ data.from_page }}</strong></p>
    <div *ngIf="data.attachment || data.video_file">
        <p><strong>Uploaded files:</strong></p>
        <div class="d-flex">
            <img *ngIf="data.attachment" class="cursor-pointer" [src]="data.attachment" [width]="data.video_file ? 200 : 100"
                 alt="" (click)="openImage(data.attachment)">
            <video *ngIf="data.video_file" class="cursor-pointer ml-2" [src]="data.video_file" width="200" controls></video>
        </div>
    </div>
</mat-dialog-content>
