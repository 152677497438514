import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-menu-toggle',
  templateUrl: './menu-toggle.component.html',
  styleUrls: ['./menu-toggle.component.scss']
})
export class MenuToggleComponent {
  @Input() checked;
  @Input() menuToggleType: 'switch'|'burger'|'default' = 'default';
  @Output() changeState = new EventEmitter<boolean>();
  constructor() { }

  handleChange(value: boolean) {
    this.changeState.emit(value);
  }

}
