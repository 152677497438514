import {ChangeDetectorRef, Component, HostListener, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IMenuItem, NavigationService} from '../../../../services/navigation.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Utils} from '../../../../utils';
import {CustomizationService} from "../../../../services/customization.service";
import {Observable} from "rxjs";
import {CustomizationItem} from "../../../../interfaces/customization";
import {CustomizationObjects, OptionsToCustomize} from "../../../../enum/customization.enum";

@Component({
  selector: 'app-sidebar-large',
  templateUrl: './sidebar-large.component.html',
  styleUrls: ['./sidebar-large.component.scss']
})
export class SidebarLargeComponent implements OnInit, OnChanges {

  selectedItem: IMenuItem;

  nav: IMenuItem[];
  isCustomizationSideBar: boolean = false;
  customizationItemsToShow: Observable<CustomizationItem>;
  selectedCustomizationOption: Observable<OptionsToCustomize>;

  customizationObject: typeof CustomizationObjects = CustomizationObjects;

  constructor(
      public router: Router,
      private cdRef: ChangeDetectorRef,
      public navService: NavigationService,
      private customizationService: CustomizationService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => this.cdRef.detectChanges())
  }

  ngOnInit() {
    this.updateSidebar();
    this.checkOnCustomizationPage();
    // CLOSE SIDENAV ON ROUTE CHANGE
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((routeChange) => {
        this.closeChildNav();
        if (Utils.isMobile()) {
          this.navService.sidebarState.sidenavOpen = false;
        }
      });

    this.navService.menuItems$
      .subscribe((items) => {
        this.nav = items;
        this.setActiveFlag();
      });
  }

  selectItem(item) {
    this.navService.sidebarState.childnavOpen = true;
    this.selectedItem = item;
    this.setActiveMainItem(item);
  }

  closeChildNav() {
    this.navService.sidebarState.childnavOpen = false;
    this.setActiveFlag();
  }

  onClickChangeActiveFlag(item) {
    this.setActiveMainItem(item);
  }

  setActiveMainItem(item) {
    this.nav.forEach(item => {
      item.active = false;
    });
    item.active = true;
  }

  setActiveFlag() {
    if (window && window.location) {
      const activeRoute = window.location.hash || window.location.pathname;
      this.nav.forEach(item => {
        item.active = false;
        if (activeRoute.indexOf(item.state) !== -1) {
          this.selectedItem = item;
          item.active = true;
        }
        if (item.sub) {
          item.sub.forEach(subItem => {
            subItem.active = false;
            if (activeRoute.indexOf(subItem.state) !== -1) {
              this.selectedItem = item;
              item.active = true;
            }
            if (subItem.sub) {
              subItem.sub.forEach(subChildItem => {
                if (activeRoute.indexOf(subChildItem.state) !== -1) {
                  this.selectedItem = item;
                  item.active = true;
                  subItem.active = true;
                }
              });
            }
          });
        }
      });
    }
  }

  updateSidebar() {
    if (Utils.isMobile()) {
      this.navService.sidebarState.sidenavOpen = false;
      this.navService.sidebarState.childnavOpen = false;
    } else {
      this.navService.sidebarState.sidenavOpen = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateSidebar();
  }

  private checkOnCustomizationPage(): void {
    this.customizationService.isCustomizationSideBar$
        .subscribe(isCustomizationSideBar => {
          this.isCustomizationSideBar = isCustomizationSideBar;
          this.cdRef.detectChanges();
        });
    this.customizationItemsToShow = this.customizationService.customizationItemsToShow$;
    this.selectedCustomizationOption = this.customizationService.selectedCustomizationOption$;
  }

  onMouseLeaveSidebar(): void {
    if (this.navService.isToggleLeftBarOnMouseEvent) {
      this.navService.sidebarState.sidenavOpen = false;
    }
  }
}
