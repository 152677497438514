import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
  Router,
  RouteConfigLoadStart,
  ResolveStart,
  RouteConfigLoadEnd,
  ResolveEnd,
  ActivatedRoute
} from '@angular/router';

import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { NavigationService } from '../../../services/navigation.service';
import { AuthService } from '../../../services/auth.service';
import { CustomizationService } from '../../../services/customization.service';

@Component({
  selector: 'app-admin-layout-sidebar-large',
  templateUrl: './admin-layout-sidebar-large.component.html',
  styleUrls: ['./admin-layout-sidebar-large.component.scss']
})
export class AdminLayoutSidebarLargeComponent implements OnInit {
  moduleLoading: boolean;
  isCustomizationSideBar: boolean;
  isAuthenticated: boolean;
  @ViewChild(PerfectScrollbarDirective, {static: true}) perfectScrollbar: PerfectScrollbarDirective;

  constructor(
    private navService: NavigationService,
    private router: Router,
    private authService: AuthService,
    private customizationService: CustomizationService,
    private cd: ChangeDetectorRef,
    private activateRoute: ActivatedRoute,
    ) {
  }


  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
        this.moduleLoading = true;
      }
      if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        this.moduleLoading = false;
      }
    });
    this.customizationService.isCustomizationSideBar$.subscribe(isCustomizationSideBar => {
      this.isCustomizationSideBar = isCustomizationSideBar;
      this.cd.detectChanges();
    });
    this.checkOnline();

    this.authService.isAuthenticated().pipe(take(1))
        .subscribe(isAuthenticated => {
          const isIframeMode = !!this.activateRoute.snapshot.queryParams.iframe;
          this.isAuthenticated = isAuthenticated && !isIframeMode;
        });
  }

  checkOnline(): void {
    this.authService.checkOnline().subscribe();
    setInterval(() => { this.authService.checkOnline().subscribe(); }, 3 * 60 * 1000);
  }

  toggleSidenavState(state: boolean) {
    this.navService.sidebarState.sidenavOpen = state;
  }

  get sideNavOpenState(): boolean {
    return this.isAuthenticated ? this.navService.sidebarState.sidenavOpen : false;
  }

  get toggleLeftBarOnMouseEvent(): Observable<boolean> {
    return this.navService.isToggleLeftBarOnMouseEvent$
      .pipe(
        map(state => state && !this.navService.sidebarState.sidenavOpen)
      );
  }

}
