import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {Discount} from "../../../models/payment.model";
import {Duration} from "../../../enum/payment.enum";

@Component({
  selector: 'app-confirm-discount',
  templateUrl: './confirm-discount.component.html',
  styleUrls: ['./confirm-discount.component.scss']
})
export class ConfirmDiscountComponent implements OnInit {
  duration = Duration;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDiscountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {discount: Discount, isHaveSub: boolean}
    ) { }

  ngOnInit() {
    console.log(this.data);
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

  close(): void {
    this.dialogRef.close(null);
  }

}
