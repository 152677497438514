<h1 mat-dialog-title class="text-center text-capitalize">Download options<i (click)="close()"
                                                     class="i-Close-Window modal-close"></i></h1>
<mat-dialog-content>
  <div class="row mt-3 mb-3 d-flex" *ngIf="!data.isTrial">
    <div class="col-3 justify-content-center">
      <div class="col-12 text-center mt-2"><i class="i-Bar-Chart text-35"></i></div>
      <div class="col-12 text-center mt-2">Bar Chart</div>
      <div class="col-12 text-center mt-2">
        <btn-loading
            [loading]="btns[0].loading"
            loadingText="Loading..."
            btnClass="btn btn-primary m-1 btn-load"
            (click)="onButtonClick(typeDocumentProject.chartImage, btns[0])"
        >Image
        </btn-loading>
      </div>
      <div class="col-12 text-center mt-2">
        <btn-loading
            [loading]="btns[1].loading"
            loadingText="Loading..."
            btnClass="btn btn-primary m-1 btn-load"
            (click)="onButtonClick(typeDocumentProject.chartSVG, btns[1])"
        >SVG
        </btn-loading>
      </div>
    </div>
    <div class="col-3 justify-content-center">
      <div class="col-12 text-center mt-2"><i class="i-Pie-Chart-3 text-35"></i></div>
      <div class="col-12 text-center mt-2">Rose Chart</div>
      <div class="col-12 text-center mt-2">
        <btn-loading
            [loading]="btns[2].loading"
            loadingText="Loading..."
            btnClass="btn btn-primary m-1 btn-load"
            (click)="onButtonClick(typeDocumentProject.windRoseImage, btns[2])"
        >Image
        </btn-loading>
      </div>
      <div class="col-12 text-center mt-2">
        <btn-loading
            [loading]="btns[3].loading"
            loadingText="Loading..."
            btnClass="btn btn-primary m-1 btn-load"
            (click)="onButtonClick(typeDocumentProject.windRoseSVG, btns[3])"
        >SVG
        </btn-loading>
      </div>
    </div>
    <div class="col-3 justify-content-center">
      <div class="col-12 text-center mt-2"><i class="i-File-CSV text-35"></i></div>
      <div class="col-12 text-center mt-2">CSV Table</div>
      <div class="col-12 text-center mt-2">
        <btn-loading
            [loading]="btns[4].loading"
            loadingText="Loading..."
            [disabled]="data.project.use_user_observation"
            btnClass="btn btn-primary m-1 btn-load"
            (click)="onButtonClick(typeDocumentProject.windDirectionsCsv, btns[4])"
        >Wind directions
        </btn-loading>

      </div>
      <div class="col-12 text-center mt-2">
        <btn-loading
            [loading]="btns[5].loading"
            loadingText="Loading..."
            btnClass="btn btn-primary m-1 btn-load"
            (click)="onButtonClick(typeDocumentProject.windDistributionTableCsv, btns[5])"
        >Wind distribution
        </btn-loading>
      </div>
    </div>
    <div class="col-3 justify-content-center">
      <div class="col-12 text-center mt-2"><i class="i-File-Horizontal-Text text-35"></i></div>
      <div class="col-12 text-center mt-2">Dashboard</div>
      <div class="col-12 text-center mt-2">
        <btn-loading
            [loading]="btns[6].loading"
            loadingText="Loading..."
            btnClass="btn btn-primary m-1 btn-load"
            (click)="onButtonClick(typeDocumentProject.DashboardImage, btns[6])"
        >Dashboard PNG
        </btn-loading>
      </div>
      <div class="col-12 text-center mt-2">
        <btn-loading
                [loading]="btns[7].loading"
                loadingText="Loading..."
                btnClass="btn btn-primary m-1 btn-load"
                (click)="onButtonClick(typeDocumentProject.satImagePNG, btns[7])"
        >Sat. image PNG
        </btn-loading>
      </div>
    </div>
  </div>
  <div class="row mt-3 mb-3 d-flex justify-content-center text-center" *ngIf="data.isTrial">
    <strong>This feature isn't available in the trial version. <br>
    Please upgrade to a full subscription to unlock all the features within Web App.</strong>
  </div>
  <small *ngIf="!data.isTrial">
    Please note: The size of the font in the web application relates to number of 'number of pixels'. You will need to scale your downloaded image in order to change the font size used within the chart.
  </small>

</mat-dialog-content>
<mat-dialog-actions style="justify-content: flex-end;" *ngIf="data.isTrial">
  <div class="modal-btns">
    <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="close()">Close</button>
    <button class="btn btn-primary mr5 waves-effect" type="submit" [routerLink]="['/dashboard/payments']" (click)="close()">Upgrade to full plan</button>
  </div>
</mat-dialog-actions>
