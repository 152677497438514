import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TypeDocumentProject} from '../../../enum/project.enum';
import {Projects} from "../../../models/project.model";

@Component({
  selector: 'app-dashboard-download',
  templateUrl: './dashboard-download.component.html',
  styleUrls: ['./dashboard-download.component.scss']
})
export class DashboardDownloadComponent implements OnInit {
  onClick = new EventEmitter();
  typeDocumentProject = TypeDocumentProject;
  btns: { loading }[] = [
    {
      loading: false,
    },
    {
      loading: false,
    },
    {
      loading: false,
    },
    {
      loading: false,
    },
    {
      loading: false,
    },
    {
      loading: false,
    },
    {
      loading: false,
    },
    {
      loading: false,
    }
  ];

  constructor(
    private dialogRef: MatDialogRef<DashboardDownloadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isTrial: boolean, project: Projects }
  ) {
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

  onButtonClick(val: TypeDocumentProject, btn): void {
    if (this.data.project.use_user_observation && val === TypeDocumentProject.windDirectionsCsv) {
      return;
    }
    btn.loading = true;
    this.onClick.emit({val, btn});
  }

}
