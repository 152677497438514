import {StripeCardElementOptions} from "@stripe/stripe-js";
import {Plan} from "../models/payment.model";

export const CARD_OPTIONS: StripeCardElementOptions = {
  classes: {
    base: 'form-control mb-2'
  },
  hidePostalCode: true
};

export const EXPIRY_OPTIONS: StripeCardElementOptions = {
  classes: {
    base: 'form-control mb-2'
  },
  hidePostalCode: true
};

export const CVC_OPTIONS: StripeCardElementOptions = {
  classes: {
    base: 'form-control mb-2'
  },
  hidePostalCode: true
};

export interface PlanInformation {
  title: string;
  isShowOrderSummaryBlock: boolean;
  isShowDiscountInfo?: boolean;
  discountSize?: number;
  price?: number;
  stationFetches?: number;
  infoBlockTitle: string[];
  infoBlockItems: string[];
  extraData: string[];
  plan: Plan;
}

export const TRIAL_INFO_BLOCK_TITLE: string[] = [
  'Thank you for signing up to the Web App trial plan!',
  'Please be aware that the trial subscription will differ from the full subscription in the following ways:'
];

export const INFO_BLOCK_TITLE: string[] = [
  'Included:',
];

export const  TRIAL_INFO_BLOCK_ITEMS: string[] = [
  'There will the watermarks over some tables and graphs',
  'You will not be able to download items from your dashboard'
];

// export const TRIAL_PLAN_INFORMATION: PlanInformation = {
//   title: 'Trial Subscription Plan',
//   isShowOrderSummaryBlock: false,
//   isShowDiscountInfo: false,
//   infoBlockTitle: [
//     'Thank you for signing up to the Web App trial plan!',
//     'Please be aware that the trial subscription will differ from the full subscription in the following ways:'
//   ],
//   infoBlockItems: [
//     'There will the watermarks over some tables and graphs',
//     'You will not be able to download items from your dashboard'
//   ],
//   extraData: [
//     'To change your plan to a full subscription click on ‘Plans and Billing’ via the top right menu (next to your avatar) on the Web App.'
//   ],
// };
//
// export const BRONZE_PLAN_INFORMATION: PlanInformation = {
//   title: 'WRE Web App (Bronze subscription)',
//   isShowOrderSummaryBlock: true,
//   infoBlockTitle: [
//     'Included:',
//   ],
//   infoBlockItems: [
//     `Access to WRE Web App including data fetches`,
//     'Invite multiple users to share the same subscription using separate login credentials',
//     'Create individual projects or shared projects within your company',
//     'Free access to WRE v1.7',
//     'Free access to WRE+',
//   ],
//   extraData: [
//     'You can easily upgrade from a trial subscription plan to a full subscription by going to the plans and billing page via the menu on the top right of the Web App.'
//   ],
// };
//
// export const SILVER_PLAN_INFORMATION: PlanInformation = {
//   title: 'WRE Web App (Silver subscription)',
//   isShowOrderSummaryBlock: true,
//   infoBlockTitle: [
//     'Included:',
//   ],
//   infoBlockItems: [
//     `Access to WRE Web App including data fetches`,
//     'Invite multiple users to share the same subscription using separate login credentials',
//     'Create individual projects or shared projects within your company',
//     'Free access to WRE v1.7',
//     'Free access to WRE+',
//   ],
//   extraData: [
//     'You can easily upgrade from a trial subscription plan to a full subscription by going to the plans and billing page via the menu on the top right of the Web App.'
//   ],
// };
//
// export const GOLD_PLAN_INFORMATION: PlanInformation = {
//   title: 'WRE Web App (Gold subscription)',
//   isShowOrderSummaryBlock: true,
//   infoBlockTitle: [
//     'Included:',
//   ],
//   infoBlockItems: [
//     `Access to WRE Web App including data fetches`,
//     'Invite multiple users to share the same subscription using separate login credentials',
//     'Create individual projects or shared projects within your company',
//     'Free access to WRE v1.7',
//     'Free access to WRE+',
//   ],
//   extraData: [
//     'You can easily upgrade from a trial subscription plan to a full subscription by going to the plans and billing page via the menu on the top right of the Web App.'
//   ],
// };
