import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ToastrModule} from 'ngx-toastr';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedComponentsModule} from './components/shared-components.module';
import {SharedDirectivesModule} from './directives/shared-directives.module';
import {SharedPipesModule} from './pipes/shared-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    ToastrModule.forRoot(),
    NgbModule,
    SharedComponentsModule,
    SharedDirectivesModule,
    SharedPipesModule,
    RouterModule
  ],
  declarations: []
})
export class SharedModule {
}
