import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ProjectsService} from "../../../services/projects.service";
import {User} from "../../../models/user.model";
import {Projects} from "../../../models/project.model";

@Component({
  selector: 'app-detele-diaolog',
  templateUrl: './detele-diaolog.component.html',
  styleUrls: ['./detele-diaolog.component.css']
})
export class DeteleDiaologComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeteleDiaologComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {removable: string}
  ) { }

  ngOnInit() {
  }

  public makeDelete(): void {
    this.close(true);
  }

  public close(isDelete: boolean = false): void {
    this.dialogRef.close(isDelete);
  }
}
