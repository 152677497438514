import * as echarts from 'echarts/lib/echarts';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ProjectData, Projects} from '../../models/project.model';
import {assign, cloneDeep, flow as _flow, isEmpty} from 'lodash';
import {CHART_BAR_START_OPTIONS, INIT_OPTS_SVG_CHART_BAR, RESPONSIVE_CHART_BAR_OPTIONS, xAxisName} from '../../constants/charts';
import {ResizedEvent} from 'angular-resize-event';
import {ChartBarBreakpoints} from '../../enum/customization.enum';
import {isArray} from 'util';

@Component({
  selector: 'app-chart-bar',
  templateUrl: './chart-bar.component.html',
  styleUrls: ['./chart-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartBarComponent implements OnChanges {
  constructor() {}
  @Input() projectData: ProjectData;
  @Input() project: Projects;
  @Input() chartBarOptions: any;
  @Input() isTrialProject = false;
  @ViewChild('eCharts', {static: true}) eCharts: ElementRef<HTMLDivElement>;

  private chartInstance: echarts.ECharts;
  chartBarData: echarts.EChartOption;
  initOpts = INIT_OPTS_SVG_CHART_BAR;
  //
  private static getChartBarBreakpoint(chartBarWidth: number): ChartBarBreakpoints {
    if (chartBarWidth > ChartBarBreakpoints.Large) {
      return ChartBarBreakpoints.Large;
    } else if (chartBarWidth > ChartBarBreakpoints.Medium) {
      return ChartBarBreakpoints.Medium;
    }
    return ChartBarBreakpoints.Small;
  }
  //
  ngOnChanges(changes: SimpleChanges): void {
    this.chartBarData = _flow(
        () => this.prepareDiagramData(),
        options => this.prepareResponsiveChanges(options, ChartBarComponent.getChartBarBreakpoint(this.eCharts.nativeElement.clientWidth))
    )();
    if (this.chartInstance) {
      this.chartInstance.setOption(this.chartBarData);
    }
    this.prepareDiagramData();
  }

  private prepareDiagramData(): echarts.EChartOption {
    let options: echarts.EChartOption = isEmpty(this.chartBarOptions) ? cloneDeep(CHART_BAR_START_OPTIONS) : this.chartBarOptions;
    options = this.fixForOldProject(options);
    if (this.projectData && this.projectData.labels_wind_speed && this.projectData.histogram_wind_speed) {
      const result = assign(options, {
        yAxis: assign(options.yAxis, {
          axisLabel: assign((options.yAxis as echarts.EChartOption.YAxis).axisLabel, {
            formatter(value, index) {
              value = value * 100;
              return `${value.toFixed()}`;
            }
          })
        }),
        xAxis: assign(options.xAxis,
          {
            name: options.xAxis['name'].length ? `${CHART_BAR_START_OPTIONS.xAxis['name']} (${this.project.wind_speed_units})` : ''
          }),
        legend: assign(options.legend, {
          data: [
            {
              name: `Mean wind speed: ${this.projectData.summary.mean_speed.toFixed(1)} ${this.project.wind_speed_units}`,
              icon: 'none'
            },
            {
              name: `Scale parameter (A): ${this.project.scale || ''}  ${this.project.wind_speed_units}`,
              icon: 'none'
            },
            {
              name: `Shape parameter (k): ${this.project.shape || ''}`,
              icon: 'none'
            },
            {
              name: 'Wind speed'
            }
          ]
        }),
        series: [
          ...options.series.map(item => {
            switch (item.id) {
              case 'windSpeed':
                let linedHistogram = [[0, 0]]
                this.projectData.histogram_wind_speed.forEach(item => {
                  linedHistogram.push(item)
                  linedHistogram.push([item[0], 0])
                })
                return assign(item, {data: linedHistogram});
              case 'weibullLine':
                return assign(item, {data: this.projectData.histogram_weibull});
              case 'meanSpeed':
                return assign(item, {name: `Mean wind speed: ${this.projectData.summary.mean_speed.toFixed(1)} ${this.project.wind_speed_units}`});
              case 'scale':
                return assign(item, {name: `Scale parameter (A): ${this.project.scale || ''}  ${this.project.wind_speed_units}`});
              case 'shape':
                return assign(item, {name: `Shape parameter (k): ${this.project.shape || ''}`});
              default:
                return item
            }
          }),
        ]
      });
      return result;
    }
    return null;
  }

  private prepareResponsiveChanges(options: echarts.EChartOption, breakpoint: ChartBarBreakpoints): echarts.EChartOption {
    const responsiveOptions = cloneDeep(RESPONSIVE_CHART_BAR_OPTIONS[breakpoint]);
    if (options) {
      return assign(options,
          {
            grid: assign(options.grid, responsiveOptions.grid),
            legend: assign(options.legend, responsiveOptions.legend),
            series: options.series.map(series => {
              const responsiveSeries = responsiveOptions.series.find(item => series.type === item.type);
              return responsiveSeries ? assign(series, responsiveSeries) : series;
            })
          });
    }
    return null;
  }

  onChartInit(chart: echarts.ECharts): void {
    this.chartInstance = chart;
  }

  resizeChart(event: ResizedEvent): void {
    if (this.chartInstance) {
      this.chartBarData = this.prepareResponsiveChanges(this.chartBarData, ChartBarComponent.getChartBarBreakpoint(event.newWidth));
      this.chartInstance.resize();
    }
  }

  getDataURL(): string {
    return this.chartInstance.getDataURL({
      pixelRatio: 2,
      backgroundColor: '#fff'
    });
  }

  fixForOldProject(options: echarts.EChartOption): echarts.EChartOption {
    const cloneOptions: echarts.EChartOption = cloneDeep(options);
    // cloneOptions.series = options.series.filter(item => item.type !== 'line');
    if (Array.isArray(options.xAxis)) {
      cloneOptions.xAxis = options.xAxis.find(item => item.name.includes(xAxisName));
    }
    return cloneOptions;
  }
}
