import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private _isAdvancedSearch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _mapCenter: BehaviorSubject<{lat, lng, zoom}> = new BehaviorSubject<any>(null);
  private chooseBackgroundEvent$: Subject<void> = new Subject<void>();
  isAdvancedSearch: Observable<boolean> = this._isAdvancedSearch.asObservable();
  mapCenter: Observable<{lat, lng, zoom}> = this._mapCenter.asObservable();

  constructor() {
  }

  startAdvancedSearch(): void {
    this._isAdvancedSearch.next(true);
  }

  setCenterMap(center): void {
    this._mapCenter.next(center);
  }

  deleteCenterMap(): void {
    this._mapCenter.next(null);
  }

  endAdvancedSearch(): void {
    this._isAdvancedSearch.next(false);
  }

  changeAdvancedSearch(val: boolean): void {
    this._isAdvancedSearch.next(val);
  }

  createControlUI(): HTMLDivElement {
    const controlUI = document.createElement('div');
    controlUI.style.backgroundColor = '#fff';
    controlUI.style.border = '2px solid #fff';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlUI.style.marginBottom = '22px';
    controlUI.style.textAlign = 'center';
    controlUI.style.display = 'flex';
    controlUI.style.flexFlow = 'row';
    controlUI.style.alignItems = 'flex-end';
    controlUI.style.zIndex = '1';
    controlUI.className = 'map_font';
    return controlUI;
  }

  createControlBtn(): HTMLButtonElement {
    const controlBtn = document.createElement('button');
    controlBtn.className = 'btn btn-outline-primary map_font';
    controlBtn.style.marginLeft = '5px';
    return controlBtn;
  }

  createAcceptIcon() {
    const controlBtn = document.createElement('i');
    controlBtn.className = 'i-Yes m-1 ml-2';
    controlBtn.style.fontSize = '30px';
    controlBtn.style.cursor = 'pointer';
    return controlBtn;
  }

  get chooseBackgroundEvent(): Observable<void> {
    return this.chooseBackgroundEvent$;
  }

  emitChooseBackgroundEvent(): void {
    this.chooseBackgroundEvent$.next();
  }
}
