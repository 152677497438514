import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ApiInterceptor, LoadingInterceptor, ToastrInterceptor} from './api.interceptor';
import {PageNotFoundComponent} from './components/common/page-not-found/page-not-found.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {ToastrModule} from 'ngx-toastr';
import {InviteUserComponent} from './shared/components/modal/invite-user/invite-user.component';
import {MatDialogModule} from '@angular/material/dialog';
import {EditUserComponent} from './shared/components/modal/edit-user/edit-user.component';
import {CreateProjectComponent} from './shared/components/modal/create-project/create-project.component';
import {MatRadioModule} from '@angular/material/radio';
import {EditProjectComponent} from './shared/components/modal/edit-project/edit-project.component';
import {DeteleDiaologComponent} from './shared/components/modal/detele-diaolog/detele-diaolog.component';
import {EditPermissionComponent} from './shared/components/modal/edit-permission/edit-permission.component';
import {AddGroupComponent} from './shared/components/modal/add-group/add-group.component';
import {EditGroupComponent} from './shared/components/modal/edit-group/edit-group.component';
import {PaymentInformationComponent} from './shared/components/modal/payment-information/payment-information.component';
import {NgxStripeModule} from 'ngx-stripe';
import {environment} from '../environments/environment';
import {YesNoComponent} from './shared/components/modal/yes-no/yes-no.component';
import {SocialAuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule} from 'angularx-social-login';
import {NgxSpinnerModule} from 'ngx-spinner';
import {SendReportComponent} from './shared/components/modal/send-report/send-report.component';
import {ShareProjectComponent} from './shared/components/modal/share-project/share-project.component';
import {AccessProjectComponent} from './shared/components/modal/access-project/access-project.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {InfoComponent} from './shared/components/modal/info/info.component';
import {ShowReportComponent} from './shared/components/modal/show-report/show-report.component';
import {ImageCropperComponent} from './shared/components/modal/image-cropper/image-cropper.component';
import {ImageCropperModule} from 'ngx-img-cropper';
import {WreDownloadComponent} from './shared/components/modal/wre-download/wre-download.component';
import {DashboardDownloadComponent} from './shared/components/modal/dashboard-download/dashboard-download.component';
import {SharedComponentsModule} from './shared/components/shared-components.module';
import {InfoWindowComponent} from './shared/components/modal/info-window/info-window.component';
import {AddEmailFacebookComponent} from './shared/components/modal/add-email-facebook/add-email-facebook.component';
import {LightboxModule} from 'ngx-lightbox';
import {ClipboardModule} from 'ngx-clipboard';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmStepOneComponent} from './shared/components/modal/confirm-step-one/confirm-step-one.component';
import {WreInformationComponent} from './shared/components/modal/wre-information/wre-information.component';
import {InfoV3Component} from './shared/components/modal/info-v3/info-v3.component';
import {AuthLayoutComponent} from './shared/components/layouts/auth-layout/auth-layout.component';
import {ConfirmDiscountComponent} from './shared/components/modal/confirm-discount/confirm-discount.component';
// tslint:disable-next-line:max-line-length
import {UploadDataFromExcelComponent} from './components/main/project/step-one/modal/upload-data-fron-excel/upload-data-from-excel.component';
import {NgxFileDropModule} from 'ngx-file-drop';
import {cloneDeep} from 'lodash';
import {cashewConfig, defaultConfig, HTTP_CACHE_CONFIG, HttpCacheInterceptorModule} from '@ngneat/cashew';
import {HttpCacheConfig} from '@ngneat/cashew/lib/httpCacheConfig';

const config = [
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.GOOGLE_CLIENT_ID)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.FACEBOOK_APP_ID)
  },
];

const cacheConfig: HttpCacheConfig = {
  ...defaultConfig,
  strategy: 'explicit',
  ttl: 10 * 10000,
  responseSerializer(body) {
    return cloneDeep(body);
  }
};

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    InviteUserComponent,
    EditUserComponent,
    CreateProjectComponent,
    EditProjectComponent,
    DeteleDiaologComponent,
    EditPermissionComponent,
    AddGroupComponent,
    EditGroupComponent,
    PaymentInformationComponent,
    YesNoComponent,
    SendReportComponent,
    ShareProjectComponent,
    AccessProjectComponent,
    InfoComponent,
    ShowReportComponent,
    ImageCropperComponent,
    WreDownloadComponent,
    DashboardDownloadComponent,
    InfoWindowComponent,
    AddEmailFacebookComponent,
    ConfirmStepOneComponent,
    WreInformationComponent,
    InfoV3Component,
    AuthLayoutComponent,
    ConfirmDiscountComponent,
    UploadDataFromExcelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    SocialLoginModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    FormsModule,
    MatToolbarModule,
    MatSidenavModule,
    ToastrModule.forRoot(
      {
        timeOut: 10000,
        positionClass: 'toast-top-right',
        preventDuplicates: true,
      }),
    MatDialogModule,
    ReactiveFormsModule,
    MatRadioModule,
    NgxStripeModule.forRoot(environment.stripKey),
    NgxSpinnerModule,
    NgxDatatableModule,
    LightboxModule,
    ClipboardModule,
    ImageCropperModule,
    SharedComponentsModule,
    NgbPopoverModule,
    NgxFileDropModule,
    HttpCacheInterceptorModule.forRoot()
  ],
  providers: [
    { provide: HTTP_CACHE_CONFIG, useValue: cashewConfig(cacheConfig) },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ToastrInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: provideConfig(),
      } as SocialAuthServiceConfig,
    }
  ],
  entryComponents: [InviteUserComponent, EditUserComponent, CreateProjectComponent,
    EditProjectComponent, DeteleDiaologComponent, EditPermissionComponent,
    AddGroupComponent, EditGroupComponent, PaymentInformationComponent,
    YesNoComponent, SendReportComponent, ShareProjectComponent, AccessProjectComponent,
    InfoComponent, ShowReportComponent, ImageCropperComponent, WreDownloadComponent,
    DashboardDownloadComponent, InfoWindowComponent, AddEmailFacebookComponent,
    ConfirmStepOneComponent, WreInformationComponent, InfoV3Component, ConfirmDiscountComponent,
    UploadDataFromExcelComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}
