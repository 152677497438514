import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {User} from '../../../models/user.model';
import {UserRoles} from '../../../enum/user-roles.enum';
import {CompanyService} from '../../../services/company.service';
import {linkedGroups} from '../../../models/company.model';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  editForm = new FormGroup({
    group: new FormControl(''),
    right: new FormControl(''),
  });
  userRoles: UserRoles[] = [UserRoles.ADMIN, UserRoles.USER];

  constructor(
    public dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User, company: linkedGroups },
    private companyService: CompanyService
  ) {
  }

  ngOnInit() {
  }

  putRoleToCompanyUser(): void {
    this.companyService
      .putRoleToCompanyUser(this.data.company, this.data.user, this.editForm.value.right)
      .subscribe(user => {
        this.dialogRef.close(user);
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  upperCaseFirst(str): string {
    return str[0].toUpperCase() + str.slice(1);
  }
}
