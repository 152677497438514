import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {WindSpeed} from '../../../enum/wind-speed.enum';
import {User} from '../../../models/user.model';
import {ProjectsService} from '../../../services/projects.service';
import {ProjectAccess} from '../../../enum/project.enum';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.css']
})
export class CreateProjectComponent implements OnInit {
  windSpeed = WindSpeed;
  projectAccess = ProjectAccess;
  isCompanyProject: boolean = false;
  isUseOwnData: boolean = false;
  windSpeedSelect: WindSpeed[] = [WindSpeed.MS, WindSpeed.MPH, WindSpeed.KN, WindSpeed.KMH];
  createProjectForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    wind_speed_units: new FormControl(WindSpeed.MS),
    access: new FormControl(this.projectAccess.PRIVATE),
  });
  constructor(
    public dialogRef: MatDialogRef<CreateProjectComponent>,
    private projectsService: ProjectsService,
    @Inject(MAT_DIALOG_DATA) public data: {user: User}
) { }

  ngOnInit() {
    this.isCompanyProject = !this.data.user.isAmNotCompanyUser();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public createProject(): void {
    if (this.createProjectForm.invalid) {
      return;
    }
    let data = {...this.createProjectForm.value, use_user_observation: this.isUseOwnData};
    if (this.isCompanyProject) {
      data = {...data, group: this.data.user.getFirstCompany().id};
    }
    this.projectsService
      .createProject(data)
      .subscribe(project => {
        this.dialogRef.close(project);
      });
  }

  get isUserHaveCompany(): boolean {
    return (
      this.data.user.linked_groups && this.data.user.linked_groups.length && this.data.user.linked_groups.some(group => group.is_company)
    );
  }
}
