export enum CustomizationObjects {
  TableAndPreview = 'TableAndPreview',
  ChartBart = 'ChartBart',
  RoseGraph = 'RoseGraph',
  Dashboard = 'Dashboard'
}

export enum OptionsToCustomize {
    General = 'General',
    Title = 'Title',
    ColumnColorControls = 'Change column color',
    XYAxis = 'X&Y axis',
    Text = 'Text',
    Lines = 'Lines',
    TrendLine = 'Trendline',
    RadiusAxis = 'Radius Axis',
    AngleAxis = 'Angle Axis',
    InfoBox = 'Info box',
    TimeStamp = 'Timestamp',
    SpeedScale = 'Speed Scale',
    Background = 'Background',
    Legend = 'Legend',
    SpeedUnits = 'Speed Units',
    Logo = 'Logo'
}

export enum ChartBarBreakpoints {
    Small = 500,
    Medium = 768,
    Large = 800
}

export enum ElementPosition {
    Left = 'Left',
    Right = 'Right'
}
