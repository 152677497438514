import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'relativeTime' })
export class RelativeTimePipe implements PipeTransform {
  transform(value: Date) {
    return this.relativeTime(value);
  }

  relativeTime(value: Date) {
    let dateNow = new Date();
    dateNow = new Date(dateNow.getTime() + dateNow.getTimezoneOffset() * 60000);
    if (!(value instanceof Date)) {
      value = new Date(value);
    }
    const seconds: number = Math.floor((dateNow.getTime() - value.getTime()) / 1000);
    let interval: number = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + ' years ago';
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + ' months ago';
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 2) {
      return interval + ' days ago';
    }
    return 'today';
  }
}
