<div class="app-admin-wrap layout-sidebar-large">
    <div>
        <div class="main-header">
            <div class="logo cursor-pointer" routerLink="/dashboard/homepage">
                <img src="assets/images/logo_full.svg" alt="logo">
            </div>
            <div style="margin: auto"></div>

            <div class="header-part-right">
              <ng-container
                  *ngIf="!isPrivacyPage"
              >
                Welcome to Wind Rose Excel. Please sign in or register.
                <a class="btn btn-primary mx-3 " href="https://windroseexcel.com/">Return to website</a>
              </ng-container>
              <ng-container
                  *ngIf="isPrivacyPage"
              >
                <a class="btn btn-primary mx-3" (click)="closeTab()">Close Privacy Policy</a>
              </ng-container>
            </div>

        </div>

    </div>
    <div class="main-content-wrap d-flex flex-column">
        <router-outlet></router-outlet>
    </div>
</div>
