<h2 mat-dialog-title>Are you sure you wish to download and proceed to the next step? <i (click)="close()" class="i-Close-Window modal-close"></i></h2>
<mat-dialog-content>
    <div class="panel-body">
        Before you click next you should know the following:
        <ul>
            <li>Your remaining ‘monthly data fetches’ will decrease by 1.</li>
            <li>You will not be able select a different dataset for this project. Instead, you will have to create a new project</li>
        </ul>
    </div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: flex-end;">
    <div class="modal-btns">
        <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="confirm()">Yes, I wish to proceed</button>
        <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="close()">No, take me back to the map</button>
    </div>
</mat-dialog-actions>
