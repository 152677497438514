import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {FileSystemFileEntry, NgxFileDropEntry} from "ngx-file-drop";
import * as XLSX from 'xlsx';
import {Projects} from "../../../../../../shared/models/project.model";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-upload-data-from-excel',
  templateUrl: './upload-data-from-excel.component.html',
  styleUrls: ['./upload-data-from-excel.component.scss']
})
export class UploadDataFromExcelComponent implements OnInit {
  data: any[] = [];
  fileName: string = null;
  constructor(
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<UploadDataFromExcelComponent>,
    @Inject(MAT_DIALOG_DATA) public injectData: {project: Projects}
    ) { }

  ngOnInit() {
  }

  public files: NgxFileDropEntry[] = [];

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {

      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file.name.indexOf('csv') !== -1 || file.name.indexOf('xlsx') !== -1) {
            this.fileName = file.name;
            this.spinner.show();
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
              const bstr: string = e.target.result;
              const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary', cellText:false, cellDates:true});
              const wsname: string = wb.SheetNames[0];
              const ws: XLSX.WorkSheet = wb.Sheets[wsname];
              this.data = XLSX.utils.sheet_to_json(ws, {header: 1, raw:false, dateNF:'dd/mm/yyyy'});
              this.spinner.hide();
            };
            reader.readAsBinaryString(file);
          }
        });
      }
    }
  }

  confirm(data): void {
    if (data.length) {
      this.dialogRef.close(data);
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }
}
