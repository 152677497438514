<h2 mat-dialog-title>Access Project <i (click)="close()" class="i-Close-Window modal-close"></i></h2>

<mat-dialog-content>
  <div
          *ngIf="user.isAmCompanyOwner(epm) || user.isAmCompanyAdmin(epm)"
          class="panel panel-default mb20 panel-hovered activities"
  >
    <div class="panel-heading">Share link:</div>
    <div class="panel-body d-flex align-items-center mb-1">
      <div class="share-link-container mr-2">
        <a target="_blank" href="{{project.getShareLink()}}">{{project.getShareLink()}}</a>
      </div>
      <button
              type="button"
              class="btn btn-primary waves-effect"
              ngxClipboard [cbContent]="project.getShareLink()"
              ngbPopover="Copied to clipboard!" autoClose="outside" (shown)="closePopoverWithDelay(10)"
              #shareLinkButton="ngbPopover">
        Copy Link
      </button>
    </div>
  </div>
  <div *ngIf="user.isAmCompanyOwner(epm) || user.isAmCompanyAdmin(epm)"
       class="panel panel-default mb20 panel-hovered activities">
    <div class="panel-heading">Embedded code:</div>
    <div class="panel-body d-flex align-items-center mb-1">
      <div class="share-link-container mr-2">
        <span>{{project.getEmbeddedIFrame()}}</span>
      </div>
      <button
              type="button"
              class="btn btn-primary waves-effect"
              ngxClipboard [cbContent]="project.getEmbeddedIFrame()"
              ngbPopover="Copied to clipboard!" autoClose="outside" (shown)="closePopoverWithDelay(10)"
              #iFrameButton="ngbPopover">
        Copy To Your Website/Blog
      </button>
    </div>
  </div>
<div class="row">
  <div class="col-md-4">
    <form [formGroup]="searchForm">
      <div class="form-group">
        <input type="search" placeholder="Search..." formControlName="search" class="form-control form-control-rounded">
      </div>
    </form>
  </div>
</div>
<div class="col-md-12 pb-3">
  <div class="card  o-hidden">
    <ngx-datatable
        class="material"
        [rows]="filteredPermission | async"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [limit]="10"
    >
      <ngx-datatable-column name="Full Name">
        <ng-template let-row="row" ngx-datatable-cell-template>
         {{getFullName(row)}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Permission">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{getStringLevel(row)}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Edit">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <i
              *ngIf="project.isAmCamEditUserProject(row, user)"
              (click)="editPermission(row)"
              class="text-20 i-Pen-2 cursor-pointer">
          </i>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Delete">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <i
              *ngIf="project.isAmCamEditUserProject(row, user)"
              (click)="deletePermission(row)"
              class="text-20 i-Close-Window cursor-pointer">
          </i>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>

</mat-dialog-content>

