import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {ProjectData, Projects} from '../../../models/project.model';
import {Observable, Subject} from 'rxjs';
import {
    CUSTOMIZATION_ITEMS,
    GRAPHIC,
    INFO_BOX_POSITIONS,
    LEGEND_POSITIONS,
    Positions,
    SIZES,
    START_ROSE_GRAPH_TITLE,
    TEXT_FONT_LIST,
    TEXT_SIZE_LIST
} from '../../../constants/charts';
import {CustomizationObjects, OptionsToCustomize} from '../../../enum/customization.enum';
import {
    CustomEChartOption,
    CustomizationItem,
    InfoBoxPositionOption,
    ItemPosition
} from '../../../interfaces/customization';
import {CustomizationService} from '../../../services/customization.service';
import {assign, cloneDeep, merge} from 'lodash/fp';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {ProjectsService} from '../../../services/projects.service';
import {UtilsService} from '../../../services/utils.service';
import {Utils} from '../../../utils';
import * as echarts from 'echarts/lib/echarts';
import EChartOption = echarts.EChartOption;
import {AuthService} from '../../../services/auth.service';
import {FormControl, Validators} from '@angular/forms';
import { debounceTime, filter, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import {ModalWindowService} from '../../../services/modal-window.service';
import {fromFetch} from 'rxjs/fetch';
import {MapService} from '../../../services/map.service';
import EChartTitleOption = echarts.EChartTitleOption;
const WHITE = '#ffffff';
const BLACK = '#000000';
@Component({
  selector: 'app-rose-graph-customization',
  templateUrl: './rose-graph-customization.component.html',
  styleUrls: ['./rose-graph-customization.component.scss'],
})
export class RoseGraphCustomizationComponent implements OnDestroy {
  projectData: ProjectData = {} as ProjectData;
  @Input() selectedOption: Observable<OptionsToCustomize>;
  @ViewChild('addressText') addressText: any;
  selectedCustomizationOption: Observable<OptionsToCustomize>;
  customizedRoseGraphOptions: Observable<CustomEChartOption>;
  optionsToCustomize: typeof OptionsToCustomize = OptionsToCustomize;
  optionsList: CustomizationItem = CUSTOMIZATION_ITEMS[CustomizationObjects.RoseGraph];
  project: Projects;
  showInfoBox = false;
  showTimestamp = false;
  showLogo = true;
  logoUrl = '';
  logoPosition: ItemPosition;
  logoZoom = 15;
  isEnabledSubtextField: boolean;
  titleText: string;
  subtitleText: string;
  timestampText: string;
  timestampPosition: ItemPosition;
  infoBoxPosition: InfoBoxPositionOption = INFO_BOX_POSITIONS[1];
  legendPosition: ItemPosition = LEGEND_POSITIONS[1].position;
  currentOptions: CustomEChartOption;
  hiddenValues: string[] = [];
  elementsPositions = Positions;
  legendPositions = LEGEND_POSITIONS;
  infoBoxPositions = INFO_BOX_POSITIONS;
  palette: string[] = [];
  polarZoom;
  showBackgroundColorMenu = false;
  showBackgroundImageMenu = false;
  showBackgroundMapMenu = false;
  readonly textFontList: string[] = TEXT_FONT_LIST;
  readonly textSizeList: number[] = TEXT_SIZE_LIST;
  isCustomizationMap = false;
  zoomSizes = SIZES;
  autocomplete;
  fontSizeField: FormControl = new FormControl('', {
    validators: [
      Validators.required,
      Validators.pattern(/^[+-]?\d+(\.\d+)?$/)
    ]
  });
  titleFontSizeField: FormControl = new FormControl('', {
    validators: [
      Validators.required,
      Validators.pattern(/^[+-]?\d+(\.\d+)?$/)
    ]
  });
  country: string = null;
  roseGraphCustomizationInstance = this;

  menuSections = {
    textFormatting: {
      hidden: true
    },
    titleAndSubtitle: {
      hidden: true
    },
    grid: {
      hidden: true
    },
    angleAxis: {
      hidden: true
    }
  };

  private componentDestroy$: Subject<void> = new Subject();

  get roseLabelsReverse(): string[] {
    return this.projectData && this.projectData.labels_wind_speed ? [...this.projectData.labels_wind_speed].reverse() : [];
  }

  ngOnDestroy(): void {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  constructor(
    private utils: UtilsService,
    private projectsService: ProjectsService,
    private customizationService: CustomizationService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private modalWindowService: ModalWindowService,
    private modalService: ModalWindowService,
    private mapService: MapService
  ) {
    this.customizedRoseGraphOptions = this.customizationService.customizedRoseGraphOptions$;
    this.selectedCustomizationOption = this.customizationService.selectedCustomizationOption$;
    this.mapService.chooseBackgroundEvent.pipe(takeUntil(this.componentDestroy$))
      .subscribe(() => this.changeTextColor(this.currentOptions, {target: {value: WHITE}}));
    this.projectsService.currentProject$.pipe(
      filter(project => !!project),
      take(1)
    ).subscribe(project => {
      this.project = new Projects(project);
      this.projectsService.getProjectData(project).pipe(
        takeUntil(this.componentDestroy$)
      ).subscribe(data => {
        this.projectData = data;
        this.initOptions();
      });
    });
    this.customizationService.currentProject$
      .pipe(
        takeUntil(this.componentDestroy$)
      ).subscribe(project => {
      this.project = new Projects(project);
    });
    this.customizedRoseGraphOptions.pipe(
      takeUntil(this.componentDestroy$)
    ).subscribe(result => {
      this.currentOptions = result;
    });
    this.customizationService.isCustomizationMap$
      .pipe(
        takeUntil(this.componentDestroy$)
      ).subscribe(isCustomizationMap => {
      this.isCustomizationMap = isCustomizationMap;
      if (this.isCustomizationMap) {
        this.autocompleteChange();
      } else {
        this.country = null;
        this.mapService.deleteCenterMap();
      }
    });
  }


  private getPlaceAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
        {
          types: ['geocode']
        });
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      const place = this.autocomplete.getPlace();
      this.country = place.formatted_address;
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      this.mapService.setCenterMap({lat, lng, zoom: 13})
    });
  }

  autocompleteChange() {
    if (this.showBackgroundMapMenu && this.isCustomizationMap) {
      setTimeout(_=> this.getPlaceAutocomplete(), 0);
    }
  }

  private changeOption(option): void {
    this.customizationService.setCustomizedRoseGraphOptions(assign(this.customizationService.customizedRoseGraphOptions, option));
  }

  changeLineTransparency(options: CustomEChartOption, event): void {
    const newTransparencyValue = parseFloat(event.target.value);
    this.changeOption(merge(options, {
      angleAxis: {
        splitLine: {lineStyle: {opacity: newTransparencyValue}},
        axisLine: {lineStyle: {opacity: newTransparencyValue}},
        axisTick: {lineStyle: {opacity: newTransparencyValue}}
      },
      radiusAxis: {
        splitLine: {lineStyle: {opacity: newTransparencyValue}},
        axisLine: {lineStyle: {opacity: newTransparencyValue}}
      },
    }));
  }

  changeTitleDisplay(options: CustomEChartOption, event: boolean): void {
    if (event) {
      const startRoseGraphTitle: EChartTitleOption = cloneDeep(START_ROSE_GRAPH_TITLE);
      const titleFontSize = options.textStyle ? options.textStyle.fontSize : startRoseGraphTitle.textStyle.fontSize;
      this.changeOption({
        title: merge(
          merge(startRoseGraphTitle, options.title),
          {show: event, text: this.project.title, textStyle: {fontSize: titleFontSize}, top: '1%'}
        )
      });
    } else {
      this.changeOption({title: merge(options.title, {show: false, top: '0%'})});
    }
  }

  changeTitleBold(options: CustomEChartOption, event: MatCheckboxChange): void {
    if (event.checked) {
      this.changeOption({title: merge(options.title, {textStyle: {fontWeight: 'bolder'}})});
    } else {
      this.changeOption({title: merge(options.title, {textStyle: {fontWeight: 'normal'}})});
    }
  }

  changeTitleText(titleSettings: EChartTitleOption, event): void {
    this.changeOption({title: assign(titleSettings, {text: event.target.value})});
  }

  changeSubtitleDisplay(options: CustomEChartOption, event: MatCheckboxChange): void {
    const subtitleText = this.currentOptions.customOptions && this.currentOptions.customOptions.subtitleText
      ? this.currentOptions.customOptions.subtitleText
      : this.subtitleText;
    if (event.checked) {
      this.changeOption({title: merge(options.title, {subtext: subtitleText}), polar: merge(options.polar, {center: ['50%', '50%']})});
      this.isEnabledSubtextField = true;
    } else {
      this.isEnabledSubtextField = false;
      this.changeOption({title: merge(options.title, {subtext: ''}), polar: merge(options.polar, {center: ['50%', '50%']})});
    }
  }

  changeSubtitleText(titleSettings: EChartTitleOption, event): void {
    this.setCustomOptions({subtitleText: event.target.value});
    this.changeOption({title: assign(titleSettings, {subtext: event.target.value})});
  }

  changeLegendDisplay(event: boolean): void {
    const options = this.currentOptions;
    this.setCustomOptions({windSpeedScaleFirstStart: false});
    if (event) {
      this.changeOption({legend: merge(options.legend, {show: true, data: this.roseLabelsReverse})});
    } else {
      this.changeOption({legend: merge(options.legend, {show: false, data: []})});
    }
    this.addAdditionalItems();
  }

  changeLegendPosition(value): void {
    this.legendPosition = value;
    this.changeOption({legend: merge(this.currentOptions.legend, {...this.legendPosition})});
    this.setCustomOptions({legendPosition: value});
    this.addAdditionalItems();
  }

  changeSeriesDisplay(name: string, event: MatCheckboxChange): void {
    if (event.checked) {
      this.hiddenValues.push(name);
    } else {
      this.hiddenValues = this.hiddenValues.filter(val => val !== name);
    }
    this.changeOption({customOptions: {...this.currentOptions.customOptions, hiddenValues: this.hiddenValues}});
  }

  changePalette(event, idx: number) {
    this.palette[idx] = event.target.value;
    this.changeOption({customOptions: {...this.currentOptions.customOptions, customPalette: [...this.palette].reverse()}});
  }

  changeInfoBoxDisplay(): void {
    this.showInfoBox = !this.showInfoBox;
    this.setCustomOptions({infoBoxFirstStart: false});
    this.addAdditionalItems();
  }

  changeInfoBoxPosition(value): void {
    this.infoBoxPosition = value;
    this.setCustomOptions({infoBoxPosition: value});
    this.addAdditionalItems();
  }

  changeLogoDisplay(): void {
    this.showLogo = !this.showLogo;
    this.addAdditionalItems();
  }

  changeLogoPosition(value): void {
    this.logoPosition = value;
    this.addAdditionalItems();
  }

  changeLogoZoom(event): void {
    this.logoZoom = parseInt(event.target.value, 10);
    this.addAdditionalItems();
  }

  changePolarZoom(event): void {
    this.polarZoom = parseInt(event.target.value, 10);
    this.changeOption(merge(this.currentOptions.polar, {radius: `${this.polarZoom}%`}));
    this.changeOption({polar: merge(this.currentOptions.polar, {radius: `${this.polarZoom}%`})});
  }

  changeTimestampDisplay(): void {
    this.showTimestamp = !this.showTimestamp;
    this.setCustomOptions({timestampFirstStart: false});
    this.addAdditionalItems();
  }

  changeTimestampText(event): void {
    this.setCustomOptions({timestampText: event.target.value});
    this.addAdditionalItems();
  }

  changeTimestampPosition(value): void {
    this.timestampPosition = value;
    this.addAdditionalItems();
  }

  changeNorthLineDisplay(event: boolean): void {
    this.changeOption({
      radiusAxis: merge(this.currentOptions.radiusAxis, {
        axisLabel: {show: event},
        axisTick: {show: event}, axisLine: {show: event}
      })
    });
  }

  changeRadiusAxisLabelDisplay(radiusAxisSettings, event: MatCheckboxChange): void {
    this.changeOption({radiusAxis: merge(radiusAxisSettings, {axisLabel: {show: event.checked}})});
  }

  changeRadiusAxisTickDisplay(radiusAxisSettings, event: MatCheckboxChange): void {
    this.changeOption({radiusAxis: merge(radiusAxisSettings, {axisTick: {show: event.checked}})});
  }

  changeRadiusAxisLineDisplay(radiusAxisSettings, event: MatCheckboxChange): void {
    this.changeOption({radiusAxis: merge(radiusAxisSettings, {axisLine: {show: event.checked}})});
  }

  changeRadiusSplitLineDisplay(radiusAxisSettings, event: MatCheckboxChange): void {
    this.changeOption({radiusAxis: merge(radiusAxisSettings, {splitLine: {show: event.checked}})});
  }

  changeAngleAxisLabelDisplay(angleAxisSettings, event: MatCheckboxChange): void {
    this.changeOption({angleAxis: merge(angleAxisSettings, {axisLabel: {show: event.checked}})});
  }

  changeAngleAxisTickDisplay(angleAxisSettings, event: MatCheckboxChange): void {
    this.changeOption({angleAxis: merge(angleAxisSettings, {axisTick: {show: event.checked}})});
  }

  changeAngleAxisLineDisplay(angleAxisSettings, event: MatCheckboxChange): void {
    this.changeOption({angleAxis: merge(angleAxisSettings, {axisLine: {show: event.checked}})});
  }

  changeAngleSplitLineDisplay(angleAxisSettings, event: MatCheckboxChange): void {
    this.changeOption({angleAxis: merge(angleAxisSettings, {splitLine: {show: event.checked}})});
  }

  changeTextFont(options: CustomEChartOption, event): void {
    const newTextFont = event.target.value;
    this.changeOption({
      textStyle: merge(options.textStyle, {fontFamily: newTextFont}),
      angleAxis: merge(options.angleAxis, {axisLabel: {fontFamily: newTextFont}}),
      radiusAxis: merge(options.radiusAxis, {
        nameTextStyle: {fontFamily: newTextFont},
        axisLabel: {fontFamily: newTextFont},
      }),
    });
    this.addAdditionalItems();
  }

  changeTextColor(options: CustomEChartOption, event): void {
    const newColor = event.target.value;
    this.changeOption({
      textStyle: merge(options.textStyle, { color: newColor }),
      angleAxis: merge(options.angleAxis, { axisLabel: { color: newColor } }),
      radiusAxis: merge(options.radiusAxis, {
        nameTextStyle: { color: newColor },
        axisLabel: { color: newColor },
      }),
      legend: merge(options.legend, {
        textStyle: { color: newColor }
      }),
      title: merge(options.title, {textStyle: {color: newColor}, subtextStyle: {color: newColor}})
    });
    this.addAdditionalItems();
  }

  changeTextSize(newTextSize): void {
    const options = this.currentOptions;
    this.changeOption({
      textStyle: merge(options.textStyle, {fontSize: newTextSize}),
      angleAxis: merge(options.angleAxis, {axisLabel: {fontSize: newTextSize}}),
      title: merge(options.title, {textStyle: {fontSize: newTextSize}}),
      radiusAxis: merge(options.radiusAxis, {
        nameTextStyle: {fontSize: newTextSize},
        axisLabel: {fontSize: newTextSize},
      }),
    });
    this.changeTitleTextSize(newTextSize);
    this.addAdditionalItems();
  }

  changeTitleTextSize(newTextSize): void {
    this.titleFontSizeField.setValue(newTextSize);
    const options = this.currentOptions;
    this.changeOption({
      title: merge(options.title, {textStyle: {fontSize: newTextSize}}),
    });
  }

  setCustomOptions(options): void {
    this.changeOption({customOptions: merge(this.currentOptions.customOptions, options)});
  }

  uploadLogo() {
    this.modalService.openUploadLogo().pipe(
      mergeMap(data => fromFetch(data.image)),
      mergeMap(image => image.blob()),
      tap(blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          this.logoUrl = base64data.toString();
        };
      }),
      mergeMap(blob => this.projectsService.setWindRoseLogo(this.project.id, blob))
    ).subscribe( result => {
      this.addAdditionalItems();
    });
  }

  logoUrlToBase64(logoUrl): void {
     fromFetch(logoUrl)
         .pipe(
             mergeMap(resp => resp.blob()),
             tap(blob => {
                 const reader = new FileReader();
                 reader.readAsDataURL(blob);
                 reader.onloadend = () => {
                     const base64data = reader.result;
                     this.logoUrl = base64data.toString();
                 };
             })).subscribe(_ => this.addAdditionalItems());
  }

  setSelectedItemToCustomize(item: OptionsToCustomize): void {
    this.customizationService.setSelectedCustomizationOption(item);
  }

  toggleCustomOptionMenu(option: 'hideInfoBox'|'hideLogo'|'hideTimestamp'|'hideWindSpeedScale') {
    this.currentOptions.customOptions[option] = !this.currentOptions.customOptions[option];
  }

  private initOptions(): void {
    const options = this.customizationService.customizedRoseGraphOptions;
    this.currentOptions = options;
    if ('subtext' in options.title) {
      this.isEnabledSubtextField = options.title.show && !!options.title.subtext;
    }
    if ('text' in options.title) {
      this.titleText = options.title.text || this.project.title;
    }
    this.logoUrl = this.project.wind_rose_logo || this.authService.user.getFirstCompany() && this.authService.user.getFirstCompany().logo;
    this.palette = options.customOptions.customPalette.length
      ? [...options.customOptions.customPalette].reverse()
      : [...this.projectData.palette].reverse();
    console.log(options.customOptions.subtitleText);
    this.subtitleText = options.customOptions.subtitleText || this.project.getDescription();
    this.timestampText = options.customOptions.timestampText || `${this.project.creator.getFullName()} ${this.project.createdAt()}`;
    this.legendPosition = options.customOptions.legendPosition || this.legendPositions[1].position;
    this.infoBoxPosition = options.customOptions.infoBoxPosition || this.infoBoxPositions[1];
    // @ts-ignore
    options.graphic.elements.forEach(element => {
      this.showInfoBox = element.id === 'infobox' ? true : this.showInfoBox;
      this.showTimestamp = element.id === 'timestamp' ? true : this.showTimestamp;
      this.showLogo = element.id === 'logo' ? true : this.showLogo;
      if (element.id === 'logo') {
        this.logoZoom = parseInt(element.style.width, 10) || 15;
        this.showLogo = true;
      }
    });
    if (options.customOptions.firstStart) {
      this.showInfoBox = true;
      this.showTimestamp = true;
      this.changeOption({title: merge(options.title, {text: this.project.title, subtext: this.project.getDescription(), top: '1%'}),
        polar: merge(options.polar, {center: ['50%', '50%']})});
      this.isEnabledSubtextField = true;
      this.setCustomOptions({firstStart: false});
    }
    if (this.currentOptions.legend.show) {
      this.changeOption({legend: merge(options.legend, {data: this.roseLabelsReverse})});
    }
    this.polarZoom = parseInt(options.polar.radius, 10) || 70;
    this.hiddenValues = [...options.customOptions.hiddenValues] || [];
    this.fontSizeField.setValue(options.textStyle.fontSize || 12);
    this.showBackgroundColorMenu = !!options.backgroundColor;
    if ('textStyle' in options.title) {
      this.titleFontSizeField.setValue(options.title.textStyle.fontSize || 12);
    } else {
      this.titleFontSizeField.setValue(12);
    }
    this.fontSizeField.valueChanges.pipe(
      debounceTime(1000),
      takeUntil(this.componentDestroy$)
    ).subscribe(value => {
      const size = parseFloat(value);
      if (this.checkFontSize(size, this.fontSizeField)) {
        this.changeTextSize(size);
      }
    });
    this.titleFontSizeField.valueChanges.pipe(
      debounceTime(1000),
      takeUntil(this.componentDestroy$)
    ).subscribe(value => {
      const size = parseFloat(value);
      if (this.checkFontSize(size, this.titleFontSizeField)) {
        this.changeTitleTextSize(size);
      }
    });
    this.addAdditionalItems();
    this.cdr.detectChanges();
  }

  private addAdditionalItems() {
    if (this.showLogo && this.logoUrl && !this.utils.isBase64(this.logoUrl)) {
        this.logoUrlToBase64(this.logoUrl);
        return;
    }
    const infoBoxFields = [
      `${this.utils.toFloat(this.projectData.summary.mean_speed)} ${this.project.wind_speed_units}`,
      `${this.utils.toPercentString(this.projectData.summary.peak_frequency)}`,
      `${this.projectData.labels_wind_direction_verbose[this.projectData.summary.peak_direction_index]}`,
      `${this.utils.toPercentString(this.projectData.summary.calm_frequency)}`,
      `${this.projectData.summary.calm_definition}`,
    ];
    const newGraphic = cloneDeep(GRAPHIC);
    const fontSize = this.currentOptions.textStyle.fontSize;
    const fontFamily = this.currentOptions.textStyle.fontFamily;
    const textColor = this.currentOptions.textStyle.color || '#ffffff';
    if (this.showInfoBox) {
      newGraphic.elements.push(...Utils.getInfobox(fontSize, fontFamily, infoBoxFields, this.infoBoxPosition.positionTitle,
        this.infoBoxPosition.positionContent, textColor));
    }
    if (this.showTimestamp) {
      newGraphic.elements.push(Utils.getTimestamp(fontSize, fontFamily, this.timestampText, this.timestampPosition,
          textColor === '#333' ? '#ACA3A0' : textColor));
    }
    if (this.currentOptions.legend.show) {
      newGraphic.elements.push(Utils.getLegendTitle(fontSize, fontFamily, `Speed Scale (${this.project.wind_speed_units})`,
          this.legendPosition, textColor));
    }
    if (this.showLogo) {
      newGraphic.elements.push(Utils.getLogo(this.logoUrl, this.logoPosition || this.elementsPositions[0].position, this.logoZoom));
    }
    this.changeOption({graphic: newGraphic});
  }

  private checkFontSize(size: number, field: FormControl): boolean {
    if (field.valid && size > SIZES.FONT_MAX_SIZE) {
      field.setValue(SIZES.FONT_MAX_SIZE);
    } else if (field.valid && size < SIZES.FONT_MIN_SIZE) {
      field.setValue(SIZES.FONT_MIN_SIZE);
    }
    return field.valid && size <= SIZES.FONT_MAX_SIZE && size >= SIZES.FONT_MIN_SIZE;
  }

  changeBackgroundColor(options: EChartOption, event): void {
    this.customizationService.hideCustomizationMap();
    const newColor = event.target.value;
    this.project.wind_rose_background = null;
    this.project.map_cover_as_wr_background = false;
    this.project.blob = null;
    this.project.clearBackground = false;
    this.customizationService.setProject(this.project);
    this.changeOption({
      backgroundColor: newColor,
    });
    this.changeTextColor(this.currentOptions, {target: {value: BLACK}});
  }

  setBackgroundMap(): void {
    this.customizationService.showCustomizationMap();
  }

  changeProjectMapAsBackgroundRose(): void {
    this.customizationService.hideCustomizationMap();
    this.project.map_cover_as_wr_background = true;
    this.project.blob = null;
    this.project.clearBackground = false;
    this.customizationService.setProject(this.project);
    this.changeOption({
      backgroundColor: null,
    });
    this.changeTextColor(this.currentOptions, {target: {value: BLACK}});
  }

  uploadBackground(): void {
    this.customizationService.hideCustomizationMap();
    this.modalWindowService
      .openUploadBackground()
      .pipe(
        mergeMap(data => fromFetch(data.image)),
        mergeMap(response => response.blob()),
      )
      .subscribe(blob => {
        this.project.wind_rose_background = URL.createObjectURL(blob);
        this.project.map_cover_as_wr_background = false;
        this.project.blob = blob;
        this.project.clearBackground = false;
        this.customizationService.setProject(this.project);
        this.changeOption({
          backgroundColor: null,
        });
        this.changeTextColor(this.currentOptions, {target: {value: BLACK}});
      });
  }

  clearBackground(): void {
    this.customizationService.hideCustomizationMap();
    this.project.wind_rose_background = null;
    this.project.map_cover_as_wr_background = false;
    this.project.blob = null;
    this.project.clearBackground = true;
    this.customizationService.setProject(this.project);
    this.showBackgroundColorMenu = false;
    this.showBackgroundImageMenu = false;
    this.showBackgroundMapMenu = false;
    this.changeOption({
      backgroundColor: null,
    });
    this.changeTextColor(this.currentOptions, {target: {value: BLACK}});
  }

  chooseMapBackground() {
    this.mapService.emitChooseBackgroundEvent();
  }

  toggleMenuSections(section: 'textFormatting'|'titleAndSubtitle'|'grid'|'angleAxis') {
    this.menuSections[section].hidden = !this.menuSections[section].hidden;
  }
}
