import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {catchError, take, tap} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}
  canActivate(): Observable<boolean> {
    let authUrl = '/auth';
    if (location.pathname === '/dashboard/payments') {
      authUrl += '?payment=noauth'
    }
    return this.authService.isAuthenticated().pipe(
      take(1),
      catchError( (err: HttpErrorResponse) => {
        this.authService.clearUser();
        this.router.navigateByUrl(`${authUrl}`, { queryParamsHandling: 'merge' });
        return throwError(err);
      }),
      tap(authenticated => {
        if (!authenticated) {
          this.authService.clearUser();
          this.router.navigateByUrl(`${authUrl}`, { queryParamsHandling: 'merge' });
        }
      }),
    );
  }
}
