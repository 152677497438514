<h2 mat-dialog-title>{{data.title}} <i (click)="close()" class="i-Close-Window modal-close"></i></h2>
<mat-dialog-content>
    <form>
        <div class="form-group">
            <mat-form-field class="w-100">
                <input matInput placeholder="Email" required email ngModel #email="ngModel" name="email">
                <mat-error *ngIf="email.errors?.required">
                    This field may not be blank.
                </mat-error>
                <mat-error *ngIf="email.errors?.email">
                    Enter valid e-mail.
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: flex-end;">
    <div class="modal-btns">
        <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="register(email)">Register</button>
        <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="close()">Close</button>
    </div>
</mat-dialog-actions>
