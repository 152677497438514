import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CompanyService} from '../../../services/company.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {linkedGroups} from '../../../models/company.model';
import {ModalWindowService} from '../../../services/modal-window.service';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';

const MAX_PEOPLE_INVITE = 100;

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.css']
})
export class InviteUserComponent implements OnInit {
  inviteForm: FormGroup;
  buttonText: string;

  constructor(
    public dialogRef: MatDialogRef<InviteUserComponent>,
    private companyService: CompanyService,
    public authService: AuthService,
    private fb: FormBuilder,
    private modalWindowService: ModalWindowService,
    @Inject(MAT_DIALOG_DATA) public data: { company: linkedGroups, isInviteToCompany: boolean, groups?: linkedGroups[]}
  ) {
  }

  ngOnInit() {
    this.buttonText = this.data.isInviteToCompany ? 'Send Invite' : 'Add Users';
    this.inviteForm = this.fb.group({
      emails: this.fb.array([
        this.fb.group({
          email: ['', [Validators.required, Validators.email]],
          group: [null],
        })
      ]),
    });
  }

  close(invitationSent: boolean = false): void {
    this.dialogRef.close(invitationSent);
  }

  addEmailRow(): void {
    const emailForm = this.inviteForm.get('emails') as FormArray;
    if (emailForm.value.length < MAX_PEOPLE_INVITE) {
      emailForm.push(
        this.fb.group({
          email: ['', Validators.email],
          group: [null,]
        })
      );
    }
  }

  inviteUser(): void {
    if (this.inviteForm.invalid) {
      return;
    }
    const formData = this.inviteForm.get('emails') as FormArray;
    const emailsList: string[] = this.getEmailList(formData);
    if (this.data.isInviteToCompany) {
      const groupInviteList = this.getGroupInviteList(formData);
      console.log(groupInviteList);
      let request: Observable<any> = of(true);
      if (groupInviteList) {
        const groups = this.data.groups;
        request = forkJoin(
          Object.keys(groupInviteList)
            .map(companyId =>
              this.inviteUserGroup(groupInviteList[companyId], groups.find(g => g.id === Number(companyId)), true)
            )
        );
      }
      this.inviteUserCompany(emailsList, this.data.company).pipe(switchMap(() =>  request)).subscribe();
    } else {
      this.inviteUserGroup(emailsList, this.data.company, false)
        .subscribe(() => this.close(true));
    }
  }

  inviteUserCompany(emailsList, company): Observable<any> {
    return this.companyService
      .inviteUserCompany(emailsList, company)
      .pipe(
        tap(() => this.close(true)),
        catchError(error => {
          this.modalWindowService.openInformationWindow(
            [`The email you have provided is already part of WRE.`],
            null,
            null,
            'We cannot add this user to your company.'
          );
          return throwError(error);
        })
      );
  }

  inviteUserGroup(emailsList, company, is_company_invite: boolean = false): Observable<any> {
    return this.companyService
      .inviteUserGroup(emailsList, company, is_company_invite );
  }

  getGroupInviteList(formData): any {
    const groupInviteList = formData.getRawValue().reduce((r, a) => {
      r[a.group] = [...r[a.group] || [], a.email];
      return r;
    }, {});
    Object.keys(groupInviteList).forEach((key) => (key == '' || key == 'null') && delete groupInviteList[key]);
    if (Object.keys(groupInviteList).length) {
      return groupInviteList;
    }
    return null;
  }

  getEmailList(formData): any {
    return formData.getRawValue().map(row => {
      if (row.email) {
        return row.email;
      }
    }).filter(Boolean);
  }

  get emailsFormArray(): FormArray {
    return this.inviteForm.get('emails') as FormArray;
  }
}
