<i (click)="close()" class="i-Close-Window modal-close"></i>
<br>
<div
  [ngClass]="{'text-center': data?.titleCenter}"
>
  <h2 mat-dialog-title>{{data.title}}</h2>
</div>
<mat-dialog-content *ngIf="data.body">
  <p
      *ngFor="let p of data.body"
  >
    {{p}}
  </p>
  <ul *ngIf="data.ul">
    <li
        *ngFor="let li of data.ul"
    >
      {{li}}
    </li>
  </ul>
  <p
      *ngFor="let f of data.footer"
  >
    {{f}}
  </p>
</mat-dialog-content>
<mat-dialog-actions
    *ngIf="data.yesButtonText"
    style="justify-content: center;"
>
    <div class="modal-btns">
      <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="yes()">{{ data.yesButtonText || 'Yes' }}</button>
    </div>
</mat-dialog-actions>
