<h2 mat-dialog-title>Report a Problem <i (click)="close()" class="i-Close-Window modal-close"></i></h2>

<mat-dialog-content>
  <p class="m-0 p-0"><strong>{{user.getFullName()}}</strong></p>
  <p class="m-0 p-0"><strong>Time: </strong>{{time}}</p>
  <p><strong>Current Page: {{ fromPage }}</strong></p>
  <mat-form-field class="example-full-width">
    <mat-label>Message</mat-label>
    <textarea [formControl]="reportTextControl" matInput [cols]="40" [rows]="10"
              placeholder="You can add a screenshot/screen clippings the ‘function’, ‘element’ or ‘feature’ within the web app you think needs improving. Any recommendations will be taken very seriously. We will aim to respond within 24 hours. Thank you for your time! Wind Rose Excel Team"></textarea>
    <mat-error *ngIf="reportTextControl.errors?.maxlength">
      This field cannot be more than {{ reportTextControl.errors.maxlength.requiredLength  }} characters long.
    </mat-error>
  </mat-form-field>
  <p><strong>Note: Message field cannot be more than 5000 characters long.<br>Image size should be less than
    2.5MB and video size should be less than 50MB.</strong></p>
  <div class="image_block">
    <div class="d-flex justify-content-between">
      <div>
        <button class="btn btn-outline-primary m-1 image-upload" type="button">
          <input type="file" class="cursor-pointer" id="importFile" accept="image/jpeg, image/png"
                 (change)="imageUpload($event)"/>
          <label class="cursor-pointer mb-0" for="importFile">
            Add Image
            <i class="i-Upload text-20 "></i>
          </label>
        </button>

        <button class="btn btn-outline-primary m-1 image-upload" type="button">
          <input type="file" class="cursor-pointer" id="importVideo" accept="video/*"
                 (change)="videoUpload($event)"/>
          <label class="cursor-pointer mb-0" for="importVideo">
            Add Video
            <i class="i-Upload text-20 "></i>
          </label>
        </button>
      </div>

      <button type="button" class="btn btn-outline-primary m-1" [disabled]="reportTextControl.invalid"
              (click)="sendReport()">
        Send
      </button>
    </div>
    <div class="d-flex justify-content-between">
        <div>
            <img class="cursor-pointer" [src]="imgURL" width="100" *ngIf="imgURL" (click)="openImage(imgURL)">
        </div>
        <div style="margin-right: 200px;">
            <video class="cursor-pointer" [src]="videoURL" width="200" *ngIf="videoURL" controls></video>
        </div>
    </div>
  </div>
</mat-dialog-content>
