<h2 mat-dialog-title>Display Settings <i (click)="close(isChanged)" class="i-Close-Window modal-close"></i></h2>

<mat-dialog-content>
  <div class="panel-body">
    <form role="form" class="form-horizontal" [formGroup]="editProjectForm">

      <div class="form-group">
        <label class="col-md-4 control-label">Project Name:</label>
        <div class="col-md-12">
          <div class="input-group">
            <input class="form-control" placeholder="Project name" formControlName="title">
            <span class="input-group-addon fa fa-file"></span>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="col-md-4 control-label">Project Icon:</label>
        <div class="col-md-12 pt7">
          <div class="input-group">
            <div class="image-upload">
              {{ fileName }}
              <input style="display: none" type="file" accept="image/jpeg, image/png" #projectIcon
                     (change)="coverUpload($event)">
              <button class="btn btn-outline-primary mb-2" (click)="projectIcon.click()">
                Upload
                <i class="i-Upload text-20 "></i>
              </button>
            </div>
            <div class="image-delete ml-2" *ngIf="project.cover">
              <button class="btn btn-outline-primary mb-2" (click)="coverDelete()">
                Delete
                <i class="i-Delete-File text-20 "></i>
              </button>
            </div>
          </div>
          <p class="mb-0"><strong>Note: File size should be less than 2.5MB.</strong></p>
        </div>
      </div>

      <div class="form-group" *ngIf="!data.project.isPersonalProject()">
        <label class="col-md-4 control-label">Access setting:</label>
        <div class="col-md-12">
          <select class="form-control" formControlName="access">
            <option [value]="projectAccess.PRIVATE">Private</option>
            <option [value]="projectAccess.AvailableToView">Company users can view</option>
            <option [value]="projectAccess.AvailableToEdit">Company users can edit</option>
          </select>
        </div>
      </div>

      <div class="modal-btns">
        <button class="btn btn-primary waves-effect" type="submit" (click)="saveProject()">Save</button>
      </div>
    </form>
  </div>
</mat-dialog-content>
