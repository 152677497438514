import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

export interface IMenuItem {
  id?: string;
  title?: string;
  description?: string;
  type: string;       // Possible values: link/dropDown/extLink
  name?: string;      // Used as display text for item and title for separator type
  state?: string;     // Router state
  icon?: string;      // Material icon name
  tooltip?: string;   // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  active?: boolean;
}

export interface IChildItem {
  id?: string;
  parentId?: string;
  type?: string;
  name: string;       // Display text
  state?: string;     // Router state
  icon?: string;
  sub?: IChildItem[];
  active?: boolean;
}

interface IBadge {
  color: string;      // primary/accent/warn/hex color codes(#fff000)
  value: string;      // Display text
}

interface ISidebarState {
  sidenavOpen?: boolean;
  childnavOpen?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public sidebarState: ISidebarState = {
    sidenavOpen: true,
    childnavOpen: false
  };

  private _isToggleLeftBarOnMouseEvent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  defaultMenu: IMenuItem[] = [
    {
      name: 'Home',
      type: 'link',
      icon: 'i-Home1',
      state: '/dashboard/homepage'
    },
    {
      name: 'WRE APPS',
      type: 'link',
      icon: 'i-Monitor-5',
      state: '/dashboard/wre-apps'
    },
    {
      name: 'Projects',
      type: 'link',
      icon: 'i-Windows-2',
      state: '/dashboard/projects'
    },
    {
      name: 'Data',
      type: 'link',
      icon: 'i-Big-Data',
      state: '/dashboard/data'
    },
    {
      name: 'Support',
      type: 'link',
      icon: 'i-Speach-Bubble-Asking',
      state: '/dashboard/support'
    },
  ];


  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  get isToggleLeftBarOnMouseEvent$(): Observable<boolean> {
    return this._isToggleLeftBarOnMouseEvent$.asObservable();
  }

  get isToggleLeftBarOnMouseEvent(): boolean {
    return this._isToggleLeftBarOnMouseEvent$.value;
  }

  setToggleLeftBarOnMouseEvent(state: boolean): void {
    this._isToggleLeftBarOnMouseEvent$.next(state);
  }

  // You can customize this method to supply different menu for
  // different user type.
  // publishNavigationChange(menuType: string) {
  //   switch (userType) {
  //     case 'admin':
  //       this.menuItems.next(this.adminMenu);
  //       break;
  //     case 'user':
  //       this.menuItems.next(this.userMenu);
  //       break;
  //     default:
  //       this.menuItems.next(this.defaultMenu);
  //   }
  // }
}
