<h2 mat-dialog-title style="font-size: 22px !important;">Payment Information <i (click)="close()" class="i-Close-Window modal-close"></i></h2>
<mat-dialog-content>
  <div
      *ngIf="isActionChange"
      class="row"

  >
    <div class="col-md-12">
      <label>Credit card number</label>
      <input
          class="form-control mb-2"
          type="text"
          placeholder="Card number"
          disabled
          [value]="user.cardNumber"
      >
    </div>
    <div
        class="col-md-12 d-flex justify-content-start"
    >
      <button
          *ngIf="!this.isShowCardBlock"
          type="button"
          (click)="showCardBlock()"
          class="btn btn-outline-primary mt-2"
      >
        Change Card
      </button>
    </div>
  </div>
  <div
      *ngIf="isShowCardBlock "
      class="row"
  >
    <div
        [ngClass]="{'col-md-6': data.planInformation, 'col-md-12': !data.planInformation}"
    >
      <div class="row">
        <div class="col-md-12">
          <img
              *ngIf="currentCardType === 'unknown' || currentCardType === 'visa'"
              width="75"
              height="75"
              src="./assets/images/card/visa.png" alt="">
          <img
              *ngIf="currentCardType === 'unknown' || currentCardType === 'mastercard'"
              width="75"
              height="75"
              src="./assets/images/card/mastercard.png" alt="">
          <img
              *ngIf="currentCardType === 'unknown' || currentCardType === 'amex'"
              width="50"
              height="50"
              src="./assets/images/card/amerecan.png" alt="">
        </div>
        <div class="col-md-12">
          <div
              ngxStripeCardGroup
              [formGroup]="stripeForm"
              [elementsOptions]="elementsOptions"
          >
            <div class="stripe__head">
              <div>
                <label>Credit card number</label>
                <ngx-stripe-card-number
                    [options]="cardOptions"
                    (change)="onChangeNumber($event)"
                >
                </ngx-stripe-card-number>
              </div>
              <div>
                <label>Cardholder name</label>
                <input class="form-control mb-2" type="text" formControlName="name" placeholder="Cardholder Name">
                <mat-error *ngIf="stripeForm.get('name').touched && stripeForm.get('name').errors?.required">
                  This field is required.
                </mat-error>
              </div>
            </div>
            <div class="stripe__footer">
              <div class="stripe__footer__expiry">
                <label>Expiry date</label>
                <ngx-stripe-card-expiry
                    [options]="expiryOptions"
                    (change)="onChangeDate($event)"
                ></ngx-stripe-card-expiry>
              </div>
              <div class="stripe__footer__cvc">
                <label>CVC</label>
                <ngx-stripe-card-cvc
                    [options]="cvcOptions"
                    (change)="onChangeCVV($event)"
                ></ngx-stripe-card-cvc>
              </div>
            </div>
            <div
                [ngClass]="{'justify-content-start': !isActionChange, 'justify-content-end': isActionChange}"
                class="d-flex"
            >
              <button
                  [ngClass]="{'fill_btn': !isActionChange}"
                  type="button"
                  (click)="sendCart()"
                  class="btn btn-outline-primary mt-2"
                  [disabled]="isSubmitButtonDisabled"
              >
                {{data.btn}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
        class="col-md-6 info_block"
        *ngIf="data.planInformation"
    >
      <div class="info_block__title">
        <span class="title">{{data.planInformation.title}}</span>
      </div>
      <div class="delimiter"></div>
      <div
          class="info_block__order_summary"
          *ngIf="data.planInformation.isShowOrderSummaryBlock"
      >
        <span>Order summary:</span>
        <span>
          {{data.planInformation.plan.getPrice()}}
          <span *ngIf="data.planInformation.isShowDiscountInfo">
            + {{data.planInformation.discountSize}}% Discount
          </span>
        </span>
      </div>
      <div
          *ngIf="data.planInformation.isShowOrderSummaryBlock"
          class="delimiter"
      ></div>
      <div class="info_block__included">
        <p
          *ngFor="let t of data.planInformation.infoBlockTitle"
        >
          {{t}}
        </p>
        <ul>
          <li
              *ngFor="let i of data.planInformation.infoBlockItems"
          >
            {{i}}
          </li>
        </ul>
        <p
            *ngFor="let e of data.planInformation.extraData"
        >
          {{e}}
        </p>
      </div>
      <div class="delimiter"></div>
      <div class="info_block__total">
        <span>Total:</span>
        <span>{{data.planInformation.plan.getPriceWithDiscount(data.planInformation.discountSize)}}</span>
      </div>
    </div>
  </div>
</mat-dialog-content>
