<h2 mat-dialog-title>Edit Group <i (click)="close()" class="i-Close-Window modal-close"></i></h2>

<mat-dialog-content>
  <div class="panel-body">
    <form role="form" class="form-horizontal" [formGroup]="editGroupForm">

      <div class="form-group">
        <label class="col-md-3 control-label">Name Group</label>
        <div class="col-md-12">
          <div class="input-group">
            <input type="email" class="form-control" placeholder="Name Group" formControlName="name">
            <span class="input-group-addon fa fa-users"></span>
          </div>
        </div>
      </div>
      <div class="modal-btns">
        <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="saveGroup()">Save</button>
        <button
          class="btn btn-primary mr5 waves-effect"
          type="submit"
          (click)="deleteGroup()"
        >
          Delete Group
        </button>
      </div>
    </form>
  </div>
</mat-dialog-content>
