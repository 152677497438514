<div class="app-admin-wrap layout-sidebar-large">
    <ng-container *ngIf="isAuthenticated">
        <div>
            <app-header-sidebar-large></app-header-sidebar-large>
        </div>
        <div class="side-content-wrap">
            <app-sidebar-large></app-sidebar-large>
        </div>
    </ng-container>
    <div class="sidenav-toggle-area" *ngIf="toggleLeftBarOnMouseEvent | async"
         (mouseover)="toggleSidenavState(true)">
    </div>
    <div class="main-content-wrap d-flex flex-column" 
    [ngClass]="{ 'sidenav-open': sideNavOpenState, 'customization-sidebar': isCustomizationSideBar, 'mt-0': !isAuthenticated }"
    [perfectScrollbar]="{suppressScrollX: true}">
        <router-outlet></router-outlet>
        <div class="flex-grow-1"></div>
        <app-footer></app-footer>
        <div class="module-loader" *ngIf="moduleLoading">
            <div class="spinner spinner-bubble spinner-bubble-primary mr-3"></div>
        </div>
    </div>
</div>
