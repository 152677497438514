import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'app-info-window',
  templateUrl: './info-window.component.html',
  styleUrls: ['./info-window.component.scss']
})
export class InfoWindowComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InfoWindowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      text: string[],
      subtext: string[],
      showYesIcon?: boolean,
      title?: string,
    },
  ) { }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

}
