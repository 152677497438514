<h2 mat-dialog-title>Edit User <i (click)="close()" class="i-Close-Window modal-close"></i></h2>

<mat-dialog-content>
  <div class="panel-body">
    <form role="form" class="form-horizontal" [formGroup]="editForm">

      <div class="form-group">
        <label class="col-md-3 control-label">Add to group</label>
        <div class="col-md-12">
          <select class="form-control" formControlName="group">
            <option value="0" selected="" disabled="">Select a group</option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label class="col-md-3 control-label">Change rights</label>
        <div class="col-md-12">
          <select class="form-control" [ngModel]="data.user.role" formControlName="right">
            <option *ngFor="let roles of userRoles" [value]="roles">{{upperCaseFirst(roles)}}</option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label class="col-md-3 control-label">Delete from group</label>
        <div class="col-md-12">
          <select class="form-control" formControlName="right">
            <option value="0" selected="" disabled="">Select a group</option>
          </select>
        </div>
      </div>

      <div class="modal-btns">
        <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="putRoleToCompanyUser()">Save</button>
      </div>
    </form>
  </div>
</mat-dialog-content>
