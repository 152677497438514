import {ItemPosition} from './interfaces/customization';

export class Utils {
    static isMobile() {
        return window && window.matchMedia('(max-width: 767px)').matches;
    }

    static ngbDateToDate(ngbDate: { month, day, year }) {
        if (!ngbDate) {
            return null;
        }
        return new Date(`${ngbDate.month}/${ngbDate.day}/${ngbDate.year}`);
    }

    static dateToNgbDate(date: Date) {
        if (!date) {
            return null;
        }
        date = new Date(date);
        return {month: date.getMonth() + 1, day: date.getDate(), year: date.getFullYear()};
    }

    static scrollToTop(selector: string) {
        if (document) {
            const element = <HTMLElement>document.querySelector(selector);
            element.scrollTop = 0;
        }
    }

    static genId() {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    static getTimestamp(fontSize, fontFamily, text: string, position: ItemPosition = {left: '1%', bottom: '1%'}, color = '#ACA3A0') {
        return {
            type: 'text',
            ...position,
            id: 'timestamp',
            $action: 'replace',
            style: {
                text,
                fill: color,
                font: `oblique bolder ${fontSize}px ${fontFamily}`
            }
        };
    }

    static getInfobox(fontSize, fontFamily, infoboxFields, titlePos = '16%', contentPos = '85%', color = '#ffffff') {
        const titleText = [
            'Mean speed: ',
            'Peak frequency: ',
            'Peak direction: ',
            'Percent calm: ',
            'Calm defined as: '
        ];
        const valuesText = infoboxFields;
        return [
            {
            type: 'group',
            right: titlePos,
            bottom: '1%',
            id: 'infobox',
            $action: 'replace',
            children: titleText.map((text, idx) => {
              return {
                type: 'text',
                top: `${(fontSize + 2) * idx}px`,
                style: {
                  text,
                  fill: color,
                  font: `${fontSize}px ${fontFamily}`,
                }
              };
            })
        },
            {
            type: 'group',
            left: contentPos,
            bottom: '1%',
            id: 'infobox_values',
            $action: 'replace',
            children: infoboxFields.map((text, idx) => {
              return {
                type: 'text',
                top: `${(fontSize + 2) * idx}px`,
                style: {
                  text,
                  fill: color,
                  font: `${fontSize}px ${fontFamily}`
                }
              };
            })
        },
        ];
    }

    static getLegendTitle(fontSize, fontFamily, text, position: ItemPosition = {left: '83%'}, color = '#ffffff') {
        return {
            type: 'text',
            ...position,
            bottom: '92%',
            $action: 'replace',
            style: {
                text,
                fill: color,
                font: `${fontSize}px ${fontFamily}`
            }
        };
    }

    static getLogo(url: string, position: ItemPosition = {left: '1%', top: '1%'}, size = 20) {
        return {
            type: 'image',
            ...position,
            id: 'logo',
            $action: 'replace',
            style: {
                image: url,
                x: 11,
                y: 11,
                width: `${size}%`,
                height: `${size}%`
            }
        };
    }
}
