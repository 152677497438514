import {Component, Inject, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EditPermissionComponent} from '../edit-permission/edit-permission.component';
import {ProjectPerLevel} from '../../../enum/project.enum';
import {ProjectsService} from '../../../services/projects.service';
import {Projects, ProjectsUser} from '../../../models/project.model';
import {DeteleDiaologComponent} from '../detele-diaolog/detele-diaolog.component';
import {User} from '../../../models/user.model';
import {FormControl, FormGroup} from '@angular/forms';
import {map, mergeMap, startWith, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UtilsService} from '../../../services/utils.service';
import {CompanyService} from '../../../services/company.service';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-access-project',
  templateUrl: './access-project.component.html',
  styleUrls: ['./access-project.component.css']
})
export class AccessProjectComponent implements OnInit, OnChanges {
  @ViewChild('shareLinkButton') shareLinkButton: NgbPopover;
  @ViewChild('iFrameButton') iFrameButton: NgbPopover;
  filteredPermission: Observable<ProjectsUser[]>;
  permissions: ProjectsUser[] = [];
  epm: User[] = [];
  project: Projects = null;
  user: User = null;
  searchForm = new FormGroup({
    search: new FormControl(''),
  });
  private timerId: number;

  constructor(
    private dialog: MatDialog,
    private projectsService: ProjectsService,
    private companyService: CompanyService,
    private utils: UtilsService,
    private dialogRef: MatDialogRef<AccessProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User, project: Projects }
  ) {
  }

  ngOnInit() {
    this.project = this.data.project;
    this.user = this.data.user;
    this.projectsService
      .getProjectPermissions(this.project)
      .pipe(
        tap(permissions => this.permissions = permissions.map(per => new ProjectsUser(per))),
        mergeMap(_ => this.companyService.getCompanyUserByID(this.project.group)),
        tap(epm => this.epm = epm),
      )
      .subscribe(_ => {
        this.onChangeSearch();
      });
  }

  /**
   * Close popover after delay
   * @param delay: number - in seconds
   */
  closePopoverWithDelay(delay: number) {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.timerId = setTimeout(() => {
      this.shareLinkButton.close();
      this.iFrameButton.close();
    }, delay * 1000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('permissions' in changes) {
      if (changes.permissions.currentValue) {
        this.onChangeSearch();
      }
    }
  }

  private onChangeSearch(): void {
    this.filteredPermission = this.searchForm.controls.search.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterMembers(value))
      );
  }

  private filterMembers(value: string): ProjectsUser[] {
    const filterValue = this.utils.normalizeValue(value);
    return this.permissions.filter(permission =>
      this.utils.normalizeValue(permission.user.getFullName()).includes(filterValue) ||
      this.utils.normalizeValue(permission.getStringLevel()).includes(filterValue));
  }

  public editPermission(perm: ProjectsUser): void {
    const dialogRef = this.dialog.open(EditPermissionComponent, {
      width: '700px',
      data: {
        perm: perm,
        user: this.user,
        project: this.project
      }
    });
    dialogRef.afterClosed().subscribe((perLevel: ProjectPerLevel) => {
      if (perLevel) {
        this.projectsService.editProjectPermissions(perm, perLevel)
          .subscribe(newPer => {
            this.permissions = this.permissions.map(p => {
              if (p.id === newPer.id) {
                return new ProjectsUser(newPer);
              }
              return p;
            });
            this.searchForm.controls.search.setValue('');
          });
      }
    });
  }

  public deletePermission(perm: ProjectsUser): void {
    const dialogRef = this.dialog.open(DeteleDiaologComponent, {
      width: '400px',
      data: {
        removable: `the ${perm.user.getFullName()} from project`
      }
    });
    dialogRef.afterClosed().subscribe((isDelete: boolean) => {
      if (isDelete) {
        this.projectsService.deleteProjectPermissions(perm)
          .subscribe(r => {
            this.permissions = this.permissions.filter(p => p.id !== perm.id);
          });
      }
    });
  }

  getFullName(perm: ProjectsUser): string {
    return perm.user.getFullName();
  }

  getStringLevel(perm: ProjectsUser): string {
    return perm.getStringLevel();
  }

  close() {
    this.dialogRef.close();
  }

}
