<ng-container *ngIf="menuToggleType === 'switch'">
    <ui-switch size="small" [checked]="checked" (click)="handleChange(!checked)" color="#9ED8EA" [matTooltip]="checked ? 'Remove' : 'Add'">
    </ui-switch>
</ng-container>

<ng-container *ngIf="menuToggleType === 'burger'">
    <div class="menu-toggle cursor-pointer mx-2" (click)="handleChange(!checked)"
         [matTooltip]="checked ? 'Collapse' : 'Expand'">
        <div></div>
        <div></div>
        <div></div>
    </div>
</ng-container>

<ng-container *ngIf="menuToggleType === 'default'">
    <i *ngIf="!checked" matTooltip="add" (click)="handleChange(true)" class="i-Add show-icon large-icon"></i>
    <i *ngIf="checked" matTooltip="hide" (click)="handleChange(false)" class="i-Close-Window hide-icon large-icon"></i>
</ng-container>

