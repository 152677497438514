<form-wizard [isCustom]="true">
  <wizard-step [title]="'General'">
    <div class="form-group row justify-content-between align-items-center">
      <h4>Text Formatting</h4>
        <app-menu-toggle
                menuToggleType="burger"
                [checked]="!menuSections.textFormatting.hidden"
                (changeState)="toggleMenuSections('textFormatting')">
        </app-menu-toggle>
    </div>
    <div [hidden]="menuSections.textFormatting.hidden">
      <div class="form-group row">
        <label for="general-text-font-input" class="col-form-label col-4 align-self-center p-0">Text font</label>
        <select class="form-control menu-list-default col-7 p-0"
                id="general-text-font-input"
                (input)="changeTextFont(currentOptions, $event)"
                [value]="currentOptions.textStyle?.fontFamily.toLowerCase()">
          <option *ngFor="let font of textFontList" [value]="font.toLowerCase()">{{ font }}</option>
        </select>
      </div>
      <div class="form-group row">
        <label for="font-size-input" class="col-form-label col-4 align-self-center p-0">Text size</label>
        <input type="text"
               id="font-size-input"
               class="form-control col-7 p-0"
               [class.input-error]="!fontSizeField.valid"
               [formControl]="fontSizeField">
      </div>
      <div class="form-group row">
        <label for="text-color-input" class="col-form-label  col-7 align-self-center p-0">Text color</label>
        <input
                class="series-color-input ml-0"
                type="color"
                id="text-color-input"
                [value]="currentOptions.textStyle.color || '#ffffff'"
                (input)="changeTextColor(currentOptions, $event)">
      </div>
    </div>

    <div class="form-group row justify-content-between align-items-center">
      <h4>Title & Subtitle</h4>
      <div class="d-flex">
        <app-menu-toggle
                menuToggleType="switch"
                [checked]="currentOptions?.title?.show"
                (changeState)="changeTitleDisplay(currentOptions, $event)">
        </app-menu-toggle>
        <app-menu-toggle
                menuToggleType="burger"
                [checked]="!menuSections.titleAndSubtitle.hidden"
                (changeState)="toggleMenuSections('titleAndSubtitle')">
        </app-menu-toggle>
      </div>
    </div>
    <div [hidden]="menuSections.titleAndSubtitle.hidden">
      <div class="form-group row pl">
        <mat-checkbox
                class="text-16 col-6 p-0" color="primary"
                [checked]="currentOptions?.title?.textStyle?.fontWeight === 'bolder'"
                [disabled]="!currentOptions?.title?.show"
                [labelPosition]="'before'"
                (change)="changeTitleBold(currentOptions, $event)">
          Bold
        </mat-checkbox>
      </div>

      <div class="form-group row pl">
        <label for="title-text-input" class="col-form-label col-4 align-self-center p-0">Title text</label>
        <input type="text"
               id="title-text-input"
               class="form-control col-7 p-0"
               [(ngModel)]="titleText"
               [disabled]="!currentOptions?.title?.show"
               (input)="changeTitleText(currentOptions.title, $event)"
        >
      </div>
      <div class="form-group row pl">
        <label for="title-font-size-input" class="col-form-label  col-4 align-self-center p-0">Font size</label>
        <input type="text"
               id="title-font-size-input"
               class="form-control col-7 p-0"
               [class.input-error]="!titleFontSizeField.valid"
               [formControl]="titleFontSizeField"
        >
      </div>
      <div class="form-group row pl">
        <mat-checkbox
                class="text-16" color="primary"
                [checked]="isEnabledSubtextField"
                [labelPosition]="'before'"
                [disabled]="!currentOptions?.title?.show"
                (change)="changeSubtitleDisplay(currentOptions, $event)">
          Show subtitle
        </mat-checkbox>
      </div>
      <div class="form-group row pl" *ngIf="isEnabledSubtextField">
        <label for="subtitle-text-input" class="col-form-label  col-5 align-self-center p-0">Subtitle text</label>
        <input type="text"
               id="subtitle-text-input"
               class="form-control col-6 p-0"
               [(ngModel)]="subtitleText"
               [disabled]="!currentOptions?.title?.show"
               (input)="changeSubtitleText(currentOptions.title, $event)"
        >
      </div>
    </div>

    <div>
      <ng-container *ngTemplateOutlet="headerSection;
      context: {
        headerText: 'Info box',
        sectionName: 'hideInfoBox',
        checked: showInfoBox,
        changeFn: changeInfoBoxDisplay.bind(roseGraphCustomizationInstance)
      }">
      </ng-container>
      <div class="form-group row pl" [hidden]="currentOptions.customOptions.hideInfoBox">
        <div class="mb-2">
        </div>
        <div>
          <label id="info-box-radio-group-label" class="mr-2">Positioning</label>
          <mat-radio-group
                  aria-labelledby="info-box-radio-group-label">
            <mat-radio-button *ngFor="let position of infoBoxPositions" [value]="position"
                              [labelPosition]="'before'" class="ml-1"
                              (click)="changeInfoBoxPosition(position)" color="primary">
              {{position.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="headerSection;
    context: {
      headerText: 'Timestamp',
      sectionName: 'hideTimestamp',
      checked: showTimestamp,
      changeFn: changeTimestampDisplay.bind(roseGraphCustomizationInstance)
    }">
    </ng-container>
    <div [hidden]="currentOptions.customOptions.hideTimestamp">
      <div class="form-group row pl">
        <label for="subtitle-text-input" class="col-form-label  col-6 align-self-center p-0">Timestamp text</label>
        <input type="text"
               id="timestamp-text-input"
               class="form-control col-5 p-0"
               [(ngModel)]="timestampText"
               (input)="changeTimestampText($event)"
        >
      </div>
      <div class="form-group row">
        <h5 class="text-center w-100 mr-2">Timestamp placement</h5>
        <div class="col-5 p-0">
          <label class="col-form-label col-12 align-self-center p-0">Top corner</label>
          <label class="col-form-label mt-1 col-12 align-self-center p-0">Bottom corner</label>
        </div>
        <div class="col-6 p-0">
        <mat-radio-group>
          <mat-radio-button *ngFor="let position of elementsPositions" [value]="position.position"
                            class="col-6 p-0" [labelPosition]="'before'"
                            (click)="changeTimestampPosition(position.position)" color="primary">
            {{position.name}}
          </mat-radio-button>
        </mat-radio-group>
        </div>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="headerSection;
    context: {
      headerText: 'Logo',
      sectionName: 'hideLogo',
      checked: showLogo,
      changeFn: changeLogoDisplay.bind(roseGraphCustomizationInstance)
    }">
    </ng-container>
    <div [hidden]="currentOptions.customOptions.hideLogo">
      <div class="form-group row pl">
        <button class="btn btn-outline-primary m-1 mx-auto mb-3" (click)="uploadLogo()">
          Upload logo <i class="i-Upload"></i>
        </button>
        <h5 class="text-center w-100 mr-2">Logo placement</h5>
        <div class="col-5 p-0">
          <label class="col-form-label col-12 align-self-center p-0">Top corner</label>
          <label class="col-form-label mt-1 col-12 align-self-center p-0">Bottom corner</label>
        </div>
        <div class="col-6 p-0">
          <mat-radio-group>
            <mat-radio-button *ngFor="let position of elementsPositions" [value]="position.position"
                              class="col-6 p-0" [labelPosition]="'before'"
                              (click)="changeLogoPosition(position.position)" color="primary">
              {{position.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="form-group row pl">
        <label for="zoom-logo-range" class="col-form-label w-100">Zoom Settings</label>
        <input type="range"
               id="zoom-logo-range"
               class="w-100 form-control custom-range border-0 mx-2 p-0"
               [min]="zoomSizes.MIN_LOGO_SIZE" [max]="zoomSizes.MAX_LOGO_SIZE" step="1"
               [value]="logoZoom"
               (input)="changeLogoZoom($event)"
        >
      </div>
    </div>

    <ng-container *ngTemplateOutlet="headerSection;
    context: {
      headerText: 'Speed Scale',
      sectionName: 'hideWindSpeedScale',
      checked: currentOptions?.legend?.show,
      changeFn: changeLegendDisplay.bind(roseGraphCustomizationInstance)
    }">
    </ng-container>
    <div [hidden]="currentOptions.customOptions.hideWindSpeedScale">
      <div class="form-group row pl">
        <label id="legend-radio-group-label" class="mr-2">Positioning</label>
        <mat-radio-group
          aria-labelledby="legend-radio-group-label">
          <mat-radio-button *ngFor="let position of legendPositions" [value]="position.position"
                            [labelPosition]="'before'" class="ml-1"
                            (click)="changeLegendPosition(position.position)" color="primary">
            {{position.name}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div>
        <div class="form-group row pl">
          <div class="col-11 p-0 m-0 d-flex" *ngFor="let option of roseLabelsReverse; let i = index">
            <mat-checkbox
              class="text-16" color="primary"
              [checked]="hiddenValues.includes(option)"
              (change)="changeSeriesDisplay(option, $event)">
              Hide {{option}}
            </mat-checkbox>
            <input
              class="series-color-input"
              type="color"
              [value]="palette[i]"
              (input)="changePalette($event, i)">
          </div>
        </div>
      </div>
    </div>

  </wizard-step>

  <wizard-step [title]="'Chart'">
    <div class="form-group row pl">
      <label for="zoom-polar-range" class="col-form-label w-100">Chart size</label>
      <input type="range"
             id="zoom-polar-range"
             class="w-100 form-control custom-range border-0 mx-2 p-0"
             [min]="zoomSizes.MIN_POLAR_SIZE" [max]="zoomSizes.MAX_POLAR_SIZE" step="1"
             [value]="polarZoom"
             (input)="changePolarZoom($event)"
      >
    </div>


    <div>
      <div class="form-group row justify-content-between align-items-center">
        <h4>Grid</h4>
        <app-menu-toggle
                menuToggleType="burger"
                [checked]="!menuSections.grid.hidden"
                (changeState)="toggleMenuSections('grid')">
        </app-menu-toggle>
      </div>
      <div [hidden]="menuSections.grid.hidden">
        <div class="form-group row pl">
          <mat-checkbox
                  class="text-16 mr-3 aligned" color="primary"
                  [checked]="$any(currentOptions.angleAxis)?.axisLabel?.show"
                  [labelPosition]="'before'"
                  (change)="changeAngleAxisLabelDisplay(currentOptions.angleAxis, $event)">
            Show labels
          </mat-checkbox>
        </div>
        <div class="form-group row pl">
          <mat-checkbox
                  class="text-16 mr-3 aligned" color="primary"
                  [checked]="$any(currentOptions.angleAxis)?.axisTick?.show"
                  [labelPosition]="'before'"
                  (change)="changeAngleAxisTickDisplay(currentOptions.angleAxis, $event)">
            Show tick
          </mat-checkbox>
        </div>
        <div class="form-group row pl">
          <mat-checkbox
                  class="text-16 mr-3 aligned" color="primary"
                  [checked]="$any(currentOptions.angleAxis)?.splitLine?.show"
                  [labelPosition]="'before'"
                  (change)="changeAngleSplitLineDisplay(currentOptions.angleAxis, $event)">
            Show spokes line
          </mat-checkbox>
        </div>
        <div class="form-group row pl">
          <mat-checkbox
                  class="text-16 mr-3 aligned" color="primary"
                  [checked]="$any(currentOptions.radiusAxis)?.splitLine?.show"
                  [labelPosition]="'before'"
                  (change)="changeRadiusSplitLineDisplay(currentOptions.radiusAxis, $event)">
            Show radius line
          </mat-checkbox>
        </div>
        <div class="form-group row pl">
          <label for="general-opacity-range" class="col-form-label w-100">Transparency</label>
          <input type="range"
                 id="general-opacity-range"
                 class="w-100 form-control custom-range border-0 mx-2 p-0"
                 min="0" max="1" step="0.1"
                 [value]="$any(currentOptions.angleAxis)?.axisLine?.lineStyle?.opacity || 1"
                 (input)="changeLineTransparency(currentOptions, $event)"
          >
        </div>
      </div>
    </div>
    <div class="form-group row justify-content-between align-items-center">
      <h4>Axis</h4>
      <div class="d-flex">
        <app-menu-toggle
                menuToggleType="switch"
                [checked]="$any(currentOptions.radiusAxis)?.axisLabel?.show || $any(currentOptions.radiusAxis)?.axisTick?.show || $any(currentOptions.radiusAxis)?.axisLine?.show"
                (changeState)="changeNorthLineDisplay($event)">
        </app-menu-toggle>
        <app-menu-toggle
                menuToggleType="burger"
                [checked]="!menuSections.angleAxis.hidden"
                (changeState)="toggleMenuSections('angleAxis')">
        </app-menu-toggle>
      </div>
    </div>
    <div [hidden]="menuSections.angleAxis.hidden">
      <div class="form-group row pl">
        <mat-checkbox
          class="text-16 mr-3 aligned" color="primary"
          [checked]="$any(currentOptions.radiusAxis)?.axisLabel?.show"
          [labelPosition]="'before'"
          (change)="changeRadiusAxisLabelDisplay(currentOptions.radiusAxis, $event)">
          Show labels
        </mat-checkbox>
      </div>
      <div class="form-group row pl">
        <mat-checkbox
          class="text-16 mr-3 aligned" color="primary"
          [checked]="$any(currentOptions.radiusAxis)?.axisTick?.show"
          [labelPosition]="'before'"
          (change)="changeRadiusAxisTickDisplay(currentOptions.radiusAxis, $event)">
          Show tick
        </mat-checkbox>
      </div>
      <div class="form-group row pl">
        <mat-checkbox
          class="text-16 mr-3 aligned" color="primary"
          [checked]="$any(currentOptions.radiusAxis)?.axisLine?.show"
          [labelPosition]="'before'"
          (change)="changeRadiusAxisLineDisplay(currentOptions.radiusAxis, $event)">
          Show line
        </mat-checkbox>
      </div>
    </div>
  </wizard-step>

  <wizard-step [title]="'BKGRND'">
    <div class="form-group row justify-content-between">
      <div class="card-title align-self-center p-0">Background color</div>
      <app-menu-toggle
        class="text-16 p-0"
        menuToggleType="burger"
        [checked]="showBackgroundColorMenu"
        (changeState)="showBackgroundColorMenu = !showBackgroundColorMenu">
      </app-menu-toggle>
    </div>

    <div class="form-group row" *ngIf="showBackgroundColorMenu">
      <label for="color-input" class="col-form-label mr-1 align-self-center p-0">Choose background color</label>
      <input
          class="series-color-input ml-0"
          type="color"
          id="color-input"
          [value]="currentOptions.backgroundColor || '#ffffff'"
          (input)="changeBackgroundColor(currentOptions, $event)">
    </div>


    <div class="form-group row justify-content-between">
      <div class="card-title align-self-center p-0">Background Image</div>
      <app-menu-toggle
        class="text-16 p-0"
        menuToggleType="burger"
        [checked]="showBackgroundImageMenu"
        (changeState)="showBackgroundImageMenu = !showBackgroundImageMenu">
      </app-menu-toggle>
    </div>

    <div class="form-group row" *ngIf="showBackgroundImageMenu">
      <button class="btn btn-outline-primary col-10 mx-auto" type="button" (click)="changeProjectMapAsBackgroundRose()">
          Use satellite station image
      </button>

      <button class="btn btn-outline-primary col-10 mx-auto mt-2" type="button" (click)="uploadBackground()">
        Upload background
      </button>
    </div>



    <div class="form-group row justify-content-between">
      <div class="card-title align-self-center p-0">Background Map</div>
      <app-menu-toggle
        class="text-16 p-0"
        menuToggleType="burger"
        (click)="autocompleteChange()"
        [checked]="showBackgroundMapMenu"
        (changeState)="showBackgroundMapMenu = !showBackgroundMapMenu">
      </app-menu-toggle>
    </div>
    <div class="form-group row" *ngIf="showBackgroundMapMenu">
      <button class="btn btn-outline-primary col-10 mx-auto" type="button" *ngIf="!isCustomizationMap" (click)="setBackgroundMap()">
        Use Map to choose
      </button>
      <div
              *ngIf="isCustomizationMap"
              class="search-bar col-12">
        <input
                #addressText
                [(ngModel)]="country"
                type="text"
                id="country-text-input"
                class="form-control col-12 p-0 search-input"
        >
        <i  class="search-icon text-muted i-Magnifi-Glass1">
        </i>
      </div>
    </div>
    <div class="form-group row mt-2" *ngIf="showBackgroundMapMenu && isCustomizationMap">
      <label class="col-form-label mr-1 align-self-center p-0">Choose map background</label>
      <i (click)="chooseMapBackground()"  class="i-Yes text-right accept-icon mr-2"></i>
    </div>

    <div class="form-group row mt-2">
      <label class="col-form-label mr-1 align-self-center p-0">Clear background</label>
      <i (click)="clearBackground()" class="i-Reload clear-icon mr-2"></i>
    </div>
  </wizard-step>
</form-wizard>

<ng-template #headerSection let-sectionName="sectionName" let-headerText="headerText" let-checked="checked" let-changeFn="changeFn">
  <div class="form-group row justify-content-between align-items-center">
    <h4 class="align-self-center p-0 mb-0">{{ headerText }}</h4>
    <div class="d-flex">
      <app-menu-toggle
              menuToggleType="switch"
              [checked]="checked"
              (changeState)="changeFn($event)">
      </app-menu-toggle>
      <div class="menu-toggle cursor-pointer mx-2" (click)="toggleCustomOptionMenu(sectionName)"
           [matTooltip]="currentOptions.customOptions[sectionName] ? 'Expand' : 'Collapse'">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</ng-template>
