import {AfterContentInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ProjectsService} from '../../../services/projects.service';
import {User} from '../../../models/user.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectAccess} from '../../../enum/project.enum';
import {Projects} from '../../../models/project.model';
import {UtilsService} from '../../../services/utils.service';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.css']
})

export class EditProjectComponent implements OnInit, AfterContentInit {
  projectAccess = ProjectAccess;
  project: Projects = null;
  editProjectForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    access: new FormControl(''),
    icon: new FormControl(''),
  });
  isChanged: boolean = false;
  fileName: any = null;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<EditProjectComponent>,
    private projectsService: ProjectsService,
    private utilsService: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: {user: User, project: Projects}
  ) { }

  ngOnInit() {
    this.project = this.data.project;
  }

  ngAfterContentInit(): void {
    this.setProjectData();
  }

  private setProjectData(): void {
    this.editProjectForm.controls.title.setValue(this.data.project.title);
    this.editProjectForm.controls.access.setValue(this.data.project.access);
    this.fileName = (this.project.cover) ? this.utilsService.getFileNameFromURL(this.data.project.cover) : "";
  }

  coverUpload(fileInput: any) {
    this.fileName = fileInput.target.files[0].name as File;
    this.projectsService.setCover(this.project, fileInput.target.files[0] as File).subscribe();
    this.isChanged = true;
  }

  coverDelete() {
    this.projectsService.deleteCover(this.project).subscribe(r => {
      this.fileName = null;
      this.project.cover = null;
      this.isChanged = true;
    });
  }

  setEditData(): {} {
    let dataEdit: {} = {};
    if (this.editProjectForm.controls.title.value !== this.project.title) {
      dataEdit['title'] = this.editProjectForm.controls.title.value;
    }
    if (this.editProjectForm.controls.access.value !== this.project.access) {
      dataEdit['access'] = this.editProjectForm.controls.access.value;
    }
    return dataEdit;
  }

  public saveProject(): void {
    const dataEdit = this.setEditData();
    if (Object.keys(dataEdit).length) {
      this.projectsService
        .editProject(this.project, dataEdit)
        .subscribe(() => {
          this.isChanged = true;
          this.close(this.isChanged);
        });
    } else {
      this.isChanged = false;
      this.close(this.isChanged);
    }
  }

  public close(isChange: boolean = false): void {
    this.dialogRef.close(isChange);
  }
}
