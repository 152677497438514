import {Projects} from "../models/project.model";
import {linkedGroups} from "../models/company.model";

export class Notifications {
  project: Projects;
  group: linkedGroups;
  created_at: Date | string;
  message: string;

  constructor(init?: Partial<Notifications>) {
    Object.assign(this, init);
    if (typeof this.group === 'object') {
      this.group = new linkedGroups(this.group);
    }
    if (typeof this.project === 'object') {
      this.project = new Projects(this.project);
    }
    const a = this.created_at as string;
    if (a.indexOf('Z') + 1) {
      const str = new Date(this.created_at).toISOString();
      this.created_at = this.parseISOString(str);
    } else {
      this.created_at = new Date(this.created_at);
    }
  }

  parseISOString(s) {
    const b = s.split(/\D+/);
    return new Date(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]);
  }

}
