<h2 mat-dialog-title>Upload & Crop image <i (click)="close()" class="i-Close-Window modal-close"></i></h2>
<mat-dialog-content>
  <img-cropper [image]="data" [settings]="cropperSettings"></img-cropper><br>
  <p><strong>Note: File size should be less than 2.5MB.</strong></p>
  <div *ngIf="settings.showThumbnail && data.image">
    <img [src]="data.image" class="rounded-circle" alt="" width="100" height="100">
  </div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: flex-end;">
  <div class="modal-btns">
    <button type="button" class="btn btn-primary waves-effect m-0" (click)="save()">Save</button>
  </div>
</mat-dialog-actions>
