import {DashboardStyles} from '../models/project.model';

export enum ProjectFolder {
    COMPANY = 'Company',
    PERSONAL = 'Personal'
}

export enum ProjectAccess {
    PRIVATE = 100,
    AvailableToView = 200,
    AvailableToEdit = 300
}

export enum ProjectPerLevel {
    PERSONAL = 400,
    CanDelete = 300,
    CanEditAccessLevel = 250,
    CanEdit = 200,
    CanView = 100
}

export enum ActionProject {
    VIEW = 'VIEW',
    ACCESS = 'ACCESS',
    SHARE = 'SHARE'
}

export enum StepCreateProject {
    ONE = 'ONE',
    TWO = 'TWO',
    THREE = 'THREE',
    FINAL = 'FINAL',
    UNSET = 'UNSET'
}

export enum Spokes {
    Four = 'Four',
    Eight = 'Eight',
    Sixteen = 'Sixteen',
    ThirtyTwo = 'Thirty Two',
}

export enum TypeDocumentProject {
    DashboardImage = 'Dashboard image',
    windDirectionsCsv = 'Wind directions csv',
    windDistributionTableCsv = 'Wind distribution table csv',
    chartImage = 'Chart image',
    chartSVG = 'Chart svg',
    windRoseImage = 'Wind rose image',
    windRoseSVG = 'Wind rose svg',
    tableAndChartImage = 'Table and chart image',
    satImagePNG = 'Sat. image PNG'
}

export enum Regularity {
    HOURLY = 'Hourly',
    HOURLY_DAY = 'Hourly: DayTime Data ',
    HOURLY_NIGHT = 'Hourly: NightTime Data ',
    TwoHourly = '2 hourly',
    ThreeHourly = '3 hourly',
    SixHourly = '6 hourly',
    TwiceDaily = 'Twice daily',
    DAILY = 'Daily',
}

export enum DayNightRegularityEnum {
    DAY_AND_NIGHT = 'day and night',
    DAY = 'day',
    NIGHT = 'night',
}

export enum ErrorMessage {
    errorSetStationCompany =
        'The number of stations available for purchase according to your plan has expired.' +
        'You can select stations that you have already purchased.' +
        'If you own a company, you can renew your subscription.',
    errorSetStationPersonal = 'You have exhausted the limit of available stations of your subscription, please select the option below',
    errorDoesNotHaveSubscription =
        'In order to get access to data from over 30,000 weather stations, you first need to purchase a subscription.' +
        'You will also be able to process this data into a beautiful wind rose diagram!',
    errorSetStationCompanyUser = 'Your company\'s subscription expired, please contact the administration for further information.'
}

export const selectedMarker = './assets/images/Geo-icon-selected.png';
export const markerStation = './assets/images/Geo-icon.png';
export const markerCountry = './assets/images/Geo-icon-country.png';
export const searchMarker = './assets/images/Geo-icon2.png';

export enum DashboardDiv {
    WindSpeedChart = 'WindSpeedChart',
    RoseGraph = 'RoseGraph',
    Table = 'Table',
    SatelliteImage = 'SatelliteImage',
}

export const DEFAULT_DASHBOARD_STYLE: DashboardStyles[] = [
    {name: DashboardDiv.RoseGraph, style: {display: 'block'}, class: 'col-lg-12'},
    {name: DashboardDiv.Table, style: {display: 'block'}, class: 'col-lg-12'},
    {name: DashboardDiv.WindSpeedChart, style: {display: 'block'}, class: 'col-lg-6'},
    {name: DashboardDiv.SatelliteImage, style: {display: 'block'}, class: 'col-lg-6'},
];

export enum BinConfiguration {
  BINS_STANDARD = 'standard',
  BINS_BEAUFORT = 'beaufort',
  BINS_CUSTOM = 'custom'
}

export const BeaufortScaleBinConfigurationMS: string[] = [
  '0 - 0.3',
  '0.3 - 1.5',
  '1.5 - 3.3',
  '3.3 - 5.5',
  '5.5 - 7.9',
  '7.9 - 10.7',
  '10.7 - 13.8',
  '13.8 - 17.1',
  '17.1 - 20.7',
  '20.7 - 24.4',
  '24.4 - 28.4',
  '28.4 - 32.6',
  '> 32.6',
];

export const BeaufortScaleBinConfigurationMPH: string[] = [
  '0 - 0.9',
  '0.9 - 3.0',
  '3.0 - 7.0',
  '7.0 - 12.0',
  '12.0 - 18.0',
  '18.0 - 24.0',
  '24.0 - 31.0',
  '31.0 - 38.0',
  '38.0 - 46.0',
  '46.0 - 54.0',
  '54.0 - 63.0',
  '63.0 - 72.0',
  '> 72.0'
];

export const BeaufortScaleBinConfigurationKN: string[] = [
  '0 - 1.0',
  '1.0 - 3.0',
  '3.0 - 6.0',
  '6.0 - 10.0',
  '10.0 - 16.0',
  '16.0 - 21.0',
  '21.0 - 27.0',
  '27.0 - 33.0',
  '33.0 - 40.0',
  '40.0 - 47.0',
  '47.0 - 55.0',
  '55.0 - 63.0',
  '> 63.0'
];

export const BeaufortScaleBinConfigurationKMH: string[] = [
  '0 - 1.0',
  '1.0 - 5.0',
  '5.0 - 11.0',
  '11.0 - 19.0',
  '19.0 - 28.0',
  '28.0 - 38.0',
  '38.0 - 49.0',
  '49.0 - 61.0',
  '61.0 - 74.0',
  '74.0 - 88.0',
  '88.0 - 102.0',
  '102.0 - 117.0',
  '> 117.0'
];

export const StandardBinConfiguration: string[] = [
  '0 - 0.3',
  '0.3 - 1.0',
  '1.0 - 2.0',
  '2.0 - 3.0',
  '3.0 - 4.0',
  '4.0 - 5.0',
  '5.0 - 6.0',
  '6.0 - 7.0',
  '7.0 - 8.0',
  '8.0 - 9.0',
  '9.0 - 10.0',
  '10.0 - 11.0',
  '11.0 - 12.0',
  '12.0 - 13.0',
  '13.0 - 14.0',
  '14.0 - 15.0',
  '15.0 - 16.0',
  '16.0 - 17.0',
  '17.0 - 18.0',
  '18.0 - 19.0',
  '19.0 - 20.0',
  '> 20.0'
];

export const PaletteWithCalm: string[] = [
  '#696969',
  '#AEF1F9',
  '#96F7DC',
  '#96F7B4',
  '#6FF46F',
  '#73ED12',
  '#A4ED12',
  '#DAED12',
  '#EDC212',
  '#ED8F12',
  '#ED6312',
  '#ED2912',
  '#D5102D'
];

export const Palette: string[] = [
  '#AEF1F9',
  '#96F7DC',
  '#96F7B4',
  '#6FF46F',
  '#73ED12',
  '#A4ED12',
  '#DAED12',
  '#EDC212',
  '#ED8F12',
  '#ED6312',
  '#ED2912',
  '#D5102D'
];
