import { Component, Inject } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { AuthService } from '../../../services/auth.service';
import { RegistrationRequest } from '../../../models/auth.model';

@Component({
  selector: 'app-add-email-facebook',
  templateUrl: './add-email-facebook.component.html',
  styleUrls: ['./add-email-facebook.component.scss']
})
export class AddEmailFacebookComponent {

  constructor(
    public dialogRef: MatDialogRef<AddEmailFacebookComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, user: RegistrationRequest },
  ) { }

  close(): void {
    this.dialogRef.close(null);
  }

  register(email: NgModel): void {
    if (email.errors) {
      return;
    }
    this.authService.register({ ...this.data.user, email: email.value.trim() })
      .pipe(catchError(error => throwError(error)))
      .subscribe(
        () => this.dialogRef.close({ needToConfirmEmail: true }),
        error => console.error(error)
      );
  }
}
