import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CompanyService} from "../../../services/company.service";
import {AuthService} from "../../../services/auth.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.css']
})
export class AddGroupComponent implements OnInit {
  addGroupForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });
  constructor(
    public dialogRef: MatDialogRef<AddGroupComponent>,
    private companyService: CompanyService,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: {}
  ) { }

  ngOnInit() {
  }

  addGroup(): void {
    if (this.addGroupForm.invalid) {
      return;
    }
    this.companyService
      .addGroup(this.addGroupForm.value.name)
      .subscribe(group => {
        this.dialogRef.close(group);
      });
  }

  close(): void {
    this.dialogRef.close();
  }
}
