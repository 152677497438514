import {UserRoles} from '../enum/user-roles.enum';
import {Subscription} from './payment.model';

export class linkedGroups {
  id?: number;
  billing_address: string;
  email: string;
  name: string;
  phone: string;
  postal_code: number;
  owner: number;
  is_company: boolean;
  logo: string;
  role: UserRoles;
  subscription: Subscription;
  stripe_customer_id: string;
  notification_on: boolean = true;
  notification_owner: boolean = true;
  notification_admin: boolean = true;
  notification_user: boolean = true;

  constructor(init?: Partial<linkedGroups>) {
    Object.assign(this, init);
    this.subscription = new Subscription(this.subscription);
  }
}
