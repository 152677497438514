import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  isPrivacyPage: boolean = false;
  constructor(private router: Router) { }

  ngOnInit() {
    this.isPrivacyPage = this.router.url.toLowerCase().indexOf('privacy-policy') !== -1;
  }

  closeTab(): void {
    window.close();
  }

}
