import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../../services/auth.service';
import {User} from '../../../models/user.model';
import {Router} from '@angular/router';
import {Lightbox} from 'ngx-lightbox';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-send-report',
  templateUrl: './send-report.component.html',
  styleUrls: ['./send-report.component.scss']
})
export class SendReportComponent implements OnInit {
  reportTextControl: FormControl;
  user: User;
  time: string;
  file: File = null;
  videoFile = null;
  imgURL;
  videoURL;
  fromPage: string;
  constructor(
    public dialogRef: MatDialogRef<SendReportComponent>,
    private authService: AuthService,
    private router: Router,
    private lightbox: Lightbox,
    @Inject(MAT_DIALOG_DATA) public data: {}
  ) {}

  ngOnInit() {
    this.user = new User(this.authService.user);
    const date = new Date();
    this.time = `${date.getHours()}:${date.getMinutes()}`;
    this.fromPage = this.router.url;
    this.reportTextControl = new FormControl('', [Validators.required, Validators.maxLength(5000)]);
  }

  sendReport(): void {
    const formData = new FormData();
    if (this.file) {
      formData.append('attachment', this.file);
    }
    if (this.videoFile) {
      formData.append('video_file', this.videoFile);
    }
    formData.append('message', this.reportTextControl.value.trim());
    formData.append('from_page', this.fromPage);
    this.authService
      .sendReport(formData)
      .subscribe(r => {
        this.dialogRef.close(true);
      });
  }

  imageUpload(fileInput: any) {
    this.file = fileInput.target.files[0] as File;
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => {
      this.imgURL = reader.result;
    };
  }

  videoUpload(fileInput: any) {
    this.videoFile = fileInput.target.files[0] as File;
    const reader = new FileReader();
    reader.readAsDataURL(this.videoFile);
    reader.onload = () => {
      this.videoURL = reader.result;
    };
  }

  close(): void {
    this.dialogRef.close();
  }

  openImage(imgSrc: string) {
    this.lightbox.open([{src: imgSrc, thumb: ''}]);
  }
}
