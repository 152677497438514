import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationService} from '../../../../services/navigation.service';
import {AuthService} from '../../../../services/auth.service';
import {ModalWindowService} from '../../../../services/modal-window.service';
import {NotificationService} from "../../../../services/notification.service";
import {Observable, Subject} from "rxjs";
import {Notifications} from "../../../../interfaces/notifications";
import {filter, takeUntil} from "rxjs/operators";
import {Router} from "@angular/router";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit, OnDestroy {
  isOpen: boolean = false;
  sidebarState: { sidenavOpen?: boolean, childnavOpen?: boolean };
  avatarSrc: string = './assets/images/account.png';
  notifications$: Observable<Notifications[]>;
  timeOut;
  notifiClass: string = 'multi-notification';
  private componentDestroy$: Subject<void> = new Subject();
  constructor(
    private navService: NavigationService,
    public authService: AuthService,
    private router: Router,
    private modalWindowService: ModalWindowService,
    private notificationService: NotificationService,
  ) {
  }

  ngOnInit() {
    if (!localStorage.getItem('Authorization')) {
      return;
    }
    this.notifications$ = this.notificationService.unreadNotifications$;
    this.notificationService.trigger.pipe(
      takeUntil(this.componentDestroy$),
      filter(bool => bool && !this.authService.user.is_muted),
    ).subscribe(_ => {
      this.clearTimeOut();
      this.notifiClass = 'solo-notification';
      this.isOpen = true;
      this.timeOut = setTimeout(_ => {
        this.isOpen = false;
        this.notifiClass = 'multi-notification';
      }, 1000 * 3);
    });
    this.notificationService.init();
    this.sidebarState = this.navService.sidebarState;
  }

  goToNotificationPage() {
    if (this.notificationService.countUnreadNotification()) {
      this.notificationService
        .makeAllNotificationAsRead()
        .subscribe(() => {
          this.goToNotificationCenterPage();
        });
    } else {
      this.goToNotificationCenterPage();
    }
  }

  goToNotificationCenterPage(): void {
    this.router.navigateByUrl('dashboard/notifications');
    this.isOpen = false;
  }

  showNotification() {
    this.isOpen = false;
    this.notifiClass = 'multi-notification';
    this.clearTimeOut();
    this.isOpen = true;
  }

  closeNotification() {
    this.isOpen = false;
  }

  clearTimeOut() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  }

  ngOnDestroy(): void {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  toggleSidebar() {
    const state = this.navService.sidebarState;
    if (state.childnavOpen && state.sidenavOpen) {
      return state.childnavOpen = false;
    }
    if (!state.childnavOpen && state.sidenavOpen) {
      return state.sidenavOpen = false;
    }
    if (!state.sidenavOpen && !state.childnavOpen) {
      state.sidenavOpen = true;
    }
  }

  signout() {
    this.authService.logout().subscribe();
  }

  openProjectCreationModal() {
    this.modalWindowService.openProjectCreationModal();
  }

  openSendReportModal(): void {
    this.modalWindowService.openSendReportModal().subscribe((result) => {
      if (result) {
        this.modalWindowService.showSuccessReportWindow();
      }
    });
  }

  goToGroup(isCompany) {
    this.notificationService.goToGroup(isCompany);
  }

  get isTrialProject(): boolean {
    const company = this.authService.user.getFirstCompany();
    if (company && company.subscription && company.subscription.isActive()) {
      return company.subscription.isTrial;
    }
    if (this.authService.user.subscription && this.authService.user.subscription.isActive()) {
      return this.authService.user.subscription.isTrial;
    }
    return true;
  }

  getPrivacyPolicyLink(): string {
    return `${environment.PROJECT_DOMAIN}/auth/privacy-policy`;
  }
}
