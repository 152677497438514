<h2 mat-dialog-title>{{ data.title }} <i (click)="close()" class="i-Close-Window modal-close"></i></h2>
<mat-dialog-content>
    <ng-container *ngIf="data.type === 'web'">
        <div class="panel-body">
            <p>WRE’s design team is constantly developing our Web App.
                During this process we keep the following three targets in mind: keep it simple, keep it intuitive,
                and produce a powerful product for our customers.
            </p>
            <p><strong>What to Expect:</strong></p>
            <ol>
                <li>Fetch wind data from over 30,000 data sources around the world.</li>
                <li>Visualise this data by producing customisable wind distribution graphs and wind rose diagrams overlaid on satellite images.</li>
                <li>Download your graphs as scalable vector graphics (SVGs) or image files.</li>
            </ol>
            <p>
                <strong>One subscription pays for your entire company:</strong>
                <br>
                Once you fill in your company’s basic details, each company has the ability to create unlimited user accounts.
            </p>
            <p>
                <strong>Sharing Data Analytics:</strong>
                <br>
                Users can share their projects with other users within your company. In addition, groups can be formed to share projects with individuals outside of your company.
            </p>
            <p>
                <strong>Customer Support:</strong>
                <br>
                We are a small company with a very niche product.
                As such we enjoy close, supportive relationships with our customers.
                Every page within the web app has a support icon.
                When clicked, this automatically tells us where you are within the web app.
                We then require a simple explanation of your problem.
                We will then aim to provide a solution within 1 - 20 hours, depending on your time zone.
            </p>
            <p>
                <strong>More Information needed?</strong>
                <br>
                For more information, including videos and screenshots please click here. We look forward to working with you!
            </p>
            <ng-container *ngTemplateOutlet="director"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="data.type === 'wre'">
        <div class="panel-body">
            <p>WRE v1.7 has been downloaded by over 500 customers all over the world. It has been designed for the user who has access to their own wind speed data.</p>
            <p>
                <strong>What to Expect:</strong>
                <br>
                The software is written in visual basic and will guide you through 3 key steps:
            </p>
            <ol>
                <li>Data entry and basic wind rose diagram options;</li>
                <li>Detailed wind rose diagram customization;</li>
                <li>Exporting your  final diagrams are highly customisable;</li>
            </ol>
            <p><strong>Additional Features:</strong></p>
            <ol>
                <li>Inbuilt WRE data filter removes data with incorrect formatting.</li>
                <li>Users can decide on a range spoke numbers.</li>
                <li>Users can filter the logged wind speeds into wind speed bins (ranges for each bin is customisable).</li>
                <li>Users can also choose between the following wind speed units: mph, kph, knots and m/s to be displayed on the final diagram.</li>
                <li>Input data can be either cardinal directions or degrees.</li>
            </ol>
            <p>
                <strong>One subscription pays for your entire company:</strong>
                <br>
                Once you fill in your company’s basic details, each company has the ability to create unlimited user accounts.
            </p>
            <p>
                <strong>Support:</strong>
                <br>
                With the appropriate wind speed data, we guarantee that all of our customers will be able to produce a wind rose diagram with relative ease.
                We are always happy to answer any questions via the support page.
            </p>
            <ng-container *ngTemplateOutlet="director"></ng-container>
            <img src="assets/images/wre-example.png" alt="WRE example" class="mt-3">
        </div>
    </ng-container>

    <ng-container *ngIf="data.type === 'wrePlus'">
        <div class="panel-body">
            <p>
                <strong>What is different about WRE+?</strong>
                <br>
                For those who do not want to use meteorological data, WRE+ offers a greater degree of flexibility when it comes to designing and labelling your wind rose diagram.
            </p>
            <p>
                <strong>What to Expect:</strong>
                <br>
                With WRE+, you have complete control over the 2 key aspects of a wind rose diagram:
            </p>
            <ol>
                <li>Data type and Bins:
                    <ol type="a">
                        <li>Have flexibility over the data that makes up the bins of the spokes</li>
                        <li>Choose the number of bins you need</li>
                        <li>Choose the labels for each bin</li>
                    </ol>
                </li>
                <li>Detailed wind rose diagram customization:
                    <ol type="a">
                        <li>Choose between 4 and 16 spokes</li>
                        <li>Choose your own labels for each spoke</li>
                    </ol>
                </li>
            </ol>
            <p>
                <strong>Support for WRE+</strong>
                <br>
                With the appropriate data, all of our customers should be able to produce a rose diagram with relative ease.
                We are always happy to answer any questions via the contact us page.
            </p>
            <ng-container *ngTemplateOutlet="director"></ng-container>
            <img src="assets/images/wre-plus-example.jpg" alt="WRE+ example" class="mt-3">
        </div>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: flex-end;">
    <div class="modal-btns">
        <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="download()">Get It</button>
    </div>
</mat-dialog-actions>

<ng-template #director>
    <p>Best regards,</p>
    <p>Tom Evans<br>Director of Wind Rose Excel</p>
    <img src="assets/images/faces/director-portrait-circular.png" alt="Director Portrait" width="100">
    <img src="assets/images/wre-logo-with-text.png" alt="WRE Logo" width="200">
</ng-template>
