<h1 mat-dialog-title class="text-center"><span class="ml-30px">{{ data.isWrePlus ? 'WRE +' : 'Wind Rose Excel v1.7' }}</span> <i (click)="close()" class="i-Close-Window modal-close"></i></h1>
<mat-dialog-content>
  <ng-container *ngIf="status === statuses.ShowLinks">
    <div class="panel-body text-center">
      <span *ngIf="!data.isWrePlus">WRE 1.7 allow our customers to create high quality Wind Rose Diagrams for a fraction of the cost of rival software providers. You can
      use it offline with data taken from online WRE App.</span>
      <span *ngIf="data.isWrePlus">WRE+ offers a greater degree of flexibility when it comes to designing and labeling
        your Rose Diagram so that you can achieve exactly what you need to.</span>
    </div>
    <div class="row mt-3 justify-content-center">
      <div class="col-6 justify-content-center mb-2">
        <div class="col-12 text-center mt-2">Download for Windows</div>
        <div class="col-12 text-center mt-2"><i class="i-Windows-Microsoft load-icon"></i></div>
        <div class="col-12 text-center mt-2">
          <button class="btn btn-primary mr5 waves-effect" type="submit" [disabled]="!links.win_link" (click)="loadWindows()">Download</button>
        </div>
      </div>
<!--      <div class="col-6 justify-content-center mb-2">-->
<!--        <div class="col-12 text-center mt-2">Download for MacOS</div>-->
<!--        <div class="col-12 text-center mt-2"><i class="i-iOS-Apple load-icon"></i></div>-->
<!--        <div class="col-12 text-center mt-2">-->
<!--          <button class="btn btn-primary mr5 waves-effect" type="submit" [disabled]="!links.mac_link" (click)="loadMac()">Coming soon</button>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </ng-container>
  <ng-container *ngIf="status === statuses.ShowError">
    <div
        [ngClass]="{'text-center': !data.isWrePlus}"
        class="panel-body">
      <span
        *ngIf="data.isWrePlus"
      >
       In order to download the free WRE+ software, you will be asked to enter you card details in the following window. However, your card WILL NOT be billed for WRE+.
The get the WRE+ software, please go to ‘Plans & Billing’ via your avatar at the top right of the page. Or click the button below:
      </span>
      <span
          *ngIf="!data.isWrePlus"
      >
       You currently don’t have the ability to download this. Please go to ‘Plans & Billing’ via your avatar at the top right of the page. Or click the button below:
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="status === statuses.AddPassword">
    <div class="panel-body">
      <span>For using WRE 1.7 & WRE + , you need to setup a password for your account to login excel file. You can keep login to app without password.</span>
    </div>
    <form  [formGroup]="setPasswordForm">
      <div class="form-group" style="padding: 0 0 0 0 !important;">
        <mat-form-field class="w-100">
          <input matInput placeholder="Password" type="password" required formControlName="password1" name="password">
        </mat-form-field>
        <mat-form-field class="w-100">
          <input matInput placeholder="Repeat Password" type="password" required formControlName="password2" name="password">
        </mat-form-field>
      </div>
      <small class="text-danger" *ngIf="isErrorPass">
        Password doesn't match.
      </small>
      <div class="col-12 d-flex justify-content-end" style="padding: 0 0 0 0 !important;">
        <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="setPassword()">Set password</button>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="status === statuses.ShowLoader">
    <div class="d-flex justify-content-center">
      <div class="d-flex text-center spinner-bubble spinner-bubble-primary m-5"></div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: center;" *ngIf="status === statuses.ShowError">
  <div class="modal-btns">
    <button class="btn btn-primary mr5 waves-effect width-100px" type="submit" [routerLink]="['/dashboard/payments']" (click)="close()">Plans & Billing</button>
  </div>
</mat-dialog-actions>
