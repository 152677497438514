import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-info-v3',
  templateUrl: './info-v3.component.html',
  styleUrls: ['./info-v3.component.scss']
})
export class InfoV3Component implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InfoV3Component>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      body: string[],
      ul: string[],
      footer: string[],
      yesButtonText?: string,
      titleCenter?: boolean
    },
  ) { }

  ngOnInit() {
  }

  yes(): void {
    this.dialogRef.close(true);
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
