import {Component, OnInit} from '@angular/core';
import {CustomizationService} from '../../../services/customization.service';
import {DashboardStyles} from '../../../models/project.model';
import {DashboardDiv} from '../../../enum/project.enum';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import * as _ from 'lodash';

@Component({
  selector: 'app-dashboard-customization',
  templateUrl: './dashboard-customization.component.html',
  styleUrls: ['./dashboard-customization.component.scss']
})
export class DashboardCustomizationComponent implements OnInit {
  dashboardStyle: DashboardStyles[];
  dashboardDiv = DashboardDiv;

  constructor(
    private customizationService: CustomizationService,
  ) {
  }

  ngOnInit() {
    this.customizationService.currentDashboardStyle$.subscribe(dashboardStyle => {
      this.dashboardStyle = _.cloneDeep(dashboardStyle);
    });
  }

  showHideBlock(div: DashboardStyles, event: boolean): void {
    if (event) {
      this.dashboardStyle.find(d => d.name === div.name).style['display'] = 'block';
    } else {
      this.dashboardStyle.find(d => d.name === div.name).style['display'] = 'none';
    }
    this.customizationService.changeDashboardStyle(_.cloneDeep(this.dashboardStyle));
  }
}
