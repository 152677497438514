<h2 mat-dialog-title>
  Invite User to {{data.company.name}}
  <i (click)="close()" class="i-Close-Window modal-close"></i>
</h2>

<mat-dialog-content>
  <div class="panel-body">
    <form role="form" class="form-horizontal" [formGroup]="inviteForm">
      <div formArrayName="emails">
        <div class="form-group" *ngFor="let mail of emailsFormArray.controls; let i=index;">
          <div [formGroupName]="i">
            <div class="d-flex">
              <div class="input-group mr-3">
                <input type="email" class="form-control" placeholder="Email Address" formControlName="email">
                <span class="input-group-addon ion ion-email-unread"></span>
              </div>
              <div
                  *ngIf="data.isInviteToCompany"
                  class="input-group">
                <select
                    class="form-control"
                    formControlName="group">
                  <option [value]="null">Add to Group (optional)</option>
                  <option
                      *ngFor="let g of data.groups"
                      [value]="g.id"
                  >
                    {{g.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cursor-pointer mb-1" (click)="addEmailRow()">
        <i class="i-Add"></i>
        <span> Add More Users</span>
      </div>
      <div class="modal-btns">
        <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="inviteUser()" [disabled]="inviteForm.invalid">{{ buttonText }}</button>
      </div>
    </form>
  </div>
</mat-dialog-content>
