import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ToastrServiceService} from './shared/services/toastr-service.service';
import {AuthService} from './shared/services/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor() {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = localStorage.getItem('Authorization');
    if (authToken) {
      const copyReq = req.clone({
        headers: req.headers.set(
          'Authorization', authToken
        )
      });
      return next.handle(copyReq);
    }
    return next.handle(req);
  }
}

@Injectable()
export class ToastrInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrServiceService,
    private router: Router,
    private authService: AuthService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        tap(evt => {
          if (evt instanceof HttpResponse) {
            if (evt.body && evt.body.detail) {
              this.toastr.success(evt.body.detail);
            } else {
              this.toastr.success(evt.statusText);
            }
          }
        }),
        catchError((error: any) => {
          if (error.status === 401) {
            this.authService.clearUser();
          } else {
            const key = Object.keys(error.error)[0];
            this.toastr.error(error.error[key]);
          }
          return throwError(error);
        })
      );
  }
}


@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  timeOutId: number;

  constructor(private spinner: NgxSpinnerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.totalRequests++;
    this.spinner.show();
    clearTimeout(this.timeOutId);
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.timeOutId = setTimeout(() => this.spinner.hide(), 200);
        }
      })
    );
  }
}
