export const environment = {
  production: false,
  API_URL: 'https://dev-api.windroseexcel.com',
  PROJECT_DOMAIN: 'https://dev.windroseexcel.com',
  stripKey: 'pk_test_BqtRluVOe9bvPLE4ceje9D1U',
  GOOGLE_CLIENT_ID: '356064774559-5nugn8kg6pnaa1v0rikhvoi9rte7dmmu.apps.googleusercontent.com',
  FACEBOOK_APP_ID: '491422738407555',
  GoogleMapsKey: 'AIzaSyB5Rn2nG3w_53mVQA0Xm_DDCbvYYvrF7jo',
  SOCKET_URL: 'wss://dev-api.windroseexcel.com/notification/',
};
