import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BtnLoadingComponent} from './btn-loading/btn-loading.component';
import {FeatherIconComponent} from './feather-icon/feather-icon.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {SharedPipesModule} from '../pipes/shared-pipes.module';
import {SharedDirectivesModule} from '../directives/shared-directives.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {LayoutsModule} from './layouts/layouts.module';
import {RoseGraphComponent} from "./rose-graph/rose-graph.component";
import {NgxEchartsModule} from "ngx-echarts";
import {ChartBarComponent} from "./chart-bar/chart-bar.component";
import {AngularResizedEventModule} from "angular-resize-event";
  import * as echarts from 'echarts';

const components = [
  BtnLoadingComponent,
  FeatherIconComponent,
  RoseGraphComponent,
  ChartBarComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LayoutsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    PerfectScrollbarModule,
    NgbModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
    AngularResizedEventModule
  ],
  declarations: components,
  exports: components
})
export class SharedComponentsModule {
}
