import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {mergeMap, tap} from 'rxjs/operators';
import {BehaviorSubject, forkJoin, Observable, Subscription} from 'rxjs';

import {WebsocketService} from './websocket.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Notifications} from '../interfaces/notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _notifications: BehaviorSubject<Notifications[]> = new BehaviorSubject<Notifications[]>([]);
  private _unreadNotifications: BehaviorSubject<Notifications[]> = new BehaviorSubject<Notifications[]>([]);
  notifications$: Observable<Notifications[]> = this._notifications.asObservable();
  unreadNotifications$: Observable<Notifications[]> = this._unreadNotifications.asObservable();
  private _trigger: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  trigger: Observable<boolean> = this._trigger.asObservable();
  ws: Subscription;

  constructor(
    private router: Router,
    private http: HttpClient,
    private wsService: WebsocketService
  ) {

  }

  init() {
    this.webSocketConnect();
    forkJoin([this.getNotifications(), this.getUnreadNotifications()]).subscribe();
  }

  closeConnect(): void {
    if (this.ws) {
      this.ws.unsubscribe();
    }
  }

  webSocketConnect(): void {
    this.ws = this.wsService.connect(environment.SOCKET_URL).pipe(
      tap((response: MessageEvent): any => {
        const {notification} = JSON.parse(response.data);
        this.addUnreadNotifications(notification);
      })).subscribe();
  }

  changeNotifications(val: Notifications[]): void {
    this._notifications.next(val);
  }

  changeUnreadNotifications(val: Notifications[]): void {
    this._unreadNotifications.next(val);
  }

  triggerNotifications(): void {
    this._trigger.next(true);
  }

  addUnreadNotifications(val: Notifications): void {
    const currentValue = this._unreadNotifications.value;
    let updatedValue = [new Notifications(val)];
    if (currentValue && currentValue.length) {
       updatedValue = [...currentValue.reverse(), new Notifications(val)].reverse();
    }
    this.changeUnreadNotifications(updatedValue);
    this.triggerNotifications();
  }

  getNotifications(): Observable<any> {
    return this.http.get(environment.API_URL + '/auth/user/notifications/').pipe(
      tap((response: { notifications }) => {
        if (response && response.notifications && response.notifications.length) {
          let {notifications} = response;
          notifications = notifications.map(n => new Notifications(n));
          notifications = notifications.reverse();
          this.changeNotifications(notifications);
          return;
        }
        this.changeNotifications([]);
      })
    );
  }

  getUnreadNotifications(): Observable<any> {
    return this.http.get(environment.API_URL + '/auth/user/notifications/not_read/').pipe(
      tap((response) => {
        console.log(response);
        if (response && response.length) {
          let notifications = response;
          notifications = notifications.map(n => new Notifications(n));
          notifications = notifications.reverse();
          this.changeUnreadNotifications(notifications);
        } else {
          this.changeUnreadNotifications([]);
        }
      })
    )
  }

  countUnreadNotification(): number {
    return this._unreadNotifications.value.length;
  }

  makeAllNotificationAsRead(): Observable<any> {
    return  this.http.get(environment.API_URL + '/auth/user/notifications/read/').pipe(
      mergeMap(() => this.getUnreadNotifications())
    )
  }

  goToGroup(isCompany) {
    if (isCompany) {
      this.router.navigateByUrl('/dashboard/account/company');
    } else {
      this.router.navigateByUrl('/dashboard/group/users-list');
    }
  }
}
