import {UserRoles} from '../enum/user-roles.enum';
import {linkedGroups} from './company.model';
import {ProjectPerLevel} from '../enum/project.enum';
import {Subscription} from './payment.model';

export class User {
  pk: number;
  email: string;
  first_name: string;
  last_name: string;
  linked_groups: linkedGroups[] = [];
  role: UserRoles;
  level: ProjectPerLevel;
  avatar: string;
  stripe_customer_id: string;
  subscription: Subscription;
  is_muted: boolean;
  receive_emails: boolean;
  last4: string;

  isAmCompanyUser: boolean;

  constructor(init?: Partial<User>) {
    Object.assign(this, init);
    this.subscription = new Subscription(this.subscription);
    this.linked_groups = this.linked_groups.map( c => new linkedGroups(c));
  }

  public getFullName(): string {
    const firstName = this.first_name || '';
    const lastName = this.last_name || '';
    return `${firstName} ${lastName}`.trim();
  }

  public getAvatar(): string {
    return this.avatar ||  'assets/images/account.png';
  }

  public getFirstCompany() {
    if (this.linked_groups.length !== 0) {
      const firstCompany = this.linked_groups.find(company => company.is_company === true);
      return this.getOwnerCompany() || firstCompany;
    }
    return null;
  }

  public getFirstGroup() {
    if (this.linked_groups.length !== 0) {
      return this.linked_groups.find(company => company.is_company === false);
    }
    return null;
  }

  public getCompany(): linkedGroups[] {
    return this.linked_groups.filter(company => company.is_company === true);
  }

  public getGroups(): linkedGroups[] {
    return this.linked_groups.filter(company => company.is_company === false);
  }

  public getAdminOrOwnerCompanies(): linkedGroups[] {
    if (this.linked_groups.length !== 0) {
      return this.getCompany().filter(company => company.role !== UserRoles.USER);
    }
    return [];
  }

  public getOwnerCompany() {
    if (this.linked_groups.length !== 0) {
      const myCompany = this.linked_groups.find(company => company.owner === this.pk && company.is_company === true);
      if (myCompany) {return myCompany;}
    }
    return null;
  }

  public isAmCompanyAdmin(companyUser: User[]): boolean {
    const amIsAdmin = companyUser.find(user => user.role === UserRoles.ADMIN && user.pk === this.pk);
    return !!amIsAdmin;
  }

  public isAmCompanyOwner(companyUser: User[]): boolean {
    const amIsCompanyOwner = companyUser.find(user => user.role === UserRoles.OWNER && user.pk === this.pk);
    return !!amIsCompanyOwner;
  }

  public isHaveSub(): boolean {
    return this.subscription.is_payable;
  }

  public isHaveCancelledSub(): boolean {
    return this.subscription.status && this.subscription.status.toLowerCase().includes('cancel');
  }

  public isHaveAnySub(): boolean {
    return !this.subscription.is_payable && this.subscription.plan && !!Object.keys(this.subscription.plan).length;
  }

  public isMyCompanyHaveSub(): boolean {
    if (!this.getFirstCompany()) {
      return false;
    }
    return !!this.getFirstCompany().subscription.is_payable;
  }

  public isHaveCustomer(): boolean {
    return !!this.stripe_customer_id;
  }

  public isMyCompanyHaveCustomer(): boolean {
    if (!this.getOwnerCompany()) {
      return false;
    }
    return !!this.getOwnerCompany().stripe_customer_id;
  }

  public isAmNotCompanyUser(): boolean {
    return !this.isAmCompanyUser;
  }

  public setIsAmCompanyUser(users: User[]) {
    this.isAmCompanyUser = !(!this.getFirstCompany() || this.getOwnerCompany() || this.isAmCompanyAdmin(users));
  }

  get cardNumber(): string {
    return `**** **** **** ${this.last4}`;
  }
}

export interface Feedback {
  attachment: string;
  created_at: string;
  id: number;
  is_replied: boolean;
  message: string;
  from_page: string;
  video_file: string;
}

