import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CompanyService} from "../../../services/company.service";
import {AuthService} from "../../../services/auth.service";
import {linkedGroups} from "../../../models/company.model";
import {DeteleDiaologComponent} from "../detele-diaolog/detele-diaolog.component";

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.css']
})
export class EditGroupComponent implements OnInit {
  editGroupForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<EditGroupComponent>,
    private companyService: CompanyService,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: {group: linkedGroups}
  ) { }

  ngOnInit() {
    this.editGroupForm.controls.name.setValue(this.data.group.name);
  }

  saveGroup(): void {
    if (this.editGroupForm.invalid) {
      return;
    }
    this.companyService
      .editGroup(this.editGroupForm.value, this.data.group)
      .subscribe( group => this.dialogRef.close(group));
  }

  deleteGroup(): void {
    const dialogRef = this.dialog.open(DeteleDiaologComponent, {
      width: '400px',
      data: {
        removable: `the ${this.data.group.name} project`
      }
    });
    dialogRef.afterClosed().subscribe((isDelete: boolean) => {
      if (isDelete) {
        this.companyService
          .deleteGroup(this.data.group)
          .subscribe(resp => {
            this.authService.deleteCompanies(this.data.group);
            this.dialogRef.close(true);
          });
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}
