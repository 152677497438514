import {
    CustomEChartOption,
    CustomizationItem,
    InfoBoxPositionOption,
    ItemPositionOption
} from '../interfaces/customization';
import {
    ChartBarBreakpoints,
    CustomizationObjects,
    ElementPosition,
    OptionsToCustomize
} from '../enum/customization.enum';
import * as echarts from 'echarts/lib/echarts';
import EChartOption = echarts.EChartOption;
import EChartTitleOption = echarts.EChartTitleOption;
import SeriesLine = echarts.EChartOption.SeriesLine;

export const xAxisName = 'Wind Speed';
export const START_INFO_BOX: object = {
  id: 'wind_rose_info_box',
  type: 'text',
  right: '5%',
  bottom: '2%',
  z: 100,
  draggable: false,
  style: {
        fill: '#333',
        text: [
            'Mean speed: ',
            'Peak frequency:',
            'Peak direction: ',
            'Percent calm: ',
          'Calm defined as: '
        ].join('\n'),
    font: '14px arial'
  }
};

export const INIT_OPTS_CANVAS: object = {
  renderer: 'canvas'
};

export const INIT_OPTS_SVG_CHART_BAR: object = {
  renderer: 'svg',
};

export const INIT_OPTS_SVG_WIND_ROSE: object = {
  renderer: 'svg',
};

export const START_LEGEND_TITLE: object = {
  id: 'legend_title',
  type: 'text',
  right: '5%',
  top: '5%',
  z: 100,
  draggable: false,
  style: {
        fill: '#333',
        text: 'Wind speed',
        font: '14px arial'
    }
};

export const START_TIMESTAMP: object = {
    id: 'created_rose_timestamp',
    type: 'text',
    left: '5%',
    bottom: '2%',
    z: 100,
    draggable: false,
    style: {
        fill: '#ccc',
        text: '',
        font: '14px arial'
    }
};

export const START_LEGEND_SPEED_SCALE: EChartOption.Legend = {
    show: true,
    id: 'legend_speed_scale',
    type: 'scroll',
    orient: 'vertical',
    left: '83%',
    top: '10%',
    icon: 'circle',
    formatter: '{name}',
    data: []
};

export const START_ROSE_GRAPH_TITLE: EChartTitleOption = {
    show: true,
    text: '',
    subtext: '',
    left: 'center',
    textStyle: {}
};

export const ROSE_GRAPH_START_OPTIONS: CustomEChartOption = {
    backgroundColor: null,
    title: START_ROSE_GRAPH_TITLE,
    angleAxis: {
        show: true,
        type: 'category',
        data: [],
        z: 0,
        boundaryGap: false,
        splitLine: {
            show: true,
            lineStyle: {
                color: '#ccc',
                type: 'solid',
                opacity: 1
            }
        },
        axisLine: {
            show: false,
            lineStyle: {
                color: '#333',
                type: 'solid',
                opacity: 1
            }
        },
        axisTick: {
            show: true,
            lineStyle: {
                color: '#333',
                type: 'solid',
                opacity: 1
            }
        },
        axisLabel: {
            show: true,
            interval: 0,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'arial',
            fontSize: 12
        },
    },
    radiusAxis: {
        show: true,
        axisLine: {
            show: false,
            lineStyle: {
                color: '#ccc',
                type: 'solid',
                opacity: 1
            }
        },
        axisTick: {
            show: true,
            lineStyle: {
                color: '#333',
                type: 'solid',
                opacity: 1
            }
        },
        axisLabel: {
            show: true,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'arial',
            fontSize: 12
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: '#ccc',
                type: 'solid',
                opacity: 1
            }
        },
        nameTextStyle: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'arial',
            fontSize: 12
        },
        z: 2
    },
    graphic: {
        elements: []
    },
    polar: {
        center: ['50%', '50%'],
        radius: '70%'
    },
    series: [],
    textStyle: {
        color: '#333',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontFamily: 'arial',
        fontSize: 12
    },
    legend: START_LEGEND_SPEED_SCALE,
    customOptions: {
        timestampText: '',
        subtitleText: '',
        hiddenValues: [],
        customPalette: [],
        infoBoxPosition: {
            name: ElementPosition.Right,
            positionTitle: '17%',
            positionContent: '84%'
        },
        legendPosition: {
            left: '83%',
        },
        firstStart: true,
        hideInfoBox: true,
        hideTimestamp: true,
        hideLogo: true,
        hideWindSpeedScale: true
    }
};

export const CHART_BAR_START_OPTIONS: EChartOption = {
    color: ['#3398DB'],
    title: {
        show: false,
        text: 'Wind distribution',
        subtext: '',
        left: 'center',
        textStyle: {
            color: '#333',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'arial',
            fontSize: 18
        }
    },
    tooltip: {
        trigger: 'item',
    },
    grid: {
        containLabel: true,
        left: '5%',
        right: '14%',
        bottom: '3%',
    },
    xAxis: {
        show: true,
        name: xAxisName,
        type: 'value',
        splitNumber: 20,
        axisLabel: {
            show: true,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'arial',
            fontSize: 10
        },
        splitLine: {
            show: true
        },
        axisLine: {
            show: true
        },
        axisTick: {
            show: true,
            alignWithLabel: true
        },
        nameTextStyle: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'arial',
            fontSize: 10
        }
    },
    yAxis: {
        show: true,
        name: 'Wind Frequency (%)',
        type: 'value',
        axisLabel: {
            show: true,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'arial',
            fontSize: 10,
            formatter: function (value, index) {
                value = value * 100;
                return `${value}`;
            }
        },
        splitLine: {
            show: true
        },
        axisLine: {
            show: true
        },
        axisTick: {
            show: true,
            alignWithLabel: true
        }
    },
    graphic: [],
    legend: {
        show: true,
        orient: 'vertical',
        top: '15%',
        right: '15%',
    },
    series: [
        // {
        //     name: 'Wind speed',
        //     id: 'windSpeed',
        //     type: 'bar',
        //     barGap: '0%',
        //     data: [],
        //     itemStyle: {
        //         color: '#3398DB'
        //     }
        // },
        {
            type: 'line',
            name: 'Wind speed',
            id: 'windSpeed',
            symbol: 'none',
            areaStyle: {},
            step: 'start',
        },
        {
          name: 'Weibull',
          id: 'weibullLine',
          type: 'line',
          smooth: true,
          symbol: 'none',
          data: [],
          itemStyle: {
            color: '#000000'
          }
        },
        {
            type: 'graph',
            id: 'meanSpeed',
        },
        {
            type: 'graph',
            id: 'scale'
        },
        {
            type: 'graph',
            id: 'shape'
        },
    ],
    textStyle: {
        color: '#333',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontFamily: 'arial',
        fontSize: 10
    },
};

export const TREND_LINE_SERIES: SeriesLine = {
    name: 'Wind speed',
    id: 'trendLine',
    type: 'line',
    smooth: true,
    data: [],
    itemStyle: {
      color: '#000000'
    }
  };

export const CUSTOMIZATION_ITEMS: Record<CustomizationObjects, CustomizationItem> = {
    [CustomizationObjects.ChartBart]: <CustomizationItem>{
        name: CustomizationObjects.ChartBart,
        options: [
            {
                name: OptionsToCustomize.Title
            },
            {
                name: OptionsToCustomize.ColumnColorControls
            },
            {
                name: OptionsToCustomize.XYAxis
            },
            {
                name: OptionsToCustomize.Text
            },
            {
                name: OptionsToCustomize.Lines
            },
            {
                name: OptionsToCustomize.TrendLine
            }
        ]
    },
    [CustomizationObjects.RoseGraph]: <CustomizationItem>{
        name: CustomizationObjects.RoseGraph,
        options: [
            {
                name: OptionsToCustomize.General
            },
            {
                name: OptionsToCustomize.Title
            },
            {
                name: OptionsToCustomize.Logo
            },
            {
                name: OptionsToCustomize.Legend
            },
            {
                name: OptionsToCustomize.RadiusAxis
            },
            {
                name: OptionsToCustomize.AngleAxis
            },
            {
                name: OptionsToCustomize.SpeedScale
            },
            {
                name: OptionsToCustomize.SpeedUnits
            },
            {
                name: OptionsToCustomize.InfoBox
            },
            {
                name: OptionsToCustomize.TimeStamp
            },
            {
                name: OptionsToCustomize.Text
            },
            {
                name: OptionsToCustomize.Background
            }
        ]
    },
    [CustomizationObjects.Dashboard]: <CustomizationItem>{
        name: CustomizationObjects.Dashboard,
        options: [
            {
                name: OptionsToCustomize.Title
            },
        ]
    },
    [CustomizationObjects.TableAndPreview]: <CustomizationItem>{
      name: CustomizationObjects.TableAndPreview,
      options: [
        {
          name: OptionsToCustomize.Title
        },
      ]
    },
};

export const RESPONSIVE_CHART_BAR_OPTIONS: Record<ChartBarBreakpoints, EChartOption> = {
    [ChartBarBreakpoints.Small]: {
        grid: {
            containLabel: true,
            left: '5%',
            right: '25%',
        },
        legend: {
            right: '27%',
        },
        series: [
            {
                type: 'bar',
                // barWidth: 10
            }
        ]
    },
    [ChartBarBreakpoints.Medium]: {
        grid: {
            containLabel: true,
            left: '4%',
            right: '25%',
        },
        legend: {
            right: '27%',
        },
        series: [
            {
                type: 'bar',
                // barWidth: 20
            }
        ]
    },
    [ChartBarBreakpoints.Large]: {
        grid: {
            containLabel: true,
            left: '4%',
            right: '13%'
        },
        legend: {
            right: '15%',
        },
        series: [
            {
                type: 'bar',
                // barWidth: 40
            }
        ]
    }
};

export const GRAPHIC = {
  elements: []
};
export const TEXT_FONT_LIST: string[] = ['Arial', 'Roboto', 'Monospace', 'Helvetica'];

export const TEXT_SIZE_LIST: number[] = [10, 12, 14, 16, 18];

export const Positions: ItemPositionOption[] = [
    {
        name: ElementPosition.Left,
        position: {
            left: '1%',
            top: '1%'
        }
    },
    {
        name: ElementPosition.Right,
        position: {
            right: '1%',
            top: '1%'
        }
    },
    {
        name: ElementPosition.Left,
        position: {
            left: '1%',
            bottom: '1%'
        }
    },
    {
        name: ElementPosition.Right,
        position: {
            right: '1%',
            bottom: '1%'
        }
    },
];

export const LEGEND_POSITIONS: ItemPositionOption[] = [
    {
        name: ElementPosition.Left,
        position: {
            left: '1%'
        }
    },
    {
        name: ElementPosition.Right,
        position: {
            left: '83%',
        }
    }
];

export const INFO_BOX_POSITIONS: InfoBoxPositionOption[] = [
    {
        name: ElementPosition.Left,
        positionTitle: '83%',
        positionContent: '18%',
    },
    {
        name: ElementPosition.Right,
        positionTitle: '17%',
        positionContent: '84%'
    }
];

export const SIZES = {
    MAX_LOGO_SIZE: 25,
    MIN_LOGO_SIZE: 5,
    MAX_POLAR_SIZE: 75,
    MIN_POLAR_SIZE: 50,
    FONT_MIN_SIZE: 8,
    FONT_MAX_SIZE: 16
};
