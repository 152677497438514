import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {withCache} from '@ngneat/cashew';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private http: HttpClient,
  ) {
  }

  public normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  public toFloat(value): number {
    value = parseFloat(value);
    value = this.toFixed(value, 2);
    return value;
  }

  public toFixed(num, fixed) {
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  }

  public isFloat(n) {
    if (isNaN(n)) {
      return false;
    }
    return n % 1 !== 0;
  }

  public summVerticalDataInArray(value: number[][]): number[] {
    return value.reduce(function (array1, array2) {
      return array2.map(function (value, index) {
        return value + (array1[index] || 0);
      }, 0);
    }, []);
  }

  public toPercentString(value): string {
    value = this.toPercent(value);
    return `${value}%`;
  }

  public toPercent(value): number {
    value = value * 100;
    value = this.toFloat(value);
    return value;
  }

  public getFileNameFromURL(URL: string): string {
    const pieces = URL.split('/');
    return pieces[pieces.length - 1];
  }

  public percentToBootstrapClass(percent: number): string {
    switch (true) {
      case percent < 8.33:
        return 'col-lg-1';
      case percent < 16.67:
        return 'col-lg-2';
      case percent < 25:
        return 'col-lg-3';
      case percent < 33.33:
        return 'col-lg-4';
      case percent < 42:
        return 'col-lg-5';
      case percent < 50:
        return 'col-lg-6';
      case percent < 58:
        return 'col-lg-7';
      case percent < 66:
        return 'col-lg-8';
      case percent < 74:
        return 'col-lg-9';
      case percent < 82:
        return 'col-lg-10';
      case percent < 90:
        return 'col-lg-11';
      default:
        return 'col-lg-12';
    }
  }

  isBase64(str): boolean {
    return str.indexOf('data:image') + 1;
  }

  getTimeNow(): Observable<Date> {
    return this.http.get(`${environment.API_URL}/current_time/`, withCache({
      cache$: false
    }))
      .pipe(
        map((data: { current_time }) => {
          return new Date(data.current_time);
        })
      );
  }

  isChange(array1, array2): boolean {
    return !(array1.length === array2.length && array1.every((value, index) => {
      return value === array2[index];
    }));
  }
}
