<h2 mat-dialog-title>{{data.title}} <i (click)="close()" class="i-Close-Window modal-close"></i></h2>
<mat-dialog-content>
  <p>{{data.body}}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="d-flex justify-content-end btns">
    <button
        class="btn btn-primary mr5 waves-effect"
        type="submit"
        (click)="navigate()"
        *ngIf="data.isShowBtn"
    >
      {{data.btn}}
    </button>
    <button *ngIf="!data.queryParams?.trial" class="btn btn-primary" (click)="close()">Close</button>
    <button *ngIf="data.queryParams?.trial" class="btn btn-primary" (click)="navigateToTrial()">Choose Trial Plan</button>
  </div>
</mat-dialog-actions>
