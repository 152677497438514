import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ProjectData} from '../../../models/project.model';
import {CustomizationService} from '../../../services/customization.service';
import {Observable} from 'rxjs';
import {assign, cloneDeep, merge} from 'lodash/fp';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {CustomEChartOption, CustomizationItem} from '../../../interfaces/customization';
import {
  CHART_BAR_START_OPTIONS,
  CUSTOMIZATION_ITEMS,
  TEXT_FONT_LIST,
  TEXT_SIZE_LIST,
  TREND_LINE_SERIES
} from '../../../constants/charts';
import {CustomizationObjects, OptionsToCustomize} from '../../../enum/customization.enum';
import * as echarts from 'echarts/lib/echarts';
import EChartOption = echarts.EChartOption;
import EChartTitleOption = echarts.EChartTitleOption;

@Component({
  selector: 'app-chart-bar-customization',
  templateUrl: './chart-bar-customization.component.html',
  styleUrls: ['./chart-bar-customization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartBarCustomizationComponent {
  @Input() projectData: ProjectData = {} as ProjectData;
  @Input() selectedOption: Observable<OptionsToCustomize>;

  selectedCustomizationOption: Observable<OptionsToCustomize>;
  customizedChartBarOptions: Observable<EChartOption>;
  optionsToCustomize: typeof OptionsToCustomize = OptionsToCustomize;
  optionsList: CustomizationItem = CUSTOMIZATION_ITEMS[CustomizationObjects.ChartBart];
  readonly textFontList: string[] = TEXT_FONT_LIST;
  readonly textSizeList: number[] = TEXT_SIZE_LIST;
  options;

  menuSections = {
    chartTitle: {
      hidden: true
    },
    chartArea: {
      hidden: true
    },
    axisText: {
      hidden: true
    },
    axisX: {
      hidden: true
    },
    axisY: {
      hidden: true
    }
  };

  showXAxis = true;
  showYAxis = true;

  constructor(private customizationService: CustomizationService) {
    this.customizedChartBarOptions = this.customizationService.customizedChartBarOptions$;
    this.customizedChartBarOptions.subscribe( result => this.options = result);
    this.selectedCustomizationOption = this.customizationService.selectedCustomizationOption$;
  }

  private changeOption(option): void {
    this.customizationService.setCustomizedChartBarOptions(assign(this.customizationService.customizedChartBarOptions, option));
  }

  changeColumnColor(options: EChartOption, event): void {
    const newColor = event.target.value;
    const barSeries = options.series.find(item => item.id === 'windSpeed');
    const newBarSeries = merge(barSeries, { itemStyle: { color: newColor } });
    const newSeries = options.series.filter(item => item.id !== 'windSpeed');
    newSeries.push(newBarSeries);
    this.changeOption({
      color: [newColor],
      series: newSeries
    });
  }

  changeXAxisDisplay(xAxisSettings: EChartOption.XAxis, event: boolean): void {
    this.changeOption({ xAxis: assign(xAxisSettings, {
        axisLabel: assign(xAxisSettings.axisLabel, { show: event }),
        axisLine: assign(xAxisSettings.axisLine, { show: event }),
        axisTick: assign(xAxisSettings.axisTick, { show: event })
    })});
  }

  changeXAxisTitleDisplay(xAxisSettings: EChartOption.XAxis, event: boolean): void {
    this.changeOption({ xAxis: assign(xAxisSettings, { name: event ? 'Wind speed' : '' }) });
  }

  changeXAxisLabelsDisplay(xAxisSettings: EChartOption.XAxis, event: boolean): void {
    this.changeOption({ xAxis: assign(xAxisSettings, { axisLabel: { show: event } }) });
  }

  changeVerticalLines(xAxisSettings: EChartOption.XAxis, event: MatCheckboxChange): void {
    this.changeOption({ xAxis: assign(xAxisSettings, { splitLine: { show: event.checked } }) });
  }

  changeYAxisDisplay(yAxisSettings: EChartOption.YAxis, event: boolean): void {
    this.changeOption({ yAxis: assign(yAxisSettings, {
        axisLabel: assign(yAxisSettings.axisLabel, {
          show: event,
          formatter: function (value, index) {
            value = value * 100;
            return `${value}`;
          }
        }),
        axisLine: assign(yAxisSettings.axisLine, { show: event }),
        axisTick: assign(yAxisSettings.axisTick, { show: event })
      })});
  }

  changeYAxisTitleDisplay(yAxisSettings: EChartOption.YAxis, event: boolean): void {
    this.changeOption({ yAxis: assign(yAxisSettings, { name: event ? 'Wind frequency (%)' : '' }) });
  }

  changeYAxisLabelsDisplay(yAxisSettings: EChartOption.YAxis, event: boolean): void {
    this.changeOption({
      yAxis: assign(yAxisSettings, {
        axisLabel: {
          show: event,
          formatter: function (value, index) {
            value = value * 100;
            return `${value}`;
          }
        }
      }),
    });
  }

  changeHorizontalLines(yAxisSettings: EChartOption.YAxis, event: MatCheckboxChange): void {
    this.changeOption({ yAxis: assign(yAxisSettings, { splitLine: { show: event.checked } }) });
  }

  changeTextFont(options: EChartOption, event): void {
    const newTextFont = { fontFamily: event.target.value };
    this.changeOption({
      xAxis: assign(options.xAxis, {
        nameTextStyle: assign('nameTextStyle' in options.xAxis ? options.xAxis.nameTextStyle : {}, newTextFont),
        axisLabel: assign('axisLabel' in options.xAxis ? options.xAxis.axisLabel : {}, newTextFont),
      }),
      yAxis: assign(options.yAxis, {
        nameTextStyle: assign('nameTextStyle' in options.yAxis ? options.yAxis.nameTextStyle : {}, newTextFont),
        axisLabel: assign('axisLabel' in options.yAxis ? options.yAxis.axisLabel : {}, newTextFont),
      }),
      title: assign(options.title, {
        textStyle: assign('textStyle' in options.title ? options.title.textStyle : {}, newTextFont)
      })
    });
  }

  changeTextSize(options: EChartOption, event): void {
    const newTextSize = { fontSize: event.target.value };
    this.changeOption({
      xAxis: assign(options.xAxis, {
        nameTextStyle: assign('nameTextStyle' in options.xAxis ? options.xAxis.nameTextStyle : {}, newTextSize),
        axisLabel: assign('axisLabel' in options.xAxis ? options.xAxis.axisLabel : {}, newTextSize),
      }),
      yAxis: assign(options.yAxis, {
        nameTextStyle: assign('nameTextStyle' in options.yAxis ? options.yAxis.nameTextStyle : {}, newTextSize),
        axisLabel: assign('axisLabel' in options.yAxis ? options.yAxis.axisLabel : {}, newTextSize),
      }),
    });
  }

  changeTitleDisplay(options: EChartOption, event: boolean): void {
    const titleFont = { fontFamily: ('nameTextStyle' in options.xAxis ? options.xAxis.nameTextStyle.fontFamily : 'arial') || 'arial' };
    this.changeOption({ title: assign(options.title, {
        show: event,
        text: 'Wind distribution',
        left: 'center',
        textStyle: assign(options.title && 'textStyle' in options.title ? options.title.textStyle : {}, titleFont)
      }) });
  }

  changeTitleBold(options: CustomEChartOption, event: MatCheckboxChange) {
    if (event.checked) {
      this.changeOption({title: merge(options.title, {textStyle: {fontWeight: 'bolder'}})});
    } else {
      this.changeOption({ title: merge(options.title, {textStyle: {fontWeight: 'normal'}}) });
    }
  }

  changeTitleName(titleSettings: EChartTitleOption, event): void {
    this.changeOption({ title: assign(titleSettings,  { text: event.target.value }) });
  }

  changeTitleSize(titleSettings: EChartTitleOption, event): void {
    const newTitleSize = { fontSize: event.target.value };
    this.changeOption({
      title: assign(titleSettings, {
        textStyle: assign('textStyle' in titleSettings ? titleSettings.textStyle : {}, newTitleSize),
      })
    });
  }

  changeWeibullLine(seriesSettings: EChartOption.Series[], event: MatCheckboxChange): void {
    if (event.checked) {
      const weibullLine: EChartOption.SeriesLine = cloneDeep(CHART_BAR_START_OPTIONS.series.find(item => item.id === 'weibullLine') as EChartOption.SeriesLine);
      const newSeries: EChartOption.Series[] = [...seriesSettings];
      newSeries.push(weibullLine);
      this.changeOption({ series: newSeries });
    } else {
      this.changeOption({ series: seriesSettings.filter(item => item.id !== 'weibullLine') });
    }
  }

  changeTrendLineColor(seriesSettings: EChartOption.Series[], event): void {
    const lineSeries = seriesSettings.find(item => item.id === 'weibullLine');
    const newLineSeries = merge(lineSeries, { itemStyle: { color: event.target.value } });
    const newSeries = seriesSettings.filter(item => item.id !== 'weibullLine');
    newSeries.push(newLineSeries);
    this.changeOption({ series: newSeries });
  }

  changeTrendLineSmoothness(seriesSettings: EChartOption.Series[], event: MatCheckboxChange): void {
    const newSeries: EChartOption.Series[] = seriesSettings
        .map(series => series.id === 'weibullLine' ? {...series, smooth: event.checked } : series);
    this.changeOption({ series: newSeries });
  }

  setSelectedItemToCustomize(item: OptionsToCustomize): void {
    this.customizationService.setSelectedCustomizationOption(item);
  }

  isSmoothWeibullLine(lineSeries: EChartOption.SeriesLine): boolean {
    return this.isWeibullLine(lineSeries) && !!lineSeries.smooth;
  }

  toggleMenuSections(section: 'chartTitle'|'chartArea'|'axisText'|'axisX'|'axisY') {
    this.menuSections[section].hidden = !this.menuSections[section].hidden;
  }

  getWeibullLineSeriesFromOptions(options: EChartOption): EChartOption.SeriesLine {
    return options.series.find(item => item.id === 'weibullLine') as EChartOption.SeriesLine;
  }

  isWeibullLine(lineSeries: EChartOption.SeriesLine): boolean {
    return !!lineSeries;
  }

  get weibullLineSeries() {
    return this.getWeibullLineSeriesFromOptions(this.options);
  }
}
