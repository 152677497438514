<div #eCharts (resized)="resizeChart($event)" class="h-100 w-100">
  <div
      *ngIf="isTrialProject"
      class="watermark card"
  >
    <h4>WRE Web App Trial Version</h4>
  </div>
    <div echarts
         [options]="chartBarData"
         [initOpts]="initOpts"
         (chartInit)="onChartInit($event)"
         class="h-100">
    </div>
</div>

