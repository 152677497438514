<div
    #eCharts
    (resized)="resizeChart()"
    class="h-100 w-100 background"
    [ngStyle]="{
    background: this.project.getWRCover() ? 'url(' + this.project.getWRCover() + ') center / cover no-repeat' : 'none'
    }"
>
  <div
      *ngIf="isTrialProject"
      class="watermark card watermark-windrose"
  >
    <h4>WRE Web App Trial Version</h4>
  </div>
    <div echarts
         [options]="roseGraphData"
         [initOpts]="initOpt"
         (chartInit)="onChartInit($event)"
         class="h-100">
    </div>
</div>
