<div class="d-flex" *ngIf="data.showYesIcon">
  <div class="d-flex justify-content-center w-100">
    <i class="i-Yes cursor-pointer" *ngIf="data.showYesIcon" (click)="close()"></i>
  </div>
  <div class="d-flex justify-content-end" [ngClass]="{'w-100': !data.showYesIcon}">
    <i (click)="close()" class="i-Close-Window modal-close" [ngClass]="{'position-absolute': data.showYesIcon}"></i>
  </div>
</div>
<div class="d-flex w-100" *ngIf="!data.showYesIcon" [ngClass]="{'justify-content-between': data.title, 'justify-content-end': !data.title}">
  <h2 mat-dialog-title *ngIf="data.title" class="mb-0">{{data.title}}</h2>
  <i (click)="close()" class="i-Close-Window modal-close"></i>
</div>
<mat-dialog-content>
  <div class="mt-2">
    <div class="main-text w-100" [ngClass]="{'text-center': data.showYesIcon}" *ngFor="let item of data.text">
      {{ item }}
    </div>
  </div>
  <div class="mt-3" *ngIf="data.subtext?.length">
    <div class="sub-text w-100" [ngClass]="{'text-center': data.showYesIcon}" *ngFor="let item of data.subtext">
      {{ item }}
    </div>
  </div>
</mat-dialog-content>
