<div class="card">
  <div class="card-header">
    <ul class="nav nav-justified">
      <li class="nav-item" *ngFor="let step of steps"
          [ngClass]="{'active': step.isActive, 'enabled': !step.isDisabled && steps.length > 1, 'disabled': step.isDisabled, 'completed': isCompleted}">
        <a (click)="goToStep(step)">{{step.title}}</a>
      </li>
    </ul>
  </div>
  <div class="card-block">
    <ng-content></ng-content>
  </div>
  <div class="card-footer" [hidden]="isCompleted" *ngIf="!isCustom">
    <button type="button" class="btn btn-rounded btn-primary float-left" (click)="previous()"
            [hidden]="!hasPrevStep || !activeStep.showPrev">Previous
    </button>
    <button type="button" class="btn btn-rounded btn-primary float-right" (click)="next()"
            [disabled]="!activeStep.isValid" [hidden]="!hasNextStep || !activeStep.showNext">Next
    </button>
    <button type="button" class="btn btn-rounded btn-primary float-right" (click)="complete()"
            [disabled]="!activeStep.isValid" [hidden]="hasNextStep">Done
    </button>
  </div>
</div>
