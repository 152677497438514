<h2 mat-dialog-title>You have been offered a {{data.discount.percents}}% discount for our WRE Web App<i (click)="close()" class="i-Close-Window modal-close"></i></h2>
<mat-dialog-content>
    <div class="panel-body">
        <p *ngIf="data.discount.duration === duration.DURATION_REPEATING">
            Your discount will last for the next {{data.discount.duration_months}} months.
        </p>
        <p *ngIf="data.discount.duration === duration.DURATION_ONCE">
            Your discount will be valid for 1 month.
        </p>
        <p *ngIf="data.discount.duration === duration.DURATION_FOREVER">
            Your discount will be valid forever.
        </p>
        <p>
            <strong>You now have two options:</strong>
        </p>
        <ol>
            <li *ngIf="!data.isHaveSub">
              Click "Use Now". This will apply a discount to any subscription you purchase.
            </li>
            <li *ngIf="data.isHaveSub">Click "Use Now" to automatically upgrade to a discounted subscription at the end of this subscription.
            </li>
            <li>Click "Use later" to activate the discount later.
            </li>
        </ol>
    </div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: flex-end;">
    <div class="modal-btns">
        <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="confirm()">Use Now</button>
        <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="close()">Use later</button>
    </div>
</mat-dialog-actions>
