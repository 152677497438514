import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import * as echarts from 'echarts/lib/echarts';
import EChartOption = echarts.EChartOption;
import ECharts = echarts.ECharts;
import {ProjectData, Projects} from '../../models/project.model';
import {assign, cloneDeep, isEmpty, merge} from 'lodash/fp';
import {INIT_OPTS_SVG_WIND_ROSE, ROSE_GRAPH_START_OPTIONS} from '../../constants/charts';
import {CustomEChartOption} from '../../interfaces/customization';

@Component({
  selector: 'app-rose-graph',
  templateUrl: './rose-graph.component.html',
  styleUrls: ['./rose-graph.component.scss'],
})
export class RoseGraphComponent implements OnChanges, OnInit {
  @Input() projectData: ProjectData;
  initOpt = INIT_OPTS_SVG_WIND_ROSE;
  @Input() roseOptions: CustomEChartOption;
  @Input() paletteFromStepTwo: string[] = [];
  @Input() hiddenValuesFromStepTwo: string[] = [];
  @Input() project: Projects;
  @Input() isTrialProject: boolean = false;
  private chartInstance: ECharts;
  roseGraphData: EChartOption;
  @ViewChild('eCharts', {static: true}) eCharts: ElementRef<HTMLDivElement>;

  constructor(
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('project' in changes) {
      this.project = new Projects(this.project);
    }
    this.resizeChart();
    this.prepareRoseGraphOptions();
  }

  ngOnInit(): void {
    this.project = new Projects(this.project);
  }

  private prepareRoseGraphOptions(): void {
    const options = isEmpty(this.roseOptions) ? cloneDeep(ROSE_GRAPH_START_OPTIONS) : this.roseOptions;
    options.radiusAxis['axisLabel'].formatter = (value, index) => `${Math.round(value * 100)}%`;
    let palette = options.customOptions.customPalette.length
      ? options.customOptions.customPalette
      : this.projectData ? this.projectData.palette : [];
    palette = this.paletteFromStepTwo.length ? this.paletteFromStepTwo : palette;
    options.customOptions.hiddenValues = this.hiddenValuesFromStepTwo.length
      ? this.hiddenValuesFromStepTwo
      : options.customOptions.hiddenValues;
    if (options.legend.show && !this.paletteFromStepTwo.length) {
      options.legend = merge(options.legend, {data: this.roseLabelsReverse, height: 500});
    }
    if (this.projectData && this.projectData.frequency_table && this.projectData.frequency_table.length) {
      this.roseGraphData = assign(options, {
        angleAxis: assign(options.angleAxis, {data: this.projectData.labels_wind_direction_verbose}),
        series: this.projectData.labels_wind_speed.map((windSpeed, index) => {
          if (!options.customOptions.hiddenValues.includes(windSpeed)) {
            return {
              type: 'bar',
              data: this.projectData.frequency_table.map(windFrequencies => windFrequencies[index]),
              coordinateSystem: 'polar',
              name: windSpeed,
              stack: 'a',
              itemStyle: {
                normal: {
                  color: palette[index]
                }
              }
            };
          }
        }).filter(element => element !== undefined)
      });
    }
  }

  onChartInit(chart: ECharts): void {
    this.chartInstance = chart;
  }

  resizeChart(): void {
    if (this.chartInstance) {
      this.chartInstance.resize();
    }
  }

  getDataURL(): string {
    return this.chartInstance.getDataURL({
      pixelRatio: 2,
    });
  }

  get roseLabelsReverse(): string[] {
    return this.projectData && this.projectData.labels_wind_speed ? [...this.projectData.labels_wind_speed].reverse() : [];
  }
}
