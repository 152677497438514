import { Injectable } from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ToastrServiceService {
  override: {} = {positionClass: 'toast-bottom-right' };
  constructor(
    private toastr: ToastrService,
  ) { }

  error(message: string): void {
    if(!environment.production) {
      this.toastr.error(message, '',this.override)
    }
  }

  success(message: string): void {
    if(!environment.production) {
      this.toastr.success(message, '',this.override)
    }
  }
}
