<h2 mat-dialog-title>Edit permission <i (click)="close()" class="i-Close-Window modal-close"></i></h2>

<mat-dialog-content>
  <div class="panel-body">
    <form role="form" class="form-horizontal">

      <div class="form-group">
        <label class="col-md-4 control-label">Permission:</label>
        <div class="col-md-12">
          <select class="form-control" [(ngModel)]="currentLevel" [ngModelOptions]="{standalone: true}">
            <option [value]="projectUserLevel.CanView">Can view</option>
            <option [value]="projectUserLevel.CanEdit">Can edit</option>
            <option [value]="projectUserLevel.CanEditAccessLevel">Can edit access level</option>
            <option [value]="projectUserLevel.CanDelete">Can delete</option>
          </select>
        </div>
      </div>

      <div class="modal-btns">
        <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="save()">Save</button>
      </div>
    </form>
  </div>
</mat-dialog-content>
