import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {User} from '../models/user.model';
import {UserRoles} from '../enum/user-roles.enum';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {AuthService, COMPANY_USER_CACHE} from './auth.service';
import {linkedGroups} from '../models/company.model';
import {environment} from '../../../environments/environment';
import {StationsResponse} from '../models/project.model';
import {isArray} from 'util';
import {NotificationService} from './notification.service';
import {withCache} from '@ngneat/cashew';

const GROUP_USER_CACHE = 'GROUP_USER_CACHE';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  API = environment.API_URL;
  companyUsers: User[] = [];

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {
  }

  editCompany(data, company: linkedGroups): Observable<linkedGroups> {
    if (Object.keys(data).length !== 5) {
      return this.http.patch<linkedGroups>(this.API + `/companies/${company.id}/`, data)
        .pipe(tap(companyRes => this.authService.updateUserCompanies(companyRes)));
    }
    return this.http.put<linkedGroups>(this.API + `/companies/${company.id}/`, data)
      .pipe(tap(companyRes => this.authService.updateUserCompanies(companyRes)));
  }

  editGroup(data, company: linkedGroups): Observable<linkedGroups> {
    return this.http.patch<linkedGroups>(this.API + `/groups/${company.id}/`, data)
      .pipe(tap(companyRes => this.authService.updateUserCompanies(companyRes)));
  }

  addCompany(data): Observable<linkedGroups> {
    return this.http.post<linkedGroups>(this.API + `/companies/`, data)
      .pipe(
        tap(company => this.authService.addUserCompanies(company)),
        catchError((errorResponse: HttpErrorResponse) => {
            if (errorResponse.error.name && isArray(errorResponse.error.name)) {
              const [message]: [string] = errorResponse.error.name;
              if (message.includes('exists')) {
                return throwError('Company with this name already exists.');
              }
            }
            return throwError('Something went wrong.');
          })
      );
  }

  addGroup(name: string): Observable<linkedGroups> {
    return this.http.post<linkedGroups>(this.API + `/groups/`, { name })
      .pipe(tap(company => this.authService.addUserCompanies(company)));
  }

  deleteCompany(): Observable<any> {
    return this.http.delete(this.API + `/companies/${this.authService.user.getOwnerCompany().id}/`).pipe(
      mergeMap(() => this.notificationService.getNotifications())
    );
  }

  deleteGroup(groups: linkedGroups): Observable<any> {
    return this.http.delete(this.API + `/groups/${groups.id}/`);
  }

  inviteUserCompany(emails, company: linkedGroups): Observable<linkedGroups> {
    return this.http.post<linkedGroups>(this.API + `/companies/${company.id}/users/`, {emails});
  }

  inviteUserGroup(emails, company: linkedGroups, isCompanyUser: boolean = false): Observable<linkedGroups> {
    return this.http.post<linkedGroups>(this.API + `/groups/${company.id}/users/`, {users: emails, is_company_invite: isCompanyUser});
  }

  getCompanyUserByID(id): Observable<User[]> {
    let companyId = id;
    if (typeof id === 'object') {
      companyId = id.pk;
    }
    return this.http.get<User[]>(this.API + `/companies/${companyId}/users/`, withCache({
      cache$: true,
      key$: COMPANY_USER_CACHE
    }))
      .pipe(tap(companyUsers => this.companyUsers = companyUsers));
  }

  getGroupUser(company: linkedGroups): Observable<User[]> {
    return this.http.get<User[]>(this.API + `/groups/${company.id}/users/`, withCache({
      cache$: true,
      key$: GROUP_USER_CACHE
    }));
  }

  putRoleToCompanyUser(company: linkedGroups, user: User, role: UserRoles): Observable<User> {
    const isAdmin = role === UserRoles.ADMIN;
    return this.http.put<User>(this.API + `/companies/${company.id}/users/${user.pk}/`, {is_admin: isAdmin});
  }

  deleteCompanyUser(company: linkedGroups, user: User): Observable<any> {
    return this.http.delete<User>(this.API + `/companies/${company.id}/users/${user.pk}/`);
  }

  deleteCompanyUsers(company: linkedGroups, users: any[]): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        users_id: users,
      }
    };
    return this.http.delete<void>(this.API + `/companies/${company.id}/users/`, options);
  }

  deleteGroupUser(group: linkedGroups, user: User): Observable<any> {
    return this.http.delete<User>(this.API + `/groups/${group.id}/users/${user.pk}/`);
  }

  public setLogo(group: linkedGroups, logo: Blob): Observable<linkedGroups> {
    const headerDict = {
      'Content-Type': 'application/json',
      'Content-Disposition': `attachment; filename=${new Date().getTime()}-logo.jpg`,
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict)
    };
    return this.http.put<linkedGroups>(this.API + `/groups/${group.id}/logo/`, logo, requestOptions);
  }

  public deleteLogo(group: linkedGroups): Observable<any> {
    return this.http.delete<linkedGroups>(this.API + `/groups/${group.id}/logo/`);
  }

  public getCompany(company: linkedGroups): Observable<linkedGroups> {
    return this.http.get<linkedGroups>(this.API + `/companies/${company.id}/`)
      .pipe(map(companyRes => new linkedGroups(companyRes)));
  }

  public leaveFromGroup(group: linkedGroups, user: User): Observable<any> {
    return this.http.delete(this.API + `/groups/${group.id}/users/${user.pk}/`);
  }

  getStations(pk: number): Observable<StationsResponse> {
    return this.http.get<StationsResponse>(this.API + `/companies/${pk}/stations/`);
  }
}
