import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {cloneDeep} from 'lodash';
import {CHART_BAR_START_OPTIONS, CUSTOMIZATION_ITEMS, ROSE_GRAPH_START_OPTIONS} from '../constants/charts';
import {CustomEChartOption, CustomizationItem, stepTwoCustomization} from '../interfaces/customization';
import {CustomizationObjects, OptionsToCustomize} from '../enum/customization.enum';
import {DashboardStyles, Projects} from '../models/project.model';
import * as echarts from 'echarts/lib/echarts';
import EChartOption = echarts.EChartOption;
import * as _ from 'lodash';
import {DashboardDiv, DEFAULT_DASHBOARD_STYLE} from '../enum/project.enum';

@Injectable({
  providedIn: 'root'
})
export class CustomizationService {
  // step Three
  private _isCustomizationSideBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _customizationItemsToShow: BehaviorSubject<CustomizationItem> = new BehaviorSubject<CustomizationItem>(null);
  private _selectedCustomizationOption: BehaviorSubject<OptionsToCustomize> = new BehaviorSubject<OptionsToCustomize>(null);
  private _customizedChartBarOptions: BehaviorSubject<EChartOption> = new BehaviorSubject<EChartOption>(cloneDeep(CHART_BAR_START_OPTIONS));
  private _customizedRoseGraphOptions: BehaviorSubject<CustomEChartOption> = new BehaviorSubject<CustomEChartOption>(cloneDeep(ROSE_GRAPH_START_OPTIONS));
  private _currentProject: BehaviorSubject<Projects> = new BehaviorSubject<Projects>(null);
  private _isCustomizationMap: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isCustomizationSideBar$: Observable<boolean> = this._isCustomizationSideBar.asObservable();
  isCustomizationMap$: Observable<boolean> = this._isCustomizationMap.asObservable();
  customizationItemsToShow$: Observable<CustomizationItem> = this._customizationItemsToShow.asObservable();
  selectedCustomizationOption$: Observable<OptionsToCustomize> = this._selectedCustomizationOption.asObservable();
  customizedChartBarOptions$: Observable<EChartOption> = this._customizedChartBarOptions.asObservable();
  customizedRoseGraphOptions$: Observable<CustomEChartOption> = this._customizedRoseGraphOptions.asObservable();
  currentProject$: Observable<Projects> = this._currentProject.asObservable();
  // dashboard
  private _currentDashboardStyle: BehaviorSubject<DashboardStyles[]> = new BehaviorSubject<DashboardStyles[]>(null);
  currentDashboardStyle$: Observable<DashboardStyles[]> = this._currentDashboardStyle.asObservable();
  //step two
  private _stepTwoCustomizationData: BehaviorSubject<stepTwoCustomization> = new BehaviorSubject<stepTwoCustomization>(null);
  stepTwoCustomizationData$: Observable<stepTwoCustomization> = this._stepTwoCustomizationData.asObservable();
  private _triggerChangeBinsData: BehaviorSubject<any> = new BehaviorSubject<boolean>(null);
  triggerChangeBinsData: Observable<boolean> = this._triggerChangeBinsData.asObservable();
  private _triggerChangeWeibullData: BehaviorSubject<any> = new BehaviorSubject<boolean>(null);
  triggerChangeWeibullData: Observable<boolean> = this._triggerChangeWeibullData.asObservable();
  constructor() { }

  get customizedChartBarOptions(): EChartOption {
    return this._customizedChartBarOptions.getValue();
  }

  get customizedRoseGraphOptions(): CustomEChartOption {
    return this._customizedRoseGraphOptions.getValue();
  }

  get stepTwoCustomizationData(): stepTwoCustomization {
    return this._stepTwoCustomizationData.getValue();
  }

  showCustomizationSideBar(): void {
    this._isCustomizationSideBar.next(true);
  }

  hideCustomizationSideBar(): void {
    this._isCustomizationSideBar.next(false);
  }

  setCustomizationItems(item: CustomizationItem): void {
    this._customizationItemsToShow.next(item);
  }

  setProject(project: Projects): void {
    this._currentProject.next(project);
  }

  setSelectedCustomizationOption(selectedOption: OptionsToCustomize): void {
    this._selectedCustomizationOption.next(selectedOption);
  }

  setCustomizedChartBarOptions(options: EChartOption): void {
    this._customizedChartBarOptions.next(options);
  }

  setCustomizedRoseGraphOptions(options: CustomEChartOption): void {
    this._customizedRoseGraphOptions.next(options);
  }

  clearCustomizationSettings(): void {
    this._customizationItemsToShow.next(null);
    this._selectedCustomizationOption.next(null);
    this._customizedChartBarOptions.next(cloneDeep(CHART_BAR_START_OPTIONS));
    this._customizedRoseGraphOptions.next(cloneDeep(ROSE_GRAPH_START_OPTIONS));
  }

  showCustomizationMap(): void {
    this._isCustomizationMap.next(true);
  }

  hideCustomizationMap(): void {
    this._isCustomizationMap.next(false);
  }

  changeDashboardStyle(val: DashboardStyles[]): void {
    this._currentDashboardStyle.next(val);
  }

  changeStepTwoCustomizationData(val: stepTwoCustomization): void {
    return this._stepTwoCustomizationData.next(val);
  }

  triggerChangeBins(val): void {
    return this._triggerChangeBinsData.next(val);
  }

  triggerChangeWeibull(val): void {
    return this._triggerChangeWeibullData.next(val);
  }
}
