<h2 mat-dialog-title>Share Project <i (click)="close()" class="i-Close-Window modal-close"></i></h2>

<mat-dialog-content>

<div class="row" *ngIf="!data.isTrial">
  <div class="col-sm-12">
    <ng-container *ngIf="project.isPersonalProject() && user.getCompany().length">
      <div class="panel panel-default mb20 panel-hovered activities">
        <div class="panel-heading">Copy to Company:</div>
        <div class="panel-body">
          <form class="form-inline" role="form" [formGroup]="copyCompanyForm">
            <div class="form-group">
              <div class="input-group">
                <select class="custom-select mr-2" formControlName="group">
                  <option *ngFor="let company of user.getCompany()" [value]="company.id">{{company.name}}</option>
                </select>
              </div>
              <div class="input-group">
                <select class="custom-select mr-2" formControlName="access">
                  <option [value]="projectAccess.PRIVATE">Private</option>
                  <option [value]="projectAccess.AvailableToView">Company users can view</option>
                  <option [value]="projectAccess.AvailableToEdit">Company users can edit</option>
                </select>
              </div>
              <button type="submit" class="btn btn-primary waves-effect" (click)="copyProject(copyCompanyForm)">Copy
              </button>
            </div>
          </form>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="project.isPersonalProject() && user.getGroups().length">
      <div class="panel panel-default mb20 panel-hovered activities">
        <div class="panel-heading">Copy to Group:</div>
        <div class="panel-body">
          <form class="form-inline" role="form" [formGroup]="copyGroupForm">
            <div class="form-group">
              <div class="input-group">
                <select class="custom-select mr-2" formControlName="group">
                  <option *ngFor="let company of user.getGroups()" [value]="company.id">{{company.name}}</option>
                </select>
              </div>
              <div class="input-group">
                <select class="custom-select mr-2" formControlName="access">
                  <option [value]="projectAccess.AvailableToView">Company users can view</option>
                  <option [value]="projectAccess.AvailableToEdit">Company users can edit</option>
                </select>
              </div>
              <button type="submit" class="btn btn-primary waves-effect" (click)="copyProject(copyGroupForm)">Copy
              </button>
            </div>
          </form>
        </div>
      </div>
    </ng-container>
    <div class="panel panel-default mb20 panel-hovered activities">
      <div class="panel-heading">Share Link:</div>
      <div class="panel-body d-flex align-items-center mb-1">
        <div class="share-link-container mr-2">
          <a target="_blank" href="{{project.getShareLink()}}">{{project.getShareLink()}}</a>
        </div>
        <button
            type="button"
            class="btn btn-primary waves-effect"
            ngxClipboard [cbContent]="project.getShareLink()"
            ngbPopover="Copied to clipboard!" autoClose="outside" (shown)="closePopoverWithDelay(10)"
            #shareLinkButton="ngbPopover">
          Copy Link
        </button>
      </div>
    </div>
    <div class="panel panel-default mb20 panel-hovered activities">
      <div class="panel-heading">Embedded code:</div>
      <div class="panel-body d-flex align-items-center mb-1">
        <div class="share-link-container mr-2">
          <span>{{project.getEmbeddedIFrame()}}</span>
        </div>
        <button
                type="button"
                class="btn btn-primary waves-effect"
                ngxClipboard [cbContent]="project.getEmbeddedIFrame()"
                ngbPopover="Copied to clipboard!" autoClose="outside" (shown)="closePopoverWithDelay(10)"
                #iFrameButton="ngbPopover">
          Copy To Your Website/Blog
        </button>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3 mb-3 d-flex justify-content-center text-center" *ngIf="data.isTrial">
  <strong>This feature isn't available in the trial version. <br>
    Please upgrade to a full subscription to unlock all the features within Web App.</strong>
</div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: flex-end;" *ngIf="data.isTrial">
  <div class="modal-btns">
    <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="close()">Close</button>
    <button class="btn btn-primary mr5 waves-effect" type="submit" [routerLink]="['/dashboard/payments']" (click)="close()">Upgrade to full plan</button>
  </div>
</mat-dialog-actions>
