import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../../services/auth.service';
import {ExcelLinksStatus} from '../../../enum/common';
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-wre-download',
  templateUrl: './wre-download.component.html',
  styleUrls: ['./wre-download.component.scss']
})
export class WreDownloadComponent implements OnInit {
  statuses = ExcelLinksStatus;
  status: ExcelLinksStatus = ExcelLinksStatus.ShowLoader;
  links: { win_link: string, mac_link: string };
  setPasswordForm = new FormGroup({
    password1: new FormControl('', [Validators.required]),
    password2: new FormControl('', [Validators.required]),
  });
  isErrorPass: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<WreDownloadComponent>,
    private auth: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: { isWrePlus: boolean }
  ) { }

  ngOnInit() {
    this.checkExcel();
  }

  checkExcel() {
    this.spinner.hide();
    this.auth.checkExcel(this.data.isWrePlus).subscribe( result => {
      this.status = ExcelLinksStatus.ShowLinks;
      this.links = result;
    }, error => {
      if (error.status === 404) {
        this.status = ExcelLinksStatus.AddPassword;
      } else {
        this.status = ExcelLinksStatus.ShowError;
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  loadWindows() {
    window.open(this.links.win_link);
  }

  loadMac() {
    window.open(this.links.mac_link);
  }

  setPassword() {
    this.isErrorPass = false;
    if (this.setPasswordForm.invalid) {
      return;
    }
    this.status = ExcelLinksStatus.ShowLoader;
    this.auth.setPassword(this.setPasswordForm.value).subscribe(response => {
      this.checkExcel();
    }, error => {
      this.isErrorPass = true;
      this.status = ExcelLinksStatus.AddPassword;
    });
  }

  goToPayment() {
    this.dialogRef.close();
    this.router.navigateByUrl('dashboard/payments')
  }
}
