<div class="card-title w-100 d-flex justify-content-between pb-2 pl-1">


  Dashboard Elements
</div>
<ng-container
    *ngFor="let div of dashboardStyle; let i = index;"
>
  <ng-container
      [ngSwitch]="div.name"
  >
<!--    <ng-container *ngSwitchCase="dashboardDiv.WindSpeedChart">-->
<!--      <div class="form-group row justify-content-between">-->
<!--        <div class="card-title align-self-center p-0 element">Bar Chart</div>-->
<!--        <app-menu-toggle-->
<!--            menuToggleType="switch"-->
<!--            [checked]="div.style['display'] !== 'none'"-->
<!--            (changeState)="showHideBlock(div,  $event)"-->
<!--            class="text-16 p-0 mr-4"-->
<!--        >-->
<!--        </app-menu-toggle>-->
<!--      </div>-->
<!--    </ng-container>-->
    <ng-container *ngSwitchCase="dashboardDiv.RoseGraph">
      <div class="form-group row justify-content-between">
        <div class="card-title align-self-center p-0 element">Wind Rose Chart</div>
        <app-menu-toggle
            menuToggleType="switch"
            [checked]="div.style['display'] !== 'none'"
            (changeState)="showHideBlock(div,  $event)"
            class="text-16 p-0 mr-4"
        >
        </app-menu-toggle>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="dashboardDiv.Table">
      <div class="form-group row justify-content-between">
        <div class="card-title align-self-center p-0 element">Table</div>
        <app-menu-toggle
            menuToggleType="switch"
            [checked]="div.style['display'] !== 'none'"
            (changeState)="showHideBlock(div,  $event)"
            class="text-16 p-0 mr-4"
        >
        </app-menu-toggle>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="dashboardDiv.SatelliteImage">
      <div class="form-group row justify-content-between">
        <div class="card-title align-self-center p-0 element">Satellite Image</div>
        <app-menu-toggle
          menuToggleType="switch"
          [checked]="div.style['display'] !== 'none'"
          (changeState)="showHideBlock(div,  $event)"
          class="text-16 p-0 mr-4"
        >
        </app-menu-toggle>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="dashboardDiv.WindSpeedChart">
      <div class="form-group row justify-content-between">
        <div class="card-title align-self-center p-0 element">Bar Chart</div>
        <app-menu-toggle
            menuToggleType="switch"
            [checked]="div.style['display'] !== 'none'"
            (changeState)="showHideBlock(div,  $event)"
            class="text-16 p-0 mr-4"
        >
        </app-menu-toggle>
      </div>
    </ng-container>
  </ng-container>

</ng-container>
