<h2 mat-dialog-title>Delete <i (click)="close()" class="i-Close-Window modal-close"></i></h2>
<mat-dialog-content>
  <div class="panel-body">
    <div class="form-group">
      <label class="col-md-12 control-label">Are you sure want to delete the {{data.removable}}?</label>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: flex-end;">
  <div class="modal-btns">
    <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="close()">Cancel</button>
    <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="makeDelete()">Yes</button>
  </div>
</mat-dialog-actions>
