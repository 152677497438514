<h2 mat-dialog-title>{{data.title}} <i
    *ngIf="data.isCloseBtn"
    (click)="close()" class="i-Close-Window modal-close"></i></h2>
<mat-dialog-content>
  <p>{{data.body}}</p>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: flex-end;">
  <div class="modal-btns">
    <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="yes()">{{ data.yesButtonText || 'Yes' }}</button>
    <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="close()">{{ data.noButtonText || 'Cancel' }}</button>
  </div>
</mat-dialog-actions>
