<form-wizard [isCustom]="true">
  <wizard-step [title]="'Data Bins'">
    <div>
      <div class="row pl">
        <div
            [ngClass]="{'p-0': currentConfiguration === binConfiguration.BINS_CUSTOM}"
            class="col-12 m-0 d-flex justify-content-center">
          <button
              (click)="updateGraph()"
              [disabled]="isDisableBtn()"
              [ngClass]="{'btn-primary': !isDisableBtn(), 'btn-outline-primary': isDisableBtn()}"
              class="btn m-1 mx-auto mb-3">
            Update graphs
          </button>
        </div>
        <div
            [ngClass]="{'p-0': currentConfiguration === binConfiguration.BINS_CUSTOM}"
            class="col-12 m-0 d-flex mb-2"
        >
          <label for="title-size-input" class="col-form-label col-6 align-self-center p-0">Configuration</label>
          <select class="form-control menu-list-default col-6 p-0 h-auto"
                  id="title-size-input"
                  [(ngModel)]="currentConfiguration"
                  (ngModelChange)="onConfigurationChange(stepTwoCustomizationData)"
          >
            <option selected [value]="binConfiguration.BINS_STANDARD">{{binConfiguration.BINS_STANDARD}}</option>
            <option [value]="binConfiguration.BINS_BEAUFORT">{{binConfiguration.BINS_BEAUFORT}}</option>
            <option [value]="binConfiguration.BINS_CUSTOM">{{binConfiguration.BINS_CUSTOM}}</option>
          </select>
        </div>
        <div
            class="calm w-100"
        >
          Calm
        </div>
        <hr class="dashed dashed__table_and_preview w-100">
        <ng-container
            *ngFor="let option of parsedRoseLabels; let i = index"
        >
          <div
              [ngClass]="{'p-0': currentConfiguration === binConfiguration.BINS_CUSTOM}"
              class="col-12 m-0 mb-2 d-flex justify-content-between"
          >
            <i
                *ngIf="currentConfiguration === binConfiguration.BINS_CUSTOM && i !== 0"
                [ngClass]="{'i-Close cursor-pointer text-25': i !== 0}"
                (click)="deleteLabel(i)"
            >
            </i>
            <div
                *ngIf="currentConfiguration === binConfiguration.BINS_CUSTOM && i === 0"
                class="width-28px"
            >
            </div>
            <input
                [disabled]="isDisableLabel(0)"
                class="form-control p-0 w-auto h-auto"
                type="text"
                maxlength="4"
                size="3"
                [(ngModel)]="parsedRoseLabels[i][0]"
                (change)="changeLabel(i, 0)"
                (keypress)="numberOnly($event, parsedRoseLabels[i][0])"
            > -
            <input
                [disabled]="isDisableLabel(1)"
                class="form-control p-0 w-auto h-auto"
                [ngClass]="{'input_invalid': checkInputIsInvalid(i)}"
                type="text"
                maxlength="4"
                size="3"
                [(ngModel)]="parsedRoseLabels[i][1]"
                (change)="changeLabel(i, 1)"
                (keypress)="numberOnly($event, parsedRoseLabels[i][1])"
            >
            <div
                class="midpoints text-center"
            >
              ({{getMidPoints(i)}})
            </div>
            <input
                class="series-color-input"
                type="color"
                *ngIf="i !== 0"
                [value]="palette[i - 1]"
                (change)="setPalette(i, $event)"
            >
            <div
                *ngIf="i === 0"
                class="text-center"
                style="width: 35px"
            ><strong>N/A</strong>
            </div>
          </div>
          <hr  *ngIf="i === 0" class="dashed w-100 dashed__table_and_preview">
        </ng-container>

        <div
            *ngIf="currentConfiguration === binConfiguration.BINS_CUSTOM"
            [ngClass]="{'p-0': currentConfiguration === binConfiguration.BINS_CUSTOM}"
            class="col-12 m-0 d-flex justify-content-center"
        >
          <i
              (click)="addNewLabel()"
              class="cursor-pointer text-32 i-Add"
          >
          </i>
        </div>
        <div
            [ngClass]="{'p-0': currentConfiguration === binConfiguration.BINS_CUSTOM}"
            class="col-12 m-0 d-flex justify-content-center">
          <button
              (click)="returnMySetting()"
              class="btn btn-outline-primary m-1 mx-auto mb-3">
            Use my colour scheme
          </button>
        </div>
        <div
            [ngClass]="{'p-0': currentConfiguration === binConfiguration.BINS_CUSTOM}"
            class="col-12 m-0 d-flex justify-content-center">
          <button
              (click)="setBeaufortScaleColour()"
              class="btn btn-outline-primary m-1 mx-auto mb-3">
            Use beaufort colour scheme
          </button>
        </div>
      </div>
    </div>
  </wizard-step>
  <wizard-step [title]="'Weibull'">
    <div class="form-group row justify-content-between align-items-center">
      <h4 class="align-self-center p-0 mb-0">Weibull</h4>
    </div>
    <div>
      <form role="form" class="form-horizontal" [formGroup]="weibullForm">
        <div class="form-group row">
          <label for="shape-factor-input" class="col-form-label col-6 align-self-center p-0">Shape Factor</label>
          <input type="text"
                 id="shape-factor-input"
                 formControlName="shape"
                 class="form-control p-0 w-auto h-auto"
                 maxlength="4"
                 [attr.disabled]="weibullForm.controls.default.value ? '' : null"
                 size="3"
                 OnlyNumber="true"
          >
        </div>
        <div class="form-group row">
          <label for="scale-factor-input" class="col-form-label col-6 align-self-center p-0">Scale Factor</label>
          <input type="text"
                 id="scale-factor-input"
                 formControlName="scale"
                 class="form-control p-0 w-auto h-auto"
                 maxlength="4"
                 [attr.disabled]="weibullForm.controls.default.value ? '' : null"
                 size="3"
                 OnlyNumber="true"
          >
        </div>
        <div class="form-group row">
          <mat-checkbox
              class="text-16 col-6 p-0" color="primary"
              formControlName="default"
              [labelPosition]="'before'"
          >
            Default
          </mat-checkbox>
        </div>
        <div class="d-flex justify-content-center">
          <button
              class="btn btn-outline-primary m-1 mx-auto mb-3"
              type="submit"
              (click)="changeWeibull()"
          >Change</button>
        </div>
      </form>
    </div>
  </wizard-step>
</form-wizard>
