<div class="main-header">
    <div class="logo cursor-pointer" routerLink="/dashboard/homepage">
        <img src="assets/images/wre-logo.svg" alt="logo">
    </div>
    <div class="menu-toggle" (click)="toggleSidebar()" matTooltip="{{(sidebarState.sidenavOpen ? 'Hide' : 'Show') + ' left bar'}}">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <span
        class="d-flex flex-column m-1 cursor-pointer justify-content-center nav-bar-icons"
        (click)="openProjectCreationModal()">
        <i class="i-Add-File mb-1"></i>
        Create Project
    </span>
    <span
        class="d-flex flex-column m-1 cursor-pointer justify-content-center ml-2 nav-bar-icons"
        (click)="openSendReportModal()">
        <i class="i-Speach-Bubble-Asking mb-1"></i>
        Report
    </span>
    <div style="margin: auto"></div>
    <div class="header-part-right">
      <a *ngIf="authService.user.isAmNotCompanyUser() && isTrialProject" [routerLink]="['/dashboard/payments']"><strong>Upgrade to full plan</strong></a>
        <div ngbDropdown #drop1="ngbDropdown" (click)="goToNotificationPage()" [(open)]="isOpen" [placement]="'bottom-right'">
            <div class="badge-top-container" (mouseenter)="showNotification()" (mouseleave) ="closeNotification()">
                <span class="badge badge-primary">{{(notifications$ | async).length}}</span>
                <i ngbDropdownToggle class="i-Bell text-muted header-icon" role="button"></i>
            </div>
            <div ngbDropdownMenu  class="notification-dropdown rtl-ps-none left-0 {{notifiClass}}" perfectScrollbar *ngIf="drop1.isOpen()">
                <div class="dropdown-item d-flex" *ngFor="let item of notifications$ | async">
                    <div class="notification-icon" [routerLink]="['/dashboard/notifications']">
                        <i class="i-Add-File text-primary mr-1"></i>
                    </div>
                    <div class="notification-details flex-grow-1">
                        <p class="m-0 d-flex align-items-center">
                            <ng-container *ngIf="item.message === ''">
                                <span>
                                    New Project <a [routerLink]="[item.project.navigateToProject()]"><u>{{item.project.title}}</u></a>  was created in <a (click)="goToGroup(true)"><u>{{item.group.name}}</u></a>
                                </span>
                            </ng-container>
                            <ng-container *ngIf="item.message !== ''">
                                <span>{{item.message}}</span>
                            </ng-container>
                            <span class="flex-grow-1"></span>
                            <span class="text-small text-muted ml-auto pl-3">{{item.created_at | relativeTime}}</span>
                        </p>
                        <ng-container *ngIf="item.message === ''">
                            <p class="text-small text-muted m-0">{{item.project.title | excerpt:30}}</p>
                        </ng-container>
                        <ng-container *ngIf="item.message !== ''">
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div ngbDropdown [placement]="'bottom-right'" class="user align-self-end">
            <div class="d-flex justify-content-end align-items-center cursor-pointer" id="userDropdown"
                 ngbDropdownToggle>
                <span class="cursor-pointer mr-2 username">Hi, <u>{{ authService.user.getFullName() }}</u></span>
                <img [src]="authService.user.avatar || avatarSrc" alt="">
            </div>

            <div ngbDropdownMenu aria-labelledby="userDropdown" class="header-account-dropdown">
                <div class="dropdown-header">
                    <ng-container
                        *ngIf="authService.user.getFirstCompany()"
                    >
                        <i class="i-Building mr-1"></i> {{ authService.user.getFirstCompany().name }}
                    </ng-container>
                    <p class="mb-0 username">Hi, <u>{{ authService.user.getFullName() }}</u></p>
                </div>
                <button class="dropdown-item cursor-pointer" [routerLink]="['/dashboard/account']">Account</button>
                <button class="dropdown-item cursor-pointer" [routerLink]="['/dashboard/group/users-list']">Groups</button>
                <button
                        *ngIf="authService.user.isAmNotCompanyUser()"
                        class="dropdown-item cursor-pointer"
                        [routerLink]="['/dashboard/payments']"
                >
                    Plans & Billing
                </button>
                <a
                    target="_blank"
                    [href]="getPrivacyPolicyLink()"
                    class="dropdown-item cursor-pointer"
                >
                  Privacy Policy
                </a>
                <button class="dropdown-item cursor-pointer" (click)="signout()">Sign out</button>
            </div>
        </div>

    </div>

</div>
