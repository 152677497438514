import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {User} from '../../../models/user.model';
import {ProjectAccess} from '../../../enum/project.enum';
import {ProjectsService} from '../../../services/projects.service';
import {Projects} from '../../../models/project.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModalWindowService} from '../../../services/modal-window.service';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-share-project',
  templateUrl: './share-project.component.html',
  styleUrls: ['./share-project.component.css']
})
export class ShareProjectComponent implements OnInit {
  @ViewChild('shareLinkButton') shareLinkButton: NgbPopover;
  @ViewChild('iFrameButton') iFrameButton: NgbPopover;
  user: User;
  project: Projects;
  projectAccess = ProjectAccess;
  copyCompanyForm = new FormGroup({
    group: new FormControl('', [Validators.required]),
    access: new FormControl('', [Validators.required]),
  });
  copyGroupForm = new FormGroup({
    group: new FormControl('', [Validators.required]),
    access: new FormControl('', [Validators.required]),
  });

  private timerId: number;

  constructor(
    private projectsService: ProjectsService,
    private dialogRef: MatDialogRef<ShareProjectComponent>,
    private modalService: ModalWindowService,
    @Inject(MAT_DIALOG_DATA) public data: { user: User, project: Projects, isTrial: boolean }
  ) {
  }

  ngOnInit() {
    this.project = this.data.project;
    this.user = this.data.user;
    this.copyCompanyForm.controls.group.setValue(this.user.getFirstCompany() ? this.user.getFirstCompany().id : '');
    this.copyCompanyForm.controls.access.setValue(ProjectAccess.PRIVATE);
    this.copyGroupForm.controls.group.setValue(this.user.getFirstGroup() ? this.user.getFirstGroup().id : '');
    this.copyGroupForm.controls.access.setValue(ProjectAccess.AvailableToView);
  }

  close() {
    this.dialogRef.close();
  }

  public copyProject(form: FormGroup): void {
    if (form.invalid) {
      return;
    }
    const idSelectedGroup = parseInt(form.controls.group.value, 10);
    const group = this.user.linked_groups.find(c => c.id === idSelectedGroup);
    if (group.is_company) {
      this.projectsService
        .copyProject(this.project, form.value)
        .subscribe(() => this.openSuccessWindow(group.name, true));
    } else {
      this.projectsService
        .copyProjectToGroup(this.project, form.value)
        .subscribe(() => this.openSuccessWindow(group.name, false));
    }
  }

  private openSuccessWindow(name: string, isCompany: boolean) {
    this.modalService.openInformationWindow(
      [`A copy of ${this.project.title} has been created in ${isCompany ? 'company' : 'group'} ${name}`],
      null,
      true
    );
  }

  /**
   * Close popover after delay
   * @param delay: number - in seconds
   */
  closePopoverWithDelay(delay: number) {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.timerId = setTimeout(() => {
      this.shareLinkButton.close();
      this.iFrameButton.close();
    }, delay * 1000);
  }
}
