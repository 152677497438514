import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminLayoutSidebarLargeComponent} from './admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import {HeaderSidebarLargeComponent} from './admin-layout-sidebar-large/header-sidebar-large/header-sidebar-large.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {SharedPipesModule} from '../../pipes/shared-pipes.module';
import {SidebarLargeComponent} from './admin-layout-sidebar-large/sidebar-large/sidebar-large.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {FooterComponent} from '../footer/footer.component';
import {CustomizerComponent} from '../customizer/customizer.component';
import {SharedDirectivesModule} from '../../directives/shared-directives.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ChartBarCustomizationComponent} from './chart-bar-customization/chart-bar-customization.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {RoseGraphCustomizationComponent} from './rose-graph-customization/rose-graph-customization.component';
import {MatInputModule} from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import {FormWizardModule} from '../form-wizard/form-wizard.module';
import {DashboardCustomizationComponent} from './dashboard-customization/dashboard-customization.component';
import {MenuToggleComponent} from '../menu-toggle/menu-toggle.component';
import {UiSwitchModule} from 'ngx-ui-switch';
import {TableAndPreviewCustomizationComponent} from "./table-and-preview-customization/table-and-preview-customization.component";

const components = [
  HeaderSidebarLargeComponent,
  SidebarLargeComponent,
  FooterComponent,
  CustomizerComponent,
  AdminLayoutSidebarLargeComponent,
  ChartBarCustomizationComponent,
  RoseGraphCustomizationComponent,
  DashboardCustomizationComponent,
  TableAndPreviewCustomizationComponent,
  MenuToggleComponent
];

@NgModule({
  imports: [
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    PerfectScrollbarModule,
    CommonModule,
    MatCheckboxModule,
    MatInputModule,
    MatRadioModule,
    FormWizardModule,
    MatSlideToggleModule,
    MatTooltipModule,
    UiSwitchModule
  ],
  declarations: components,
  exports: components
})
export class LayoutsModule {
}
