import { WindSpeed } from '../enum/wind-speed.enum';
import {
  BinConfiguration,
  DashboardDiv,
  DayNightRegularityEnum,
  ProjectPerLevel,
  Regularity,
  Spokes
} from '../enum/project.enum';
import { User } from './user.model';
import { CustomEChartOption } from '../interfaces/customization';
import * as echarts from 'echarts/lib/echarts';
import EChartOption = echarts.EChartOption;
import { environment } from '../../../environments/environment';

export class Projects {
  id: string;
  access: number;
  cover: string;
  group: { pk: number, owner: number };
  creator: User;
  title: string;
  wind_speed_units: WindSpeed;
  use_user_observation: boolean;
  user_observation_name: ObservationName;
  permission_level: ProjectPerLevel;
  station: Station;
  created_at: string;
  number_of_spokes: number;
  is_saved: boolean;
  start_date: string;
  end_date: string;
  last_updated_by: User;
  updated_at: string;
  regularity: Regularity;
  windrose_graph_settings?: CustomEChartOption;
  columnar_graph_settings?: EChartOption;
  wind_rose_background: string;
  shared_token: string;
  custom_map_cover: string;
  map_cover_as_wr_background: boolean;
  wind_rose_logo: string;
  dashboard_styles: DashboardStyles[];
  day_night: DayNightRegularityEnum;
  wind_speed_bins_type: BinConfiguration;
  wind_speed_bins: number[];

  // Weibull
  shape: number;
  scale: number;
  custom: boolean;

  // weibull_shape: number;
  // weibull_scale: number;
  // weibull_image: string;
  // weibull_svg: string;

  // fields generate on frontend
  wind_speed_labels_custom: string[] = [];
  blob: Blob;
  clearBackground: boolean;

  constructor(init?: Partial<Projects>) {
    Object.assign(this, init);
    this.creator = new User(this.creator);
    this.last_updated_by = new User(this.last_updated_by);
    if (typeof this.station === 'object') {
      this.station = new Station(this.station);
    }
    if (this.wind_speed_bins && this.wind_speed_bins.length) {
      this.wind_speed_labels_custom = [];
      this.windSpeedBinsToLabel();
    }
  }

  public getDataRange(): string {
    const startTime = new Date(this.start_date);
    const endTime = new Date(this.end_date);
    return `${startTime.getDate()}.${startTime.getMonth() + 1}.${startTime.getFullYear()}` +
      ` - ${endTime.getDate()}.${endTime.getMonth() + 1}.${endTime.getFullYear()}`;
  }

  public getHTMLWindSpeed(windSpeed): string {
    const windSpeedArray = windSpeed.split('-');
    if (windSpeedArray.length > 1) {
      return `${windSpeedArray[0]} <br> - <br> ${windSpeedArray[1]}`;
    }
    return windSpeed;
  }


  public getCover(): string {
    return this.cover || this.custom_map_cover || null;
  }

  public getDescription(): string {
    if (!this.use_user_observation) {
      return this.station instanceof Station ? `${this.station.country}/${this.station.name}/${this.getDataRange()}` :
        `${this.getDataRange()}`;
    }
    return `${this.user_observation_name.name}/${this.getDataRange()}`;
  }

  public createdAt(): string {
    const createdAt = new Date(this.created_at);
    return `${createdAt.getDate()}.${createdAt.getMonth() + 1}.${createdAt.getFullYear()}`;
  }

  public updatedAt(): string {
    const updatedAt = new Date(this.updated_at);
    return `${updatedAt.getDate()}.${updatedAt.getMonth() + 1}.${updatedAt.getFullYear()}`;
  }

  public isAmCanDeleteProject(): boolean {
    return this.permission_level >= ProjectPerLevel.CanDelete;
  }

  public isAmCanEditAccessLevelProject(): boolean {
    return this.permission_level >= ProjectPerLevel.CanEditAccessLevel;
  }

  public isAmCanEditProject(): boolean {
    return this.permission_level >= ProjectPerLevel.CanEdit;
  }

  public isAmCanViewProject(): boolean {
    return this.permission_level >= ProjectPerLevel.CanView;
  }

  public isAmCamEditUserProject(userProject: ProjectsUser, me: User): boolean {
    return !(userProject.user.pk === this.group.owner) || this.group.owner === me.pk;
  }

  public isUserCanEditProject(user: User): boolean {
    return this.creator.pk === user.pk || (this.group && this.group.owner === user.pk);
  }

  public isPersonalProject(): boolean {
    return !this.group;
  }

  public getLink(): string {
    return `${environment.PROJECT_DOMAIN}/dashboard/project/${this.id}/`;
  }

  public getShareLink(): string {
    return `${environment.PROJECT_DOMAIN}/dashboard/project/${this.id}/?shared_token=${this.shared_token}`;
  }

  public getIframeLink(): string {
    return `${this.getShareLink()}&iframe=true`
  }

  public getEmbeddedIFrame(): string {
    return `<iframe src="${this.getIframeLink()}" style="overflow:hidden;border-style:none;height:100vh;width:100%"></iframe>`;
  }

  public navigateToProject(): string {
    return `/dashboard/project/${this.id}`;
  }

  public navigateToShareProject(): string {
    return `${this.navigateToProject()}/share`;
  }

  public navigateToAccessProject(): string {
    return `${this.navigateToProject()}/access`;
  }

  public isProjectReady(): boolean {
    return !this.isNeedStepOne() && !this.isNeedStepTwo();
  }

  public isAmCanViewNonFinishedProject(): boolean {
    return this.isAmCanEditAccessLevelProject() || this.isAmCanDeleteProject();
  }

  public isNeedStepOne(): boolean {
    if (!this.use_user_observation) {
      return !Object.keys(this.station).length;
    }
    return !this.user_observation_name;
  }

  public isNeedStepTwo(): boolean {
    return !this.is_saved;
  }

  public spokesToString(): string {
    const data = {
      4: Spokes.Four,
      8: Spokes.Eight,
      16: Spokes.Sixteen,
      32: Spokes.ThirtyTwo
    };
    return data[this.number_of_spokes];
  }

  public regularityToString(): string {
    const data = {
      1: Regularity.HOURLY,
      2: Regularity.TwoHourly,
      3: Regularity.ThreeHourly,
      6: Regularity.SixHourly,
      12: Regularity.TwiceDaily,
      24: Regularity.DAILY,
    };
    return data[this.regularity];
  }

  public regularityDayNight(): string {
    switch (this.day_night) {
      case DayNightRegularityEnum.DAY: return Regularity.HOURLY_DAY;
      case DayNightRegularityEnum.NIGHT: return Regularity.HOURLY_NIGHT;
      case DayNightRegularityEnum.DAY_AND_NIGHT: return Regularity.HOURLY;
    }
  }

  public spokesToNumber(spokesString): number {
    if (spokesString === Spokes.Four) {
      return 4;
    }
    if (spokesString === Spokes.Eight) {
      return 8;
    }
    if (spokesString === Spokes.Sixteen) {
      return 16;
    }
    if (spokesString === Spokes.ThirtyTwo) {
      return 32;
    }
  }

  public regularityToNumber(regularity): number {
    switch (regularity) {
      case Regularity.HOURLY:
      case Regularity.HOURLY_DAY:
      case Regularity.HOURLY_NIGHT:
        return 1;
      case Regularity.TwoHourly: return 2;
      case Regularity.ThreeHourly: return 3;
      case Regularity.SixHourly: return 6;
      case Regularity.TwiceDaily: return 12;
      case Regularity.DAILY: return 24;
    }
  }

  public dayNightBasedOnRegularity(regularity): DayNightRegularityEnum {
    switch (regularity) {
      case Regularity.HOURLY: return DayNightRegularityEnum.DAY_AND_NIGHT;
      case Regularity.HOURLY_DAY: return DayNightRegularityEnum.DAY;
      case Regularity.HOURLY_NIGHT: return DayNightRegularityEnum.NIGHT;
    }
  }

  public getWRCover(): string {
    if (this.map_cover_as_wr_background) {
      return this.custom_map_cover;
    }
    if (this.wind_rose_background) {
      return this.wind_rose_background;
    }
    return null;
  }

  windSpeedBinsToLabel(): void {
    const labels = this.wind_speed_bins.slice();
    labels.forEach((val, indx) => {
      let value = val.toString();
      let nextValue;
      if (Number.isInteger(val)) {
        value = val.toFixed(1);
      }
      if (typeof labels[indx+1] !== 'undefined') {
        nextValue = labels[indx+1].toString();
        if (Number.isInteger(labels[indx+1])) {
          nextValue = labels[indx+1].toFixed(1);
        }
      }
      if (indx === 1) {
        this.wind_speed_labels_custom.push(`< ${value}`);
        this.wind_speed_labels_custom.push(`${value} - ${nextValue}`);
      } else if (indx + 1 === labels.length) {
        this.wind_speed_labels_custom.push(`> ${value}`);
      } else if (indx !== 0) {
        this.wind_speed_labels_custom.push(`${value} - ${nextValue}`);
      }
    });
  }
}

export class ProjectsUser {
  id: number;
  level: ProjectPerLevel;
  project: string;
  user: User;

  constructor(init?: Partial<ProjectsUser>) {
    Object.assign(this, init);
    this.user = new User(this.user);
  }

  public getStringLevel(): string {
    if (this.level === ProjectPerLevel.CanDelete) {
      return 'Can delete';
    }
    if (this.level === ProjectPerLevel.CanEdit) {
      return 'Can edit';
    }
    if (this.level === ProjectPerLevel.CanView) {
      return 'Can view';
    }
    if (this.level === ProjectPerLevel.CanEditAccessLevel) {
      return 'Can edit access level';
    }
  }
}

export interface Country {
  code: string;
  name?: string;
  station_count?: number;
  country_coordinates?: MarkerV2;
}

export class Station {
  country: string;
  country_iso: string;
  elevation: number;
  end_time: string;
  id: number;
  latitude: number;
  longitude: number;
  name: string;
  observation_count: number;
  start_time: string;
  original_id: string;

  constructor(init?: Partial<Station>) {
    Object.assign(this, init);
  }

  public getDataRange(): string {
    const startTime = new Date(this.start_time);
    const endTime = new Date(this.end_time);
    return `${startTime.getDate()}.${startTime.getMonth() + 1}.${startTime.getFullYear()}
    - ${endTime.getDate()}.${endTime.getMonth() + 1}.${endTime.getFullYear()}`;
  }
}

export interface StationsResponse {
  stations: Station[];
  stations_count: number;
  countries_count: number;
  updated_at: string;
}

export interface ProjectData {
  calm_palette: string;
  calm_label: string;
  calm_frequency: number[];
  calm_summ: number;
  frequency_table: [][];
  summs_frequency: number[];
  labels_wind_direction: string[];
  labels_wind_speed: string[];
  palette: string[];
  histogram_wind_direction: number[];
  histogram_wind_speed: number[][];
  histogram_weibull: number[][];
  labels_wind_direction_verbose: string[];
  summary: Summary;
  message?: string;
  weibull_image: string;
  weibull_error: string;
}

export interface Summary {
  calm_definition: string;
  calm_frequency: number;
  mean_speed: number;
  peak_direction_index: number;
  peak_frequency: number;
  peak_speed_index: number;
}

export interface MarkerCustom {
  name?: string;
  lat?: number;
  lng?: number;
  mapCenter?: MarkerV2;
  label?: string;
  info?: string;
  draggable?: boolean;
  zoom?: number;
  isSearchCenter?: boolean;
  isCountry?: boolean;
  selected?: boolean;
}

export interface Marker {
  lat: number;
  lng: number;
}

export interface MarkerV2 {
  latitude: number;
  longitude: number;
}

export interface DashboardStyles {
  name: DashboardDiv;
  style: {};
  class: string;
}

export interface Weibull {
  shape: number;
  scale: number;
  custom: boolean;
}

export interface ObservationName {
  id: string;
  name: string;
}

export interface CustomObsData {
  wind_speed: number;
  wind_direction: number;
  name?: string;
}
