import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {Lightbox} from 'ngx-lightbox';

import {Feedback} from '../../../models/user.model';


@Component({
  selector: 'app-show-report',
  templateUrl: './show-report.component.html',
  styleUrls: ['./show-report.component.scss']
})
export class ShowReportComponent implements OnInit {
  public createdAt: string;

  constructor(
    public dialogRef: MatDialogRef<ShowReportComponent>,
    private lightbox: Lightbox,
    @Inject(MAT_DIALOG_DATA) public data: Partial<Feedback>
  ) {
  }

  ngOnInit() {
    this.createdAt = new Date(this.data.created_at).toLocaleDateString('en-GB');
  }

  close(): void {
    this.dialogRef.close();
  }

  openImage(imgSrc: string) {
    this.lightbox.open([{src: imgSrc, thumb: ''}]);
  }

}
