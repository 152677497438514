import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {StripeService, StripeCardNumberComponent} from 'ngx-stripe';
import {
  StripeCardElementOptions, StripeCardNumberElementChangeEvent,
  StripeElementsOptions, StripeElementStyle
} from '@stripe/stripe-js';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {CARD_OPTIONS, CVC_OPTIONS, EXPIRY_OPTIONS, PlanInformation} from "../../../constants/payments";
import {cloneDeep} from "lodash/fp";
import {CardType} from "../../../enum/payment.enum";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {User} from "../../../models/user.model";

@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss']
})
export class PaymentInformationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
  cardOptions: StripeCardElementOptions;
  expiryOptions: StripeCardElementOptions;
  cvcOptions: StripeCardElementOptions;
  stripeForm: FormGroup;
  stripeCardValid: {cvv: boolean, date: boolean, number: boolean} = {
    cvv: false, date: false, number: false
  };
  currentCardType: CardType = 'unknown';

  style: StripeElementStyle = {
    base: {
      iconColor: '#666EE8',
      color: '#31325F',
      fontWeight: '300',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSize: '18px',
      '::placeholder': {
        color: '#CFD7E0'
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  isActionChange: boolean = false;
  isShowCardBlock: boolean = true;
  private componentDestroy$: Subject<void> = new Subject();


  constructor(
    public dialogRef: MatDialogRef<PaymentInformationComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {
      btn: string,
      isTrial?: boolean,
      planInformation?: PlanInformation,
    },
    private stripeService: StripeService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.cardOptions = {...cloneDeep(CARD_OPTIONS), style: this.style};
    this.expiryOptions = {...cloneDeep(EXPIRY_OPTIONS), style: this.style};
    this.cvcOptions = {...cloneDeep(CVC_OPTIONS), style: this.style};
    this.stripeForm = this.fb.group({
      name: [this.authService.user.getFullName(), [Validators.required]]
    });

    this.isActionChange = this.data.btn === 'Save Card';
    this.isShowCardBlock = !this.isActionChange;
  }

  ngOnDestroy(): void {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  ngAfterViewInit(): void {
   this.cardTypeDetect();
  }

  onChangeCVV(event) {
    this.stripeCardValid.cvv = event.complete;
  }

  onChangeNumber(event) {
    this.stripeCardValid.number = event.complete;
  }

  onChangeDate(event) {
    this.stripeCardValid.date = event.complete;
  }

  isCardInValid(): boolean {
    return !this.stripeCardValid.date || !this.stripeCardValid.cvv || !this.stripeCardValid.number;
  }

  cardTypeDetect(): void {
    if (this.card) {
      this.card.change
        .pipe(takeUntil(this.componentDestroy$))
        .subscribe((data: StripeCardNumberElementChangeEvent) => {
          this.currentCardType = data.brand;
        })
    }
  }

  get user(): User {
    return this.authService.user;
  }

  sendCart(): void {
    if (this.isSubmitButtonDisabled) {
      return;
    }
    const name = this.stripeForm.get('name').value;
    this.dialogRef.close({card: this.card.element, name});
  }

  showCardBlock(): void {
    this.isShowCardBlock = true;
    setInterval(_ => this.cardTypeDetect(), 0);
  }

  get isSubmitButtonDisabled(): boolean {
    return this.stripeForm.invalid || this.isCardInValid();
  }

  close(): void {
    this.dialogRef.close();
  }
}
