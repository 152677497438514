import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {concatMap, delay, map, mergeMap, retryWhen, switchMap, tap} from 'rxjs/operators';
import {linkedGroups} from '../models/company.model';
import {
  Country,
  CustomObsData,
  Marker,
  ObservationName,
  ProjectData,
  Projects,
  ProjectsUser,
  Station,
  Weibull,
} from "../models/project.model";
import {User} from '../models/user.model';
import {environment} from '../../../environments/environment';
import {fromFetch} from 'rxjs/fetch';
import {NotificationService} from './notification.service';
import {UtilsService} from './utils.service';
import {HttpCacheManager, withCache} from '@ngneat/cashew';
import {NgxSpinnerService} from "ngx-spinner";
import {Router} from "@angular/router";

interface TasksResponse {
  task_status: 'PENDING' | 'SUCCESS';
  task_id: string;
  results?: string;
}

enum CacheType {
  Personal = 'personalProject',
  Company = 'companyProject',
  Shared = 'sharedProject',
}

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  API = environment.API_URL;
  MAPS_KEY = environment.GoogleMapsKey;
  mapsUrl = 'https://maps.google.com/maps/api/staticmap';

  companyProjects: Projects[] = [];
  personalProjects: Projects[] = [];
  personalAndCompanyProjects: Projects[] = [];
  currentProjectData: ProjectData = null;
  currentProject: Projects = null;

  latPattern = `^(\\+|-)?(?:90(?:(?:\\.0{1,})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,})?))$`;
  lonPattern = `^(\\+|-)?(?:180(?:(?:\\.0{1,})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,})?))$`;

  private _currentProject: BehaviorSubject<Projects> = new BehaviorSubject<Projects>(null);
  currentProject$: Observable<Projects> = this._currentProject.asObservable();

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private utils: UtilsService,
    private manager: HttpCacheManager,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
  }

  deleteProjectsCache(): void {
    Object.values(CacheType)
      .map(val => {
        this.deleteCache(val);
      });
  }

  deleteCache(val): void {
    if (this.manager.has(val)) {
      this.manager.delete(val);
    }
  }

  setProject(project: Projects): void {
    this._currentProject.next(project);
  }

  public getProjects(personal: boolean = false, company: linkedGroups = null): Observable<Projects[]> {
    let params;
    let cacheKey: CacheType = CacheType.Company;
    if (personal) {
      cacheKey = CacheType.Personal;
      params = {personal: 'true'};
    }
    if (company) {
      params = {group: company.id.toString()};
    }
    return this.http.get<Projects[]>(this.API + `/projects/`, withCache({
      ...params,
      cache$: true,
      key$: cacheKey
    }))
      .pipe(
        tap(projects => {
          if (personal) {
            this.personalProjects = projects.map(project => new Projects(project));
          }
          if (company) {
            this.companyProjects = projects.map(project => new Projects(project));
          }
          this.personalAndCompanyProjects = [...this.personalProjects.concat(this.companyProjects)];
        })
      );
  }

  public getProject(id, sharedToken?: string): Observable<any> {
    const params = sharedToken ? new HttpParams().append('shared_token', sharedToken) : null;
    return this.http.get(this.API + `/projects/${id}/`, {params})
      .pipe(tap(project => {
        this.currentProject = new Projects(project);
        this.setProject(this.currentProject);
      }));
  }

  public getPersonalProjects(): Observable<any> {
    return this.getProjects(true, null);
  }

  public getCompanyProjects(company: linkedGroups): Observable<any> {
    if (company.is_company) {
      return this.getProjects(false, company);
    } else {
      return this.getSharedProject(company);
    }
  }

  public getSharedProject(company: linkedGroups): Observable<any> {
    return this.http.get<Projects[]>(this.API + `/groups/${company.id}/projects/`, withCache({
      cache$: true,
      key$: CacheType.Shared
    }))
      .pipe(
        tap(projects => {
          this.companyProjects = projects.map(project => new Projects(project));
        })
      );
  }

  public createProject(data): Observable<Projects> {
    return this.http.post<Projects>(this.API + `/projects/`, data)
      .pipe(tap(() => this.deleteProjectsCache()));
  }

  public deleteProject(project: Projects): Observable<any> {
    return this.http.delete(this.API + `/projects/${project.id}/`).pipe(
      tap(() => this.deleteProjectsCache()),
      mergeMap(() => this.notificationService.getNotifications()),
    );
  }

  public copyProject(project: Projects, data): Observable<any> {
    return this.http.post(this.API + `/projects/${project.id}/copy/`, data)
      .pipe(tap(() => this.deleteProjectsCache()));
  }

  public copyProjectToGroup(project: Projects, data): Observable<any> {
    return this.http.post(this.API + `/projects/${project.id}/sharing/groups/`, data)
      .pipe(tap(() => this.deleteProjectsCache()));
  }

  public editProject(project: Projects, data): Observable<Projects> {
    return this.http.patch<Projects>(this.API + `/projects/${project.id}/`, data)
      .pipe(tap(p => {
        this.currentProject = new Projects(p);
        this.deleteProjectsCache();
        this.setProject(this.currentProject);
      }));
  }

  public setUserObservationName(name): Observable<ObservationName> {
    return this.http.post<ObservationName>(this.API + '/user_observation_name/', {name});
  }

  public setUserObservationData(data): Observable<Projects> {
    return this.http.post<Projects>(this.API + '/user_observation/', data);
  }

  public getUserObservationData(name: ObservationName): Observable<CustomObsData[]> {
    return this.http.get<CustomObsData[]>(this.API + `/user_observation/?name=${name.name}`);
  }

  public getProjectPermissions(project: Projects): Observable<User[]> {
    return this.http.get<User[]>(this.API + `/projects/${project.id}/permissions/`);
  }

  public editProjectPermissions(permission: ProjectsUser, level): Observable<ProjectsUser> {
    return this.http.patch<ProjectsUser>(this.API + `/projects/${permission.project}/permissions/${permission.id}/`, {level});
  }

  public deleteProjectPermissions(permission: ProjectsUser) {
    return this.http.delete(this.API + `/projects/${permission.project}/permissions/${permission.id}/`);
  }

  public getStation(code: string): Observable<Station[]> {
    return this.http.get<Station[]>(this.API + `/stations/`, withCache({
      cache$: true,
      country: code
    }));
  }

  public searchStation(params): Observable<Station[]> {
    return this.http.get<Station[]>(this.API + `/stations/search/`, withCache({
      cache$: true,
      ...params
    }));
  }

  public getCountry(): Observable<Country[]> {
    return this.http.get<Country[]>(this.API + `/countries/`, withCache({
      cache$: true,
    }));
  }

  public setStation(project: Projects, data): Observable<Projects> {
    return this.http.patch<Projects>(this.API + `/projects/${project.id}/`, data);
  }

  public setWindRoseLogo(projectId: string, image) {
    const headerDict = {
      'Content-Type': 'application/json',
      'Content-Disposition': `attachment; filename=wr_logo_${new Date().getTime()}.jpg`,
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict)
    };
    return this.http.put<Projects>(this.API + `/projects/${projectId}/wr_logo/`, image, requestOptions);
  }

  public setCover(project: Projects, cover): Observable<Projects> {
    const requestOptions = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Content-Disposition', `attachment; filename=${new Date().getTime()}.png`)
    };
    return this.http.put<Projects>(this.API + `/projects/${project.id}/cover/`, cover, requestOptions);
  }

  public setCoverFromMap(project: Projects, marker: Marker): Observable<any> {
    const url = `${this.mapsUrl}?sensor=false&center=${marker.lat},${marker.lng}&zoom=14&maptype=satellite&size=768x518&scale=4&key=${this.MAPS_KEY}`;
    const headerDict = {
      'Content-Type': 'application/json',
      'Content-Disposition': `attachment; filename=map_${new Date().getTime()}.png`,
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict)
    };
    return fromFetch(url)
      .pipe(
        mergeMap(resp => resp.blob()),
        mergeMap(blob  => this.http.put<Projects>(this.API + `/projects/${project.id}/custom_map_cover/`, blob, requestOptions))
      );
  }

  public setCoverWR(project: Projects, blob): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Content-Disposition', `attachment; filename=map_${new Date().getTime()}.png`)
    };
    return this.http.put<any>(this.API + `/projects/${project.id}/wr_background/`, blob, requestOptions);
  }

  getMapImage(zoom, center): Observable<Blob> {
    const marker: Marker = {lng: center.lng(), lat: center.lat()};
    const url = `${this.mapsUrl}?sensor=false&center=${marker.lat},${marker.lng}&zoom=${zoom}&maptype=satellite&size=640x640&scale=4&key=${this.MAPS_KEY}`;
    return fromFetch(url)
      .pipe(
        mergeMap(resp => resp.blob())
      );
  }

  public deleteCoverWR(project: Projects): Observable<any> {
    return this.http.delete<Projects>(this.API + `/projects/${project.id}/wr_background/`);
  }

  public deleteCover(project: Projects): Observable<any> {
    return this.http.delete<Projects>(this.API + `/projects/${project.id}/cover/`);
  }

  /**
   * Sends GET request to /stations/{id}/data/ endpoint, gets task_id as response,
   * then polls /tasks/{task_id}/ endpoint to see if the blob file is ready or not
   * and if it's ready fetch it
   *
   * @param station: Station
   * @param type: 'csv' | 'xlsx'
   * @return Observable<Blob>
   */
  public getStationDataByType(station: Station, type: 'csv' | 'xlsx'): Observable<Blob> {

    return this.http.get<{ task_id: string }>(this.API + `/stations/${station.id}/data/`, {
      params: new HttpParams().set('file', type),
    })
      .pipe(
        switchMap((stationsResponse) =>
          this.http.get<TasksResponse>(this.API + `/tasks/${stationsResponse.task_id}/`)
            .pipe(
              tap(_ => this.spinner.hide()),
              map((tasksResponse: TasksResponse) => {
                if (Boolean(tasksResponse.task_status === 'SUCCESS' && tasksResponse.results)) {
                  return tasksResponse;
                }
                throw tasksResponse;
              }),
              retryWhen(_ =>
                _.pipe(
                  concatMap((e) => {
                    return of(e).pipe(delay(3000));
                  }),
                )
              ),
            )
        ),
        mergeMap((tasksResponse: TasksResponse) => {
          return fromFetch(tasksResponse.results).pipe(mergeMap(response => response.blob()));
        }),
      );
  }

  public getWeibullCoefficients(project: Projects): Observable<Weibull> {
    return this.http.get<Weibull>(this.API + `/projects/${project.id}/weibull_coefficients/`);
  }

  public setWeibullCoefficients(project: Projects, data): Observable<Weibull> {
    return this.http.put<Weibull>(this.API + `/projects/${project.id}/weibull_coefficients/`, data);
  }

  public getProjectCSV(project: Projects): Observable<any> {
    return this.http.get(this.API + `/projects/${project.id}/data/`, {
      params: new HttpParams().set('format', 'csv'),
      headers: new HttpHeaders({'Content-Type': 'text/csv'}),
      observe: 'response',
      responseType: 'blob'
    });
  }

  public getProjectData(project: Projects, sharedToken?: string): Observable<ProjectData> {
    const params = sharedToken ? new HttpParams().append('shared_token', sharedToken) : null;

    return this.http.get<ProjectData>(this.API + `/projects/${project.id}/data/`, {params}).pipe(
      map((projectData: ProjectData) => {
        if (!projectData.message) {
          return projectData;
        }
        throw projectData;
      }),
      retryWhen(_ =>
        _.pipe(
          concatMap((e, i) => {
            if (i >= 30) {
              this.router.navigateByUrl('');
              return throwError({ ...e });
            }
            return of(e).pipe(delay(199));
          }),
        )
      ),
      tap((projectData: ProjectData) => {
        this.currentProjectData = projectData;
      }),
      map((data: ProjectData) => {
        data.calm_frequency = [];
        data.summs_frequency = this.utils.summVerticalDataInArray(data.frequency_table);
        data.frequency_table.forEach(freq => data.calm_frequency.push(freq.splice(0, 1)[0]));
        data.calm_label = data.labels_wind_speed.splice(0, 1)[0];
        data.calm_palette = data.palette.splice(0, 1)[0];
        data.calm_summ = data.summs_frequency.splice(0, 1)[0];
        return data;
      }));
  }

  public getProjectsByName(name: string): Observable<Projects[]> {
    return this.http.get<Projects[]>(this.API + `/projects/?search=` + name);
  }
}

