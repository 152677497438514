import {EventEmitter, Injectable, TemplateRef} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Params, Router} from '@angular/router';

import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {isNil} from 'lodash/fp';

import {CreateProjectComponent} from '../components/modal/create-project/create-project.component';
import {Projects} from '../models/project.model';
import {AuthService} from './auth.service';
import {PaymentInformationComponent} from '../components/modal/payment-information/payment-information.component';
import {YesNoComponent} from '../components/modal/yes-no/yes-no.component';
import {SendReportComponent} from '../components/modal/send-report/send-report.component';
import {InfoComponent} from '../components/modal/info/info.component';
import {ErrorMessage} from '../enum/project.enum';
import {ImageCropperComponent} from '../components/modal/image-cropper/image-cropper.component';
import {DashboardDownloadComponent} from '../components/modal/dashboard-download/dashboard-download.component';
import {InfoWindowComponent} from '../components/modal/info-window/info-window.component';
import {ConfirmStepOneComponent} from "../components/modal/confirm-step-one/confirm-step-one.component";
import {InfoV3Component} from "../components/modal/info-v3/info-v3.component";
import {ConfirmDiscountComponent} from "../components/modal/confirm-discount/confirm-discount.component";
import {PlanInformation} from "../constants/payments";
import {ComponentType} from "@angular/cdk/overlay";


@Injectable({
  providedIn: 'root'
})
export class ModalWindowService {

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService

  ) { }

  public openProjectCreationModal(): void {
    if (!this.authService.user.isAmNotCompanyUser() && !this.authService.user.getFirstCompany().subscription.is_payable) {
      this.openErrorModal(
          'Sorry, you need to choose a plan before you create a new project.',
          'Your company\'s subscription expired, please contact the administration for further information.',
          'Choose a Plan',
          false
      );
      return;
    }

    if (this.authService.user.isAmNotCompanyUser()  &&
        !this.authService.user.isMyCompanyHaveSub() &&
        isNil(this.authService.user.isHaveSub())) {
      this.openErrorModal(
          'Sorry, you need to choose a plan before you create a new project.',
          ErrorMessage.errorDoesNotHaveSubscription,
          'Choose a Plan',
          true,
          { trial: 'true' }
      );
      return;
    }

    const dialogRef = this.dialog.open(CreateProjectComponent, {
      width: '700px',
      data: {
        user: this.authService.user
      }
    });

    dialogRef.afterClosed()
      .pipe(
        filter(Boolean),
        map((project: Projects) => new Projects(project))
      )
      .subscribe((project: Projects) => {
        this.router.navigateByUrl(project.navigateToProject());
      });
  }

  public openPayInformationModal(
    btnName: string = '',
    isTrial: boolean = false,
    planInformation: PlanInformation = null,
  ): Observable<any> {
    const width = planInformation ? '1400px' : '700px';
    const dialogRef = this.dialog.open(PaymentInformationComponent, {
      width: width,
      data: {btn: btnName, isTrial, planInformation}
    });
    return dialogRef.beforeClosed();
  }

  public openYesNoModal(body: string, title: string = '', yesButtonText?: string, noButtonText?: string, isCloseBtn: boolean = true): Observable<boolean> {
    const dialogRef = this.dialog.open(YesNoComponent, {
      width: '700px',
      data: {title, body, yesButtonText, noButtonText, isCloseBtn}
    });
    return dialogRef.beforeClosed();
  }

  public openInfoV3Modal(
    body: string[],
    ul: string[],
    footer: string[],
    title: string,
    yesButtonText?: string,
    titleCenter?: boolean
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(InfoV3Component, {
      width: '600px',
      data: {title, body, yesButtonText, ul, footer, titleCenter}
    });
    return dialogRef.beforeClosed();
  }

  public openSendReportModal(isBeforeClosed: boolean = false): Observable<any> {
    const dialogRef = this.dialog.open(SendReportComponent, {
      width: '700px'
    });
    if (isBeforeClosed) {
      return dialogRef.beforeClosed();
    } else {
      return dialogRef.afterClosed();
    }
  }

  public openUploadLogo() {
    return this.dialog.open(ImageCropperComponent, {
      width: '700px',
      data: {
        canvasWidth: 600,
        canvasHeight: 400,
        keepAspect: false,
        preserveSize: true,
        cropperDrawSettings: {strokeColor: 'rgba(158, 216, 234, 1)', strokeWidth: 1}
      }
    }).afterClosed();
  }

  public openUploadBackground(): Observable<any> {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      width: '700px',
      data: {
        canvasWidth: 600,
        canvasHeight: 400,
        keepAspect: false,
        preserveSize: true,
        cropperDrawSettings: {strokeColor: 'rgba(158, 216, 234, 1)', strokeWidth: 1}
      }
    });
    return dialogRef.afterClosed();
  }

  public openErrorModal(title: string = '', body: string, btn: string, isShowBtn: boolean, queryParams?: Params): Observable<any> {
    const dialogRef = this.dialog.open(InfoComponent, {
      width: '700px',
      data: {title, body, btn, isShowBtn, queryParams}
    });
    return dialogRef.beforeClosed();
  }

  public openCustomModalWindow<T>(component: ComponentType<any> | TemplateRef<any>, options?: any | MatDialogConfig): Observable<T> {
    const dialogOptions: MatDialogConfig = Object.assign(new MatDialogConfig(), options);

    const dialogRef = this.dialog.open(component, dialogOptions);

    return dialogRef.beforeClosed().pipe(map(val => val));
  }

  public openDashboardDownloadModal(isTrial: Boolean, project: Projects): EventEmitter<unknown> {
    const dialogRef = this.dialog.open(DashboardDownloadComponent, {
      width: '800px',
      data: {isTrial: isTrial, project}
    });
    return dialogRef.componentInstance.onClick;
  }

  openInformationWindow(text: string[], subtext?: string[], showYesIcon?: boolean, title?: string) {
    this.dialog.open(InfoWindowComponent, {
      width: '600px',
      data: {text, subtext, showYesIcon, title}
    });
  }

  openConfirmStepOneComponent() {
    const dialogRef = this.dialog.open(ConfirmStepOneComponent, {
      width: '800px',
    });
    return dialogRef.beforeClosed();
  }

  openConfirmDiscountComponent(discount, isHaveSub) {
    const dialogRef = this.dialog.open(ConfirmDiscountComponent, {
      width: '800px',
      data: {discount: discount, isHaveSub: isHaveSub}
    });
    return dialogRef.beforeClosed();
  }

  showSuccessReportWindow() {
    this.openInformationWindow(
      ['Your message has been sent to the WRE Support Team. We will respond as soon as possible.'],
      null, null,
      'Thank you!'
    );
  }

}
