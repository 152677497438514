import {Component, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ProjectData, Projects} from '../../../models/project.model';
import {filter, takeUntil} from 'rxjs/operators';
import {ProjectsService} from '../../../services/projects.service';
import {CustomizationService} from '../../../services/customization.service';
import {
  BeaufortScaleBinConfigurationKMH,
  BeaufortScaleBinConfigurationKN,
  BeaufortScaleBinConfigurationMPH,
  BeaufortScaleBinConfigurationMS,
  BinConfiguration,
  Palette,
  StandardBinConfiguration
} from '../../../enum/project.enum';
import {WindSpeed} from '../../../enum/wind-speed.enum';
import {stepTwoCustomization} from '../../../interfaces/customization';
import {UtilsService} from '../../../services/utils.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as echarts from 'echarts/lib/echarts';
import EChartOption = echarts.EChartOption;
import {assign, cloneDeep} from 'lodash/fp';
import {CHART_BAR_START_OPTIONS} from '../../../constants/charts';

@Component({
  selector: 'app-table-and-preview-customization',
  templateUrl: './table-and-preview-customization.component.html',
  styleUrls: ['./table-and-preview-customization.component.scss']
})
export class TableAndPreviewCustomizationComponent implements OnDestroy {
  projectId: string;
  projectData: ProjectData = {} as ProjectData;
  palette: string[] = [];
  roseLabels: string[] = [];
  parsedRoseLabels: string[][] = [];
  windSpeedUnits: WindSpeed = null;
  binConfiguration = BinConfiguration;
  currentConfiguration: BinConfiguration;
  windSpeedLabelsCustom: string[];
  isAmCanEditProject: boolean;

  weibullForm = new FormGroup({
    shape: new FormControl('', Validators.required),
    scale: new FormControl('', Validators.required),
    default: new FormControl('', Validators.required),
  });
  // isShowWeibull: boolean;
  // isWeibullMenuShow = false;
  // weibullForm = new FormGroup({
  //   weibull_shape: new FormControl('', Validators.required),
  //   weibull_scale: new FormControl('', Validators.required),
  // });
  customizedChartBarOptions: Observable<EChartOption>;
  options;
  // weibull_shape: number;
  // weibull_scale: number;
  weibull: boolean;
  private componentDestroy$: Subject<void> = new Subject();
  // get weibullLineSeries() {
  //   return this.getWeibullLineSeriesFromOptions(this.options);
  // }

  constructor(
    private utils: UtilsService,
    private projectsService: ProjectsService,
    private utilsService: UtilsService,
    private customizationService: CustomizationService
  ) {
    this.customizedChartBarOptions = this.customizationService.customizedChartBarOptions$;
    this.customizedChartBarOptions.pipe(
      takeUntil(this.componentDestroy$)
    ).subscribe( result => {
      this.options = result;
      // this.isShowWeibull = this.isWeibullLine(this.weibullLineSeries);
    });
    this.customizationService
      .stepTwoCustomizationData$
      .pipe(
        filter(Boolean),
        takeUntil(this.componentDestroy$)
      )
      .subscribe((data: stepTwoCustomization) => {
        this.currentConfiguration = data.wind_speed_bins_type;
        this.onConfigurationChange(data);
      });
  }

  get stepTwoCustomizationData(): stepTwoCustomization {
    return this.customizationService.stepTwoCustomizationData;
  }

  onConfigurationChange(data): void {
    this.setMySetting(data);

    switch (this.currentConfiguration) {
      case BinConfiguration.BINS_STANDARD:
        this.roseLabels = StandardBinConfiguration.slice();
        break;
      case BinConfiguration.BINS_BEAUFORT:
        this.setBeaufortScaleLabels();
        break;
       case BinConfiguration.BINS_CUSTOM:
         if (this.windSpeedLabelsCustom && this.windSpeedLabelsCustom.length) {
           this.roseLabels = this.windSpeedLabelsCustom;
         } else {
           if (this.stepTwoCustomizationData.wind_speed_bins_type !== BinConfiguration.BINS_CUSTOM){
             this.roseLabels = [this.stepTwoCustomizationData.calmLabel].concat(this.roseLabels)
           }
         }
         break;
    }
    this.setParsedRoseLabels();
  }

  setBeaufortScaleLabels(): void {
    switch (this.windSpeedUnits) {
      case WindSpeed.KMH:
        this.roseLabels = BeaufortScaleBinConfigurationKMH.slice();
        break;
      case WindSpeed.KN:
        this.roseLabels = BeaufortScaleBinConfigurationKN.slice();
        break;
      case WindSpeed.MPH:
        this.roseLabels = BeaufortScaleBinConfigurationMPH.slice();
        break;
      case WindSpeed.MS:
        this.roseLabels = BeaufortScaleBinConfigurationMS.slice();
        break;
    }
  }

  setParsedRoseLabels() {
    this.parsedRoseLabels = [];
    const labels = this.roseLabels.slice();
    labels.forEach((value, index) => {
      if (index !== 0) {
        this.getPalette(index);
      }
      const first = this.parseLabels(value, 0);
      const second = this.parseLabels(value, 1);
      this.parsedRoseLabels.push([first, second]);
    });
  }

  parseLabels(label: string, idx: number): string {
    if (label.indexOf('-') !== -1) {
      label = label.split('-')[idx];
    }
    if (label.indexOf('>') !== -1) {
      if (idx === 0) {
        label = label.split('>')[1];
      } else {
        label = '';
      }
    }
    if (label.indexOf('<') !== -1) {
      if (idx === 1) {
        label = label.split('<')[1];
      } else {
        label = '0';
      }
    }
    return label.trim();
  }

  getPalette(idx): string {
    const idxWithoutCalm = idx - 1;
    if (typeof this.palette[idxWithoutCalm] !== 'undefined') {
      return this.palette[idxWithoutCalm];
    }
    this.palette[idxWithoutCalm] = Palette[idxWithoutCalm];
    if (typeof Palette[idxWithoutCalm] === 'undefined') {
      const maxIndex = Palette.length - 1;
      this.palette[idxWithoutCalm] = Palette[maxIndex];
    }
    return this.palette[idxWithoutCalm];
  }

  setPalette(idx, event): void {
    const idxWithoutCalm = idx - 1;
    this.palette[idxWithoutCalm] = event.target.value;
  }

  addNewLabel(): void {
    if (this.isICanAddNewLabel()) {
      const idx = this.parsedRoseLabels.push(['', '']) - 1;
      this.getPalette(idx);
      const preIdx = idx - 1;
      if (this.parsedRoseLabels[preIdx][1] !== '') {
        this.parsedRoseLabels[idx][0] = this.parsedRoseLabels[preIdx][1];
      }
    }
  }

  deleteLabel(idx: number): void {
    if (idx === 0) {
      return;
    }
    const indexWithoutCalm = idx - 1;
    delete this.parsedRoseLabels[idx];
    delete this.palette[indexWithoutCalm];
    this.parsedRoseLabels = this.parsedRoseLabels.filter(i => typeof i !== 'undefined');
    this.palette = this.palette.filter(i => typeof i !== 'undefined');
  }

  checkInputIsInvalid(idx): boolean {
    if (this.parsedRoseLabels[idx][1] == '' && idx + 1 === this.parsedRoseLabels.length) {
      return false;
    }
    const min = Number(this.parsedRoseLabels[idx][0]);
    const max = Number(this.parsedRoseLabels[idx][1]);
    if (max === min) {
      return true;
    }
    return max < min;
  }

  checkHaveInvalidLabels(): boolean {
    let isHave = false;
    const labels = this.parsedRoseLabels.slice();
    labels.forEach((val, index) => {
      if (this.checkInputIsInvalid(index)) {
        isHave = true;
      }
    });
    return isHave;
  }

  changeLabel(idx, isMax): void {
    if (isMax && this.parsedRoseLabels[idx][1] !== '') {
      if (typeof this.parsedRoseLabels[idx + 1] === 'undefined') {
        this.addNewLabel();
      }
      this.parsedRoseLabels[idx + 1][0] = this.parsedRoseLabels[idx][1];
    }
    if (this.parsedRoseLabels[idx][1] !== '') {
      this.roseLabels[idx] = `${this.parsedRoseLabels[idx][0]} - ${this.parsedRoseLabels[idx][1]}`;
    }
    if (this.parsedRoseLabels[idx][1] === '') {
      this.roseLabels[idx] = `> ${this.parsedRoseLabels[idx][0]}`;
    }
  }

  setMySetting(data: stepTwoCustomization): void {
    this.palette = data.palette.slice();
    this.roseLabels = data.roseLabels.slice();
    this.windSpeedUnits = data.windSpeedUnits;
    this.windSpeedLabelsCustom = data.windSpeedLabelsCustom.slice();
    this.isAmCanEditProject = data.isAmCanEditProject;
    this.projectId = data.projectId;
    this.weibullForm.setValue({
      shape: data.shape,
      scale: data.scale,
      default: !data.custom
    });
    // this.weibull_shape = data.weibull_shape;
    // this.weibull_scale = data.weibull_scale;
    // this.weibullForm.setValue({
      // weibull_shape: this.weibull_shape,
      // weibull_scale: this.weibull_scale
    // });
  }

  returnMySetting(): void {
    const data = this.customizationService.stepTwoCustomizationData;
    this.currentConfiguration = data.wind_speed_bins_type;
    this.onConfigurationChange(data);
  }

  setBeaufortScaleColour(): void {
    const paletteLenght = this.palette.length;
    Palette.forEach((value, index) => {
      if (paletteLenght >= index + 1) {
        this.palette[index] = value;
      }
    });
  }

  updateGraph(): void {
    this.parsedRoseLabels = this.parsedRoseLabels.filter(l =>  !(l[0] === '' && l[1] === ''));

    if (this.checkHaveInvalidLabels()) {
      return;
    }

    const lastIndx = this.parsedRoseLabels.length - 1;
    if (this.parsedRoseLabels[lastIndx][1] !== '') {
      this.addNewLabel();
    }

    const projectData = this.customizationService.stepTwoCustomizationData;
    const data: {wind_speed_bins_type?, wind_speed_bins?, palette?} = {};

    if (this.currentConfiguration !== projectData.wind_speed_bins_type) {
      data.wind_speed_bins_type = this.currentConfiguration;
    }

    if (this.currentConfiguration === BinConfiguration.BINS_CUSTOM) {
      if (this.isChange(this.roseLabels, projectData.windSpeedLabelsCustom)) {
        let wsBins = this.parsedRoseLabels.map(l => parseFloat(l[1]));
        wsBins.unshift(parseFloat(this.parsedRoseLabels[0][0]));
        wsBins = wsBins.filter(d => !isNaN(d));
        data.wind_speed_bins = wsBins;
      }
    }
    data.palette = this.palette;
    if (Object.keys(data).length) {
      this.customizationService.triggerChangeBins(data);
    }
  }

  isChange(array1, array2): boolean {
    return this.utilsService.isChange(array1, array2);
  }

  ngOnDestroy(): void {
    this.customizationService.changeStepTwoCustomizationData(null);
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  isDisableLabel(isMax): boolean {
    if (!this.isAmCanEditProject) {
      return true;
    }
    if (this.isNotCustomConfig()) {
      return true;
    }
    return !isMax;
  }

  isDisableBtn() {
    if (!this.isAmCanEditProject) {
      return true;
    }
    const projectData = this.customizationService.stepTwoCustomizationData;
    let isWSlabelChange = false;
    if (this.currentConfiguration === BinConfiguration.BINS_CUSTOM) {
      isWSlabelChange = this.isChange(this.roseLabels, projectData.windSpeedLabelsCustom);
    }
    return !(this.isChange(this.palette, projectData.palette) ||
      this.currentConfiguration !== projectData.wind_speed_bins_type || isWSlabelChange);
  }

  isICanAddNewLabel(): boolean {
    return Boolean(this.parsedRoseLabels.length < 50);
  }

  isNotCustomConfig(): boolean {
    return this.currentConfiguration !== BinConfiguration.BINS_CUSTOM;
  }

  getMidPoints(idx): string {
    const label = this.parsedRoseLabels[idx].slice();
    // TODO: Come up with a better solution. isNaN('') === false
    if (label[1] === '') {
      return label[0];
   }
    const result = (parseFloat(label[0]) + parseFloat(label[1])) / 2;
    return result.toFixed(2);
  }

  // TODO: use directives and do a normal float check
  numberOnly(event, val: string): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    const isHaveDote = val.indexOf('.') + 1;
    if (val.length && charCode === 46 && !isHaveDote) {
      return true;
    }
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }


  changeWeibull(): void {
    if (this.weibullForm.invalid) {
      return;
    }
    if (this.weibullForm.controls.shape.value < 0) {
      return;
    }
    if (this.weibullForm.controls.scale.value < 0) {
      return;
    }
    this.customizationService.triggerChangeWeibull({
      custom: !this.weibullForm.controls.default.value,
      shape: this.weibullForm.controls.shape.value,
      scale: this.weibullForm.controls.scale.value,
    });
  }
  // changeWeibull(): void {
  //   if (this.weibullForm.invalid) {
  //     return;
  //   }
  //   if (this.weibullForm.controls.weibull_shape.value < 0) {
  //     return;
  //   }
  //   if (this.weibullForm.controls.weibull_scale.value <= 0) {
  //     return;
  //   }
  //   this.customizationService.triggerChangeWeibull(this.weibullForm.value);
  // }

  // showWeibull(): void {
  //   this.changeWeibullLine(this.options.series, this.isShowWeibull);
  //   const columnar_graph_settings = this.customizationService.customizedChartBarOptions;
  //   this.customizationService.triggerChangeWeibull({
  //     weibull: this.isShowWeibull,
  //     columnar_graph_settings: columnar_graph_settings
  //   });
  // }

  // changeWeibullLine(seriesSettings: EChartOption.Series[], checked: boolean): void {
  //   if (checked) {
  //     const weibullLine: EChartOption.SeriesLine = cloneDeep(CHART_BAR_START_OPTIONS.series.find(item => item.type === 'line') as EChartOption.SeriesLine);
  //     const newSeries: EChartOption.Series[] = [...seriesSettings];
  //     newSeries.push(weibullLine);
  //     this.changeOption({ series: newSeries });
  //   } else {
  //     this.changeOption({ series: seriesSettings.filter(item => item.type !== 'line') });
  //   }
  // }

  // private changeOption(option): void {
  //   this.customizationService.setCustomizedChartBarOptions(assign(this.customizationService.customizedChartBarOptions, option));
  // }

  // getWeibullLineSeriesFromOptions(options: EChartOption): EChartOption.SeriesLine {
  //   return options.series.find(item => item.type === 'line') as EChartOption.SeriesLine;
  // }
  //
  // isWeibullLine(lineSeries: EChartOption.SeriesLine): boolean {
  //   return !!lineSeries;
  // }
}
