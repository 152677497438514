import {Component, Inject} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-wre-information',
  templateUrl: './wre-information.component.html',
  styleUrls: ['./wre-information.component.scss']
})
export class WreInformationComponent {

  constructor(
    private dialogRef: MatDialogRef<WreInformationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { type: 'web'|'wre'|'wrePlus', title: string }
    ) { }

  close(): void {
    this.dialogRef.close(null);
  }

  download(): void {
    this.dialogRef.close(true);
  }

}
