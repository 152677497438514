<form-wizard [isCustom]="true">
    <wizard-step [title]="'General'">
        <div class="form-group row justify-content-between align-items-center">
            <h4>Chart Title</h4>
            <div class="d-flex">
                <app-menu-toggle
                        menuToggleType="switch"
                        [checked]="options?.title?.show"
                        (changeState)="changeTitleDisplay(options, $event)">
                </app-menu-toggle>
                <app-menu-toggle
                        menuToggleType="burger"
                        [checked]="!menuSections.chartTitle.hidden"
                        (changeState)="toggleMenuSections('chartTitle')">
                </app-menu-toggle>
            </div>
        </div>
        <div [hidden]="menuSections.chartTitle.hidden">
            <div class="form-group row">
                <div class="col-4 p-0">
                    <label for="title-enable-bold" class="col-form-label col-8 align-self-center p-0">Bold</label>
                    <mat-checkbox
                            class="text-16 col-2 p-0" color="primary"
                            id="title-enable-bold"
                            [checked]="options?.title?.textStyle?.fontWeight === 'bolder'"
                            [disabled]="!options?.title?.show"
                            (change)="changeTitleBold(options, $event)">
                    </mat-checkbox>
                </div>
            </div>

            <div class="form-group row">
                <label for="title-name-input" class="col-form-label col-5 align-self-center p-0">Title Text</label>
                <input type="text"
                       id="title-name-input"
                       class="form-control col-6 p-0"
                       [value]="options?.title?.text || 'Wind distribution'"
                       [disabled]="!options?.title?.show"
                       (input)="changeTitleName(options.title, $event)">
            </div>
            <div class="form-group row">
                <label for="title-size-input" class="col-form-label col-5 align-self-center p-0">Title Font Size</label>
                <select class="form-control menu-list-default col-6 p-0"
                        id="title-size-input"
                        [value]="options?.title?.textStyle?.fontSize || 18"
                        [disabled]="!options?.title?.show"
                        (input)="changeTitleSize(options.title, $event)">
                    <option *ngFor="let size of textSizeList" [value]="size">{{ size }}</option>
                </select>
            </div>
        </div>

        <div class="form-group row justify-content-between align-items-center">
            <h4>Chart Area</h4>
            <app-menu-toggle
                    menuToggleType="burger"
                    [checked]="!menuSections.chartArea.hidden"
                    (changeState)="toggleMenuSections('chartArea')">
            </app-menu-toggle>
        </div>
        <div [hidden]="menuSections.chartArea.hidden">
            <div class="form-group row">
                <label for="color-input" class="col-form-label col-7 align-self-center p-0">Column color</label>
                <input
                        class="form-control col-2 p-1"
                        type="color"
                        id="color-input"
                        [value]="options.color[0]"
                        (input)="changeColumnColor(options, $event)">
            </div>

            <div class="form-group row">
                <div class="col-6 p-0">
                    <label for="trendline-enable" class="col-form-label align-self-center pr-1">Weibull</label>
                    <mat-checkbox
                            class="text-16 ml-1" color="primary"
                            id="trendline-enable"
                            [checked]="isWeibullLine(weibullLineSeries)"
                            [labelPosition]="'before'"
                            (change)="changeWeibullLine(options.series, $event)">
                    </mat-checkbox>
                </div>
                <!-- <div class="col-6 p-0">
                    <label for="smooth-enable" class="col-form-label align-self-center pr-1">Smooth</label>
                    <mat-checkbox
                            class="text-16 ml-1" color="primary"
                            id="smooth-enable"
                            [disabled]="!isWeibullLine(weibullLineSeries)"
                            [checked]="isSmoothWeibullLine(weibullLineSeries)"
                            [labelPosition]="'before'"
                            (change)="changeTrendLineSmoothness(options.series, $event)">
                    </mat-checkbox>
                </div> -->
            </div>
            <div class="form-group row">
                <label for="trendline-color-input" class="col-form-label col-7 align-self-center p-0">Weibull
                    Color</label>
                <input
                        class="form-control col-2 p-1"
                        type="color"
                        id="trendline-color-input"
                        [disabled]="!isWeibullLine(weibullLineSeries)"
                        [value]="weibullLineSeries?.itemStyle?.color || options.color[0]"
                        (input)="changeTrendLineColor(options.series, $event)">
            </div>

            <div class="form-group row">
                <label for="horizontal-lines-enable" class="col-form-label align-self-center p-0 mr-1">Background Horizontal
                    Lines</label>
                <mat-checkbox
                        class="text-16 d-flex p-0" color="primary"
                        id="horizontal-lines-enable"
                        [checked]="options.yAxis?.splitLine?.show"
                        (change)="changeHorizontalLines(options.yAxis, $event)">
                </mat-checkbox>
            </div>
            <div class="form-group row">
                <label for="vertical-lines-enable" class="col-form-label align-self-center p-0 vertical-lines">Background
                    Vertical Lines</label>
                <mat-checkbox
                        class="text-16 p-0" color="primary"
                        id="vertical-lines-enable"
                        [checked]="options.xAxis?.splitLine?.show"
                        (change)="changeVerticalLines(options.xAxis, $event)">
                </mat-checkbox>
            </div>
        </div>

    </wizard-step>

    <wizard-step [title]="'Axis'">
        <div class="form-group row justify-content-between align-items-center">
            <h4>Text Formatting</h4>
            <div class="d-flex">
                <app-menu-toggle
                        menuToggleType="burger"
                        [checked]="!menuSections.axisText.hidden"
                        (changeState)="toggleMenuSections('axisText')">
                </app-menu-toggle>
            </div>
        </div>
        <div [hidden]="menuSections.axisText.hidden">
            <div class="form-group row">
                <label for="text-font-input" class="col-form-label col-4 align-self-center p-0">Font</label>
                <select class="form-control menu-list-default col-7 p-0"
                        id="text-font-input"
                        (input)="changeTextFont(options, $event)"
                        [value]="options.xAxis?.nameTextStyle?.fontFamily"
                >
                    <option *ngFor="let font of textFontList" [value]="font">{{ font }}</option>
                </select>
            </div>
            <div class="form-group row">
                <label for="text-size-input" class="col-form-label col-4 align-self-center p-0">Text Size</label>
                <select class="form-control menu-list-default col-7 p-0"
                        id="text-size-input"
                        (input)="changeTextSize(options, $event)"
                        [value]="options.xAxis?.nameTextStyle?.fontSize"
                >
                    <option *ngFor="let size of textSizeList" [value]="size">{{ size }}</option>
                </select>
            </div>
        </div>

        <div class="form-group row justify-content-between align-items-center">
            <h4>X-axis</h4>
            <div class="d-flex">
                <app-menu-toggle
                        menuToggleType="switch"
                        [checked]="showXAxis"
                        (changeState)="changeXAxisDisplay(options.xAxis, $event);
                        changeXAxisLabelsDisplay(options.xAxis, $event);
                        changeXAxisTitleDisplay(options.xAxis, $event);
                        showXAxis = !showXAxis">
                </app-menu-toggle>
                <app-menu-toggle
                        menuToggleType="burger"
                        [checked]="!menuSections.axisX.hidden"
                        (changeState)="toggleMenuSections('axisX')">
                </app-menu-toggle>
            </div>
        </div>
        <div [hidden]="menuSections.axisX.hidden">
            <div class="form-group row">
                <label for="x-labels-show" class="col-form-label align-self-center p-0 col-5">Show Labels</label>
                <mat-checkbox
                        class="text-16 col-4 p-0" color="primary"
                        id="x-labels-show"
                        [checked]="options.xAxis?.axisLabel?.show"
                        (change)="changeXAxisLabelsDisplay(options.xAxis, $event.checked)">
                </mat-checkbox>
            </div>
            <div class="form-group row">
                <label for="x-title-show" class="col-form-label align-self-center p-0 col-5">Show Title</label>
                <mat-checkbox
                        class="text-16 col-4 p-0" color="primary"
                        id="x-title-show"
                        [checked]="!!options.xAxis?.name"
                        (change)="changeXAxisTitleDisplay(options.xAxis, $event.checked)">
                </mat-checkbox>
            </div>
        </div>

        <div class="form-group row justify-content-between align-items-center">
            <h4>Y-axis</h4>
            <div class="d-flex">
                <app-menu-toggle
                        menuToggleType="switch"
                        [checked]="showYAxis"
                        (changeState)="changeYAxisDisplay(options.yAxis, $event);
                        changeYAxisLabelsDisplay(options.yAxis, $event);
                        changeYAxisTitleDisplay(options.yAxis, $event);
                        showYAxis = !showYAxis">
                </app-menu-toggle>
                <app-menu-toggle
                        menuToggleType="burger"
                        [checked]="!menuSections.axisY.hidden"
                        (changeState)="toggleMenuSections('axisY')">
                </app-menu-toggle>
            </div>
        </div>
        <div [hidden]="menuSections.axisY.hidden">
            <div class="form-group row">
                <label for="y-labels-show" class="col-form-label align-self-center p-0 col-5">Show Labels</label>
                <mat-checkbox
                        class="text-16 col-2 p-0" color="primary"
                        id="y-labels-show"
                        [checked]="options.yAxis?.axisLabel?.show"
                        (change)="changeYAxisLabelsDisplay(options.yAxis, $event.checked)">
                </mat-checkbox>
            </div>
            <div class="form-group row">
                <label for="y-title-show" class="col-form-label align-self-center p-0 col-5">Show Title</label>
                <mat-checkbox
                        class="text-16 col-2 p-0" color="primary"
                        id="y-title-show"
                        [checked]="!!options.yAxis?.name"
                        (change)="changeYAxisTitleDisplay(options.yAxis, $event.checked)">
                </mat-checkbox>
            </div>
        </div>

    </wizard-step>
</form-wizard>
