import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, concatMap, delay, map, mergeMap, pluck, retryWhen, switchMap, tap} from 'rxjs/operators';
import {LoginRequest, RegistrationRequest, ResetPasswordRequest} from '../models/auth.model';
import {Feedback, User} from '../models/user.model';
import {Router} from '@angular/router';
import {linkedGroups} from '../models/company.model';
import {StationsResponse} from '../models/project.model';
import {environment} from '../../../environments/environment';
import {Discount, Invoice, Plan, Subscription} from '../models/payment.model';
import {SocialUser} from 'angularx-social-login';
import {ProjectsService} from './projects.service';
import {NotificationService} from './notification.service';
import {HttpCacheManager, withCache} from '@ngneat/cashew';

enum CacheType {
  Personal = 'personalFeedback',
  Company = 'companyFeedback',
}

const USER_CACHE_KEY = 'user';
export const COMPANY_USER_CACHE = 'getCompanyUserByID';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  API = environment.API_URL;
  readonly regexpPhone: string = '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$';
  user: User = new User();

  constructor(
    private http: HttpClient,
    private router: Router,
    private projectService: ProjectsService,
    private notificationService: NotificationService,
    private manager: HttpCacheManager
  ) {
  }

  deleteFeedbackCache(): void {
    Object.values(CacheType)
      .map(val => {
        this.deleteCache(val);
      });
  }

  deleteCache(val): void {
    if (this.manager.has(val)) {
      this.manager.delete(val);
    }
  }

  isAuthenticated(): Observable<boolean> {
    return this.getUser(true).pipe(map(user => !!user), catchError(() => of(null)));
  }

  setPassword(data): Observable<any> {
    return this.http.patch(this.API + '/auth/set_password/', data);
  }

  checkNoAuth(): boolean {
    return !!localStorage.getItem('Authorization');
  }

  checkOnline(): Observable<any> {
    return this.http.post(this.API + '/auth/user/status/', null);
  }

  getUser(isUseCachedData: boolean = true): Observable<User> {
    if (!isUseCachedData) {
      this.deleteCache(USER_CACHE_KEY);
    }
    return this.http.get<User>(this.API + '/auth/user/', withCache({
      cache$: true,
      key$: USER_CACHE_KEY
    })).pipe(
      tap(user => {
        this.user = new User(user);
      }),
      mergeMap(_ => this.getCompanyUserByID(this.user.getFirstCompany())),
      tap(users => {
        this.user.setIsAmCompanyUser(users);
      }),
      map(_ => this.user)
    );
  }

  getCompanyUserByID(company): Observable<User[]> {
    if (!company) {
      return of(null);
    }
    const companyId = company.id;
    return this.http.get<User[]>(this.API + `/companies/${companyId}/users/`, withCache({
      cache$: true,
      key$: COMPANY_USER_CACHE
    }));
  }

  getUserWithRetry(): Observable<User> {
    return this.http.get<User>(this.API + '/auth/user/')
      .pipe(
        map((user: User) => {
          if (!(user.subscription.payment_status === 'pending' && user.subscription.status === 'active')) {
            return user;
          }
          throw user;
        }),
        retryWhen(_ =>
          _.pipe(
            concatMap((e) => {
              return of(e).pipe(delay(198));
            }),
          )
        ),
        map(user => {
          this.user.subscription = new User(user).subscription;
          return this.user;
        }),
      );
  }

  updateUserCompanies(company: linkedGroups): void {
    this.user.linked_groups = this.user.linked_groups.map(
      comp => {
        if (comp.id === company.id) {
          return new linkedGroups(company);
        }
        return new linkedGroups(comp);
      }
    );
  }

  addUserCompanies(company: linkedGroups): void {
    this.user.linked_groups.push(new linkedGroups(company));
  }

  deleteCompanies(company: linkedGroups): void {
    this.user.linked_groups = this.user.linked_groups.filter(c => c.id !== company.id);
  }

  register(request: RegistrationRequest): Observable<any> {
    return this.http.post(this.API + '/auth/registration/', request);
  }

  signIn(data: LoginRequest): Observable<any> {
    return this.http.post(this.API + '/auth/login/', data)
      .pipe(
        tap(response => {
          localStorage.setItem('Authorization', `Token ${response.key}`);
        })
      );
  }

  recoveryPassword(data): Observable<any> {
    return this.http.post(this.API + '/auth/password/reset/', data);
  }

  resetPassword(data: ResetPasswordRequest): Observable<any> {
    return this.http.post(this.API + '/auth/password/reset/confirm/', data);
  }

  logout(): Observable<any> {
    this.notificationService.closeConnect();
    return this.http.post(this.API + '/auth/logout/', {})
      .pipe(
        tap(data => {
          this.clearUser();
          this.router.navigateByUrl('auth/login');
        })
      );
  }

  logoutWithoutRedirection(): Observable<any> {
    this.notificationService.closeConnect();
    return this.http.post(this.API + '/auth/logout/', {})
      .pipe(
        tap(data => {
          this.clearUser();
        })
      );
  }

  clearUser(): void {
    localStorage.clear();
    this.manager.delete();
    this.notificationService.changeNotifications([]);
    this.notificationService.closeConnect();
    this.projectService.companyProjects = [];
    this.projectService.personalProjects = [];
    this.projectService.personalAndCompanyProjects = [];
    this.projectService.currentProjectData = null;
    this.projectService.currentProject = null;
    this.projectService.setProject(null);
    this.user = new User();
  }

  confirmEmail(data): Observable<any> {
    return this.http.post(this.API + '/auth/registration/verify-email/', data);
  }

  getEmailInviteUser(code): Observable<any> {
    return this.http.get(this.API + `/auth/invitations/${code}/`);
  }

  confirmNewUser(code, date: RegistrationRequest = null): Observable<any> {
    return this.http.post(this.API + `/auth/invitations/${code}/confirm/`, date);
  }

  registerNewUser(code, date: RegistrationRequest): Observable<any> {
    return this.confirmNewUser(code, date);
  }

  editMuteSetting(data): Observable<any> {
    return this.http.patch<User>(this.API + '/auth/user/', data)
      .pipe(tap(user => {
        this.user.is_muted = user.is_muted;
      }));
  }

  editPassword(data): Observable<any> {
    return this.http.post(this.API + '/auth/password/change/', data)
      .pipe(
        tap(response => {
          this.logout().subscribe();
        })
      );
  }

  editEmail(email): Observable<any> {
    return this.http.put(this.API + '/auth/user/email/', {email});
  }

  editName(data): Observable<User> {
    return this.http.patch<User>(this.API + '/auth/user/', data)
      .pipe(tap(user => {
        this.user.first_name = user.first_name;
        this.user.last_name = user.last_name;
      }));
  }

  emailUnsubscribe(): Observable<User> {
    return this.http.patch<User>(this.API + '/auth/user/', {receive_emails: false})
      .pipe(tap(user => {
        this.user.receive_emails = user.receive_emails;
      }));
  }

  public setAvatar(avatar: Blob): Observable<User> {
    const imageType = avatar.type.split('/')[1];
    const headerDict = {
      'Content-Type': 'application/json',
      'Content-Disposition': `attachment; filename=avatar-${new Date().getTime()}.${imageType}`,
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict)
    };
    return this.http.put<User>(this.API + '/auth/user/avatar/', avatar, requestOptions);
  }

  public deleteAvatar(): Observable<User> {
    return this.http.delete<User>(this.API + '/auth/user/avatar/');
  }

  getStations(): Observable<StationsResponse> {
    return this.http.get<StationsResponse>(this.API + '/auth/user/stations/');
  }

  getCompanyStations(id): Observable<StationsResponse> {
    return this.http.get<StationsResponse>(this.API + `/companies/${id}/stations/`);
  }

  setPaymethod(paymentMethod: string = ''): Observable<{customer_id: string}> {
    return this.http.post<{customer_id: string}>(this.API + '/auth/user/paymethod/', {payment_method: paymentMethod});
  }

  changePaymethod(paymentMethod: string): Observable<User> {
    return this.http.patch<{ customer_id: string }>(this.API + '/auth/user/paymethod/', {payment_method: paymentMethod})
      .pipe(
        switchMap(_ => this.http.get<User>(this.API + '/auth/user/')),
        tap(user => this.user.last4 = user.last4)
      );
  }

  setSubscription(plan: Plan, coupon: Discount): Observable<Subscription> {
    const data: {plan, coupon?} = {plan : plan.id};
    if (coupon !== null && typeof coupon !== 'undefined' && !plan.isTrial) {
      data.coupon = coupon.id;
    }
    return this.http.post<Subscription>(this.API + '/auth/user/subscription/', data)
      .pipe(
        mergeMap(sub => this.getUserWithRetry()),
        tap(user => this.user.last4 = user.last4),
        map(user => user.subscription),
      );
  }

  deleteSubscription(): Observable<Subscription> {
    return this.http.delete<Subscription>(this.API + '/auth/user/subscription/')
      .pipe(
        tap(sub => {
          this.user.subscription = new Subscription(sub);
        }),
      );
  }

  editSubscription(data): Observable<Subscription> {
    return this.http.patch<Subscription>(this.API + '/auth/user/subscription/', data)
      .pipe(
        tap(sub => {
          this.user.subscription = new Subscription(sub);
        }),
      );
  }

  changeUserPlans(plan: Plan): Observable<Subscription> {
    if (plan.id === (this.user.subscription.plan as Plan).id) {
      return this.deleteSubscription().pipe(
        mergeMap(() => this.setSubscription(plan, null))
      );
    }

    return this.http.patch<{ plan: number }>(this.API + `/auth/user/subscription/`, {plan: plan.id})
      .pipe(mergeMap(sub => this.getUserWithRetry()),
        map(user => user.subscription),
      );
  }

  googleSignIn(data: SocialUser): Observable<any> {
    return this.http.post<any>(this.API + '/auth/google/', data).pipe(
      tap(response => {
        localStorage.setItem('Authorization', `Token ${response.token}`);
      }),
      map(() => data)
    );
  }

  facebookSignIn(data: SocialUser): Observable<any> {
    let facebookToken = null;
    return this.http.post<any>(this.API + '/auth/facebook/', data).pipe(
      tap(response => {
        if (response.key) {
          facebookToken = response.key;
          localStorage.setItem('Authorization', `Token ${response.key}`);
        }
      }),
      mergeMap(() => {
        return this.http.get<{ data: { height: number, width: number, is_silhouette: boolean, url: string } }>(
          `https://graph.facebook.com/${data.id}/picture`,
          { params: { type: 'large', redirect: '0' } }
          );
      }),
      pluck('data'),
      map(userPhoto => ({ ...data, facebookPhoto: userPhoto.url, facebookToken }))
    );
  }

  sendReport(formData: FormData): Observable<any> {
    return this.http.post<Subscription>(this.API + '/feedback/', formData)
      .pipe(
        tap(() => this.deleteFeedbackCache()),
      );
  }


  getReports(company: linkedGroups = null): Observable<Feedback[]> {
    if (!company) {
      return this.http.get<Feedback[]>(this.API + '/feedback/', withCache({
        cache$: true,
        key$: CacheType.Personal
      }));
    }
    return this.http.get<Feedback[]>(this.API + `/companies/${company.id}/feedback/`, withCache({
      cache$: true,
      key$: CacheType.Company
    }));
  }

  userInvoices(): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(this.API + '/auth/user/invoices/');
  }

  setCoupon(coupon): Observable<Subscription | Discount> {
    let coup = null;
    if (this.user.subscription.isActive() && !this.user.subscription.isTrial) {
      return this.http.patch<Discount>(this.API + `/coupon/${coupon}/`, {is_active: true}).pipe(
        switchMap(_ => {
          return this.http.patch<Subscription>(this.API + '/auth/user/subscription/', {coupon}).pipe(
            tap(sub => coup = sub.coupon),
            mergeMap(() => this.getUser(false)),
            mergeMap(() => this.getDiscountInfo()));
        })
      );

    } else {
      return this.http.patch<Discount>(this.API + `/coupon/${coupon}/`, {is_active: true});
    }

  }

  getCoupons(): Observable<any> {
    return this.http.get(this.API + '/auth/user/coupon/');
  }

  getDiscountInfo(): Observable<Subscription> {
    return this.http.get<Subscription>(this.API + '/auth/user/discount/')
      .pipe(map(coupon => {
        this.user.subscription.discount = coupon.discount;
        return this.user.subscription;
      }));
  }

  userGifts(): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(this.API + '/auth/user/gifts/')
      .pipe(
        map(invoices => invoices.map(invoice => {
            invoice.isGift = true;
            return invoice;
          })
        )
      );
  }

  checkExcel(isWrePlus: boolean): Observable<{ win_link: string, mac_link: string }> {
    const link = isWrePlus ? '/wre_plus_excel_link/' : '/wre_excel_link/';
    return this.http.get<{ win_link: string, mac_link: string }>(this.API + link);
  }
}
