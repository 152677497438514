<h2 mat-dialog-title><i class="i-Add-File mr-1"></i>Start a new project <i (click)="close()" class="i-Close-Window modal-close"></i></h2>

<mat-dialog-content>
  <div class="panel-body">
    <form role="form" class="form-horizontal" [formGroup]="createProjectForm">

      <div class="form-group">
        <label for="project-name" class="col-md-4 control-label">Project name:</label>
        <div class="col-md-12">
          <div class="input-group">
            <input id="project-name" class="form-control" placeholder="Project name" formControlName="title">
          </div>
          <mat-error *ngIf="createProjectForm.get('title').touched && createProjectForm.get('title').errors?.required">
            Project name is required.
          </mat-error>
        </div>
      </div>

      <ng-container>
        <div class="form-group">
          <label class="col-md-4 control-label">What data will be used:</label>
          <div class="col-md-12 pt7">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="isUseOwnData" [ngModelOptions]="{standalone: true}">
              <mat-radio-button [value]="false" checked>Fetch data using WRE Web APP</mat-radio-button>
              <mat-radio-button [value]="true">I will use my own data</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </ng-container>

      <div class="form-group">
        <label class="col-md-4 control-label">Wind speed units choose:</label>
        <div class="col-md-12 pt7">
          <select class="custom-select" formControlName="wind_speed_units">
            <option *ngFor="let speed of windSpeedSelect" [value]="speed">{{speed}}</option>
          </select>
        </div>
      </div>
      <ng-container *ngIf="this.data.user.isAmNotCompanyUser()">
        <div class="form-group">
          <label class="col-md-4 control-label">Where to save the project:</label>
          <div class="col-md-12 pt7">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="isCompanyProject" [ngModelOptions]="{standalone: true}">
              <mat-radio-button [value]="false" checked>Personal project</mat-radio-button>
              <mat-radio-button [value]="true" *ngIf="isUserHaveCompany">Company project</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isCompanyProject">
        <div class="form-group">
          <label class="col-md-4 control-label">Access setting:</label>
          <div class="col-md-12">
            <select class="custom-select" formControlName="access">
              <option [value]="projectAccess.PRIVATE">Private</option>
              <option [value]="projectAccess.AvailableToView">Company users can view</option>
              <option [value]="projectAccess.AvailableToEdit">Company users can edit</option>
            </select>
          </div>
        </div>
      </ng-container>
      <div class="form-group">
        <label class="col-md-4 control-label">Created by:</label>
        <div class="col-md-12 pt7">
          {{data.user.getFullName()}}
        </div>
        <ng-container *ngIf="isCompanyProject">
          <label class="col-md-4 control-label">You Creating Project for:</label>
          <div class="col-md-12 pt7">
            {{data.user.getFirstCompany().name}}
          </div>
        </ng-container>
      </div>
      <div class="modal-btns justify-content-center">
        <button class="btn btn-primary mr5 waves-effect" type="submit" (click)="createProject()"
                [disabled]="createProjectForm.get('title').errors">
          Create
        </button>
      </div>
    </form>
  </div>
</mat-dialog-content>
