import { Component, Inject, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InfoComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, body: string, btn: string, isShowBtn: boolean, queryParams?: Params },
  ) {
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

  navigate(): void {
    this.router.navigate(
      ['/dashboard/payments'],
      { queryParams: this.data.queryParams['return-back-to-project'] ? this.data.queryParams : null }
      );
    this.close();
  }

  navigateToTrial(): void {
    this.router.navigate(['/dashboard/payments'], { queryParams: this.data.queryParams });
    this.close();
  }

}
